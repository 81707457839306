
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Schedule } from '@client/models';
import { dateToMomentDate, dateToMomentTime, getShortDayName } from '@client/utils/DateTimeUtils';
import { DeleteScheduleButton } from '@client/components';
import ScheduleDialog from '@client/components/ScheduleDialog/ScheduleDialog.vue';
import ScheduleActiveStatusSwitch from '@client/components/Device/DeviceActiveStatusSwitch.vue';
import { TranslateResult } from 'vue-i18n';
import Wrapper from '@client/components/Layouts/Wrapper.vue';

@Component({
  methods: {
    dateToMomentDate,
    dateToMomentTime,
    getShortDayName,
  },
  components: {
    Wrapper,
    DeviceActiveStatusSwitch: ScheduleActiveStatusSwitch,
    ScheduleDialog,
    DeleteScheduleButton,
  },
})
export default class SchedulesOverviewTable extends Vue {
  @Prop()
  schedules!: Array<Schedule>;
  @Prop()
  gondolaTemplateId!: string;
  @Prop({
    default: () => {
      // Will be overridden later
    },
  })
  cancelHandler!: () => void;

  getActiveStatusText(schedule: Schedule): TranslateResult {
    return schedule.active
      ? this.$t(this.$i18nTranslationKeys.schedules.active.$path)
      : this.$t(this.$i18nTranslationKeys.schedules.inactive.$path);
  }

  getActiveStatusChipColor(schedule: Schedule): string {
    return schedule.active ? 'success' : 'accent';
  }
}
