import { Device, GondolaPublishing } from '@client/models';
import { DeviceSearchResultJSON } from '@common/device/types';
import { Optional } from '@common/types';

export default class DeviceSearchResult {
  device: Device;
  publishing?: GondolaPublishing;

  constructor(device: Device, publishing: GondolaPublishing) {
    this.device = device;
    this.publishing = publishing;
  }

  static fromJSON(deviceSearchResultJSON: DeviceSearchResultJSON): DeviceSearchResult {
    const publishing: Optional<GondolaPublishing> = deviceSearchResultJSON.publishing
      ? GondolaPublishing.fromJSON(deviceSearchResultJSON.publishing)
      : undefined;
    return {
      device: Device.fromJSON(deviceSearchResultJSON.device),
      publishing: publishing,
    };
  }
}
