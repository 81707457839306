
import { Component, Prop, Vue } from 'vue-property-decorator';
import TagDialog from '@client/components/Settings/Tags/TagDialog.vue';
import Tag from '@client/models/SettingsModels/Tag.model';

@Component({
  components: { TagDialog },
})
export default class EditButton extends Vue {
  @Prop()
  private currentTag!: Tag;

  private showDialog: boolean = false;

  closeDialog(): void {
    this.showDialog = false;
  }
}
