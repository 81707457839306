
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Bar } from 'vue-chartjs';
import { DetectedObjectEntry, DeviceDetectedObject, DeviceSensorObjects } from '@common/apim/definitions';
import {
  calculateDailyAverages,
  calculateHourlyAverages,
  mapDatasetFromAverage,
  mapLabelFromAverage,
} from '@client/components/SensorAnalytics/charts/utils';
import { ChartDataOptions, DailyAverages, HourlyAverages } from '@client/components/SensorAnalytics/charts/types';
import SwitchInput from '@client/components/SensorAnalytics/charts/Switch.vue';
import { VuetifyParsedThemeItem } from 'vuetify/types/services/theme';
import { TranslateResult } from 'vue-i18n';

@Component({
  components: { SwitchInput, Bar },
})
export default class AverageObjectsDetected extends Vue {
  /* DECLARATIONS */
  @Prop()
  private sensorDataObjects!: DeviceSensorObjects | null;
  private ctx: CanvasRenderingContext2D | null = null;

  private mode: 'daily' | 'hourly' | 'overall' = 'overall';
  private ignoreEmptyDays: boolean = false;

  private overallAverages: DailyAverages[] = [];
  private dailyAverages: DailyAverages[] = [];
  private hourlyAverages: HourlyAverages[] = [];
  private chartData: ChartDataOptions = {
    labels: [],
    datasets: [],
  };
  /* LIFECYCLE EVENTS */

  @Watch('sensorDataObjects', { deep: true })
  onDataChange(): void {
    if (!this.sensorDataObjects) {
      return;
    }
    const objects: Array<DeviceDetectedObject> = this.sensorDataObjects.detectedObjects.map(
      (object: DetectedObjectEntry) => object.detectedObject
    );
    this.hourlyAverages = calculateHourlyAverages(objects);
    this.dailyAverages = calculateDailyAverages(objects, false);
    this.overallAverages = calculateDailyAverages(objects, true, this.ignoreEmptyDays);
    this.setChartData();
  }

  @Watch('ignoreEmptyDays')
  onIgnoreEmptyDaysChange(): void {
    this.overallAverages = calculateDailyAverages(
      this.sensorDataObjects?.detectedObjects.map((object: DetectedObjectEntry) => object.detectedObject) || [],
      true,
      this.ignoreEmptyDays
    );
    this.setChartData();
  }

  mounted(): void {
    const chartRef: Vue | undefined = this.$refs['line-chart'] as Vue | undefined;
    if (!chartRef) {
      return;
    }
    const canvas: HTMLCanvasElement = chartRef.$el as HTMLCanvasElement;
    const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
    if (!ctx) {
      return;
    }
    this.ctx = ctx;
    this.setChartData();
  }

  /* METHODS */

  setChartData(): void {
    if (!this.ctx) {
      return;
    }
    const chartLabels: Array<TranslateResult> =
      this.mode === 'hourly'
        ? Array.from({ length: 24 }).map((_: unknown, i: number) => `${i}:00`)
        : this.mode === 'overall'
        ? this.overallAverages.map((dailyAverage: DailyAverages) => dailyAverage.day)
        : [
            this.$t(this.$i18nTranslationKeys.activeHours.weekdays.monday.$path),
            this.$t(this.$i18nTranslationKeys.activeHours.weekdays.tuesday.$path),
            this.$t(this.$i18nTranslationKeys.activeHours.weekdays.wednesday.$path),
            this.$t(this.$i18nTranslationKeys.activeHours.weekdays.thursday.$path),
            this.$t(this.$i18nTranslationKeys.activeHours.weekdays.friday.$path),
            this.$t(this.$i18nTranslationKeys.activeHours.weekdays.saturday.$path),
            this.$t(this.$i18nTranslationKeys.activeHours.weekdays.sunday.$path),
          ];
    this.chartData = {
      labels: chartLabels,
      datasets: [
        {
          data: this.awarenessDataset,
          label: this.$t(this.$i18nTranslationKeys.sensors.areas.awareness.$path),
          labels: this.awarenessLabel,
          tension: 0.5,
          borderRadius: 4,
          borderColor: (this.$vuetify.theme.themes.light.primary as VuetifyParsedThemeItem).base,
          backgroundColor: (this.$vuetify.theme.themes.light.primary as VuetifyParsedThemeItem).base,
          fill: true,
          datalabels: {
            color: (this.$vuetify.theme.themes.light.primary as VuetifyParsedThemeItem).base, // Label color
          },
        },
        {
          data: this.dwellDataset,
          label: this.$t(this.$i18nTranslationKeys.sensors.areas.dwell.$path),
          labels: this.dwellLabel,
          tension: 0.5,
          borderRadius: 4,
          borderColor: (this.$vuetify.theme.themes.light['puerto-rico-blue'] as VuetifyParsedThemeItem).base,
          backgroundColor: (this.$vuetify.theme.themes.light['puerto-rico-blue'] as VuetifyParsedThemeItem).base,
          fill: true,
          datalabels: {
            color: (this.$vuetify.theme.themes.light['puerto-rico-blue'] as VuetifyParsedThemeItem).base,
          },
        },
        {
          data: this.decisionDataset,
          label: this.$t(this.$i18nTranslationKeys.sensors.areas.decision.$path),
          labels: this.decisionLabel,
          tension: 0.5,
          borderRadius: 4,
          borderColor: (this.$vuetify.theme.themes.light['neon-green'] as VuetifyParsedThemeItem).base,
          backgroundColor: (this.$vuetify.theme.themes.light['neon-green'] as VuetifyParsedThemeItem).base,
          fill: true,
          datalabels: {
            color: (this.$vuetify.theme.themes.light['neon-green'] as VuetifyParsedThemeItem).base,
          },
        },
      ],
    };
  }

  onModeChange(): void {
    this.setChartData();
  }

  getDataset(type: 'awareness' | 'dwell' | 'decision'): Array<number> | Array<Array<number>> {
    if (this.mode === 'overall') {
      return this.overallAverages.map((average: DailyAverages) => mapDatasetFromAverage(type, average));
    } else if (this.mode === 'daily') {
      return this.dailyAverages.map((average: DailyAverages) => mapDatasetFromAverage(type, average));
    }
    return this.hourlyAverages.map((average: HourlyAverages) => mapDatasetFromAverage(type, average));
  }

  getLabel(type: 'awareness' | 'dwell' | 'decision'): Array<string> {
    if (this.mode === 'overall') {
      return this.overallAverages.map((average: DailyAverages) => mapLabelFromAverage(type, average));
    } else if (this.mode === 'daily') {
      return this.dailyAverages.map((average: DailyAverages) => mapLabelFromAverage(type, average));
    }
    return this.hourlyAverages.map((average: HourlyAverages) => mapLabelFromAverage(type, average));
  }

  /* GETTERS */

  get chartOptions() {
    return {
      responsive: true,
      type: 'bar',
      layout: {
        padding: {
          top: 25,
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            label(tooltipItem: {
              dataset: { label: string; labels: Array<string> };
              formattedValue: number | Array<number>;
              raw: number | Array<number>;
              datasetIndex: number;
              dataIndex: number;
            }): string | string[] | void {
              return `${tooltipItem.dataset.label}: ${tooltipItem.dataset.labels[tooltipItem.dataIndex]}`;
            },
          },
        },
        datalabels: {
          anchor: 'start', // Position the label
          align: 'top', // Align the label to the top of the bar
          formatter: (value: number) => `${value}`, // Display the value
          font: {
            weight: 'bold',
            size: 10,
          },
          display: function (context: { dataset: { data: Array<number> }; dataIndex: number }): boolean {
            return context.dataset.data.length < 60;
          },
        },
        scales: {
          x: {
            beginAtZero: true,
          },
          y: {
            beginAtZero: true,
          },
        },
      },
    };
  }

  get awarenessDataset(): Array<number> | Array<Array<number>> {
    return this.getDataset('awareness');
  }

  get dwellDataset(): Array<number> | Array<Array<number>> {
    return this.getDataset('dwell');
  }

  get decisionDataset(): Array<number> | Array<Array<number>> {
    return this.getDataset('decision');
  }

  get awarenessLabel(): Array<string> {
    return this.getLabel('awareness');
  }

  get dwellLabel(): Array<string> {
    return this.getLabel('dwell');
  }

  get decisionLabel(): Array<string> {
    return this.getLabel('decision');
  }

  get isLoading(): boolean {
    return !this.sensorDataObjects;
  }
}
