
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Store } from '@client/models';
import { ErrorResponse, ErrorType } from '@common/error/types';
import StoreAndSectionActiveHours from '@client/models/ActiveHoursModels/StoreAndSectionActiveHours';
import { StoreResponseJSON } from '@common/stores/types';
import { AppGlobalStore, useAppGlobalStore } from '@client/stores/app-global';
import { ErrorObserver, ErrorStore, useErrorStore } from '@client/stores/error';
import { ActiveHoursStore, useActiveHoursStore } from '@client/stores/activeHours';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import QRCodeSection from '@client/components/StoreDetail/StoreSettings/QRCodeSection.vue';
import Wrapper from '@client/components/Layouts/Wrapper.vue';
import GeneralSettings from '@client/components/StoreDetail/StoreSettings/GeneralSettings.vue';
import ReleaseManagementSettings from '@client/components/StoreDetail/StoreSettings/ReleaseManagementSettings.vue';
import ActiveHoursSettings from '@client/components/StoreDetail/StoreSettings/ActiveHoursSettings.vue';
import StoreActions from '@client/components/StoreDetail/StoreSettings/StoreActions.vue';
import { getStoreDetailPath } from '@client/router/utils';

@Component({
  methods: { getStoreDetailPath },
  components: {
    StoreActions,
    ActiveHoursSettings,
    GeneralSettings,
    Wrapper,
    QRCodeSection,
    ReleaseManagementSettings,
  },
})
export default class StoreSettings extends Vue {
  private static readonly ERROR_OBSERVER_KEY: string = 'StoreSettings.vue';
  @Prop()
  private store!: Store;

  private showDuplicateAzureIdErrorModal: boolean = false;
  private duplicateIdAzureStoreId: string = '';
  private duplicateIdAzureStoreName: string = '';
  private storeAndSectionActiveHours: StoreAndSectionActiveHours = { storeActiveHours: [], sectionActiveHours: [] };

  private appGlobalStore: AppGlobalStore = useAppGlobalStore();
  private errorStore: ErrorStore = useErrorStore();
  private activeHoursStore: ActiveHoursStore = useActiveHoursStore();
  private storesStore: StoresStore = useStoresStore();

  async created(): Promise<void> {
    this.registerObserver();
  }

  beforeDestroy(): void {
    this.errorStore.deregister(StoreSettings.ERROR_OBSERVER_KEY);
  }

  @Watch('isLoading')
  setStoreAndSectionActiveHours(): void {
    if (this.storeId && !this.isLoading) {
      this.storeAndSectionActiveHours =
        this.activeHoursStore.getActiveHoursByStoreId(this.storeId) ?? this.storeAndSectionActiveHours;
    }
  }

  registerObserver(): void {
    ErrorObserver.create(StoreSettings.ERROR_OBSERVER_KEY)
      .attachHandler(ErrorType.DUPLICATE, (errorResponse: ErrorResponse<StoreResponseJSON>): void => {
        this.duplicateIdAzureStoreId = errorResponse.details?._id || '';
        this.duplicateIdAzureStoreName = errorResponse.details?.name || '';
        this.showDuplicateAzureIdErrorModal = true;
        this.storesStore.resetLoadingIndicators();
      })
      .attachHandler(ErrorType.ILLEGAL_STATE, (): void => {
        this.appGlobalStore.updateGenericErrorModal({
          showGenericErrorModal: true,
          showGenericErrorModalReloadButton: false,
          genericErrorModalText: this.$t(
            this.$i18nTranslationKeys.error.StoreCRUDError.AZURE_ID_EXISTING_DEVICES.$path
          ),
        });
        this.storesStore.resetLoadingIndicators();
      })
      .attachHandler(ErrorType.APIM_ERROR, (): void => {
        this.appGlobalStore.updateGenericErrorModal({
          showGenericErrorModal: true,
          showGenericErrorModalReloadButton: false,
          genericErrorModalText: this.$t(this.$i18nTranslationKeys.error.StoreCRUDError.WEBHOOK_NOT_CREATED.$path),
        });
        this.storesStore.resetLoadingIndicators();
      })
      .attachHandler(ErrorType.APIM_INVALID_STORE, (error: ErrorResponse): void => {
        const azureId: string | undefined = error.message.split(':').pop();
        this.appGlobalStore.updateGenericErrorModal({
          showGenericErrorModal: true,
          showGenericErrorModalReloadButton: false,
          genericErrorModalText: this.$t(this.$i18nTranslationKeys.error.APIM.INVALID_STORE.$path, { azureId }),
        });
        this.storesStore.resetLoadingIndicators();
      })
      .attachHandler(ErrorType.APIM_MISSING_VRAIL_KEY, (error: ErrorResponse): void => {
        const azureId: string | undefined = error.message.split(':').pop();
        this.appGlobalStore.updateGenericErrorModal({
          showGenericErrorModal: true,
          showGenericErrorModalReloadButton: false,
          genericErrorModalText: this.$t(this.$i18nTranslationKeys.error.APIM.MISSING_VRAIL_KEY.$path, { azureId }),
        });
        this.storesStore.resetLoadingIndicators();
      })
      .attachHandler(ErrorType.APIM_MISSING_VRAIL_PERMISSIONS, (error: ErrorResponse): void => {
        const azureId: string | undefined = error.message.split(':').pop();
        this.appGlobalStore.updateGenericErrorModal({
          showGenericErrorModal: true,
          showGenericErrorModalReloadButton: false,
          genericErrorModalText: this.$t(this.$i18nTranslationKeys.error.APIM.MISSING_VRAIL_PERMISSIONS.$path, {
            azureId,
          }),
        });
        this.storesStore.resetLoadingIndicators();
      })
      .attachHandler(ErrorType.APIM_INVALID_VCLOUD_SUBSCRIPTION, (error: ErrorResponse): void => {
        const azureId: string | undefined = error.message.split(':').pop();
        this.appGlobalStore.updateGenericErrorModal({
          showGenericErrorModal: true,
          showGenericErrorModalReloadButton: false,
          genericErrorModalText: this.$t(this.$i18nTranslationKeys.error.APIM.MISSING_VCLOUD_SUBSCRIPTION.$path, {
            azureId,
          }),
        });
        this.storesStore.resetLoadingIndicators();
      })
      .attachHandler(ErrorType.UNEXPECTED_ERROR, (): void => {
        this.appGlobalStore.updateGenericErrorModal({
          showGenericErrorModal: true,
          showGenericErrorModalReloadButton: false,
          genericErrorModalText: this.$t(this.$i18nTranslationKeys.error.genericUnexpected.$path),
        });
        this.storesStore.resetLoadingIndicators();
      })
      .register();
  }

  hideIdAzureErrorModal(): void {
    this.showDuplicateAzureIdErrorModal = false;
  }

  get isLoading(): boolean {
    return (
      this.activeHoursStore.loadingIndicator.update ||
      this.storesStore.loadingIndicator.delete ||
      this.storesStore.loadingIndicator.update
    );
  }

  get storeId(): string {
    return this.$route.params.storeid;
  }
}
