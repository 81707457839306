import DevicePublishing from '@client/models/DeviceModels/DevicePublishing.model';
import GondolaPublishInformation from './GondolaPublishInformation.model';
import { DevicePublishingJSON, GondolaPublishingJSON } from '@common/publishing/types';
import { ClientPublishState } from '@client/enums';

export default class GondolaPublishing {
  _id: string;
  activeAt: Date;
  railGrid: Array<Array<DevicePublishing>> = [];
  scheduleIds: Array<string>;
  scheduleHashes: Array<string>;
  publishInformation: GondolaPublishInformation;
  customerId: string;
  userId: string;
  storeId: string;
  storeIdAzure: string;
  apimKey: string;
  apiUrl: string;
  gondolaId: string;
  storeHash: string;
  gondolaTemplateId?: string;
  gondolaTemplateHash: string;
  aisle: string;
  positionInAisle: string;
  previousAttempts?: Array<string>;
  nextRunAt?: Date;
  publishingEndTime?: Date;
  sensorDevices?: Array<string>;
  sensorDataArchived?: boolean;

  constructor(
    _id: string,
    customerId: string,
    apiUrl: string,
    storeId: string,
    storeIdAzure: string,
    apimKey: string,
    gondolaId: string,
    gondolaTemplateId: string,
    userId: string,
    aisle: string,
    positionInAisle: string,
    storeHash: string,
    gondolaTemplateHash: string,
    activeAt: Date,
    scheduleIds?: Array<string>,
    scheduleHashes?: Array<string>,
    previousAttempts?: Array<string>,
    nextRunAt?: Date,
    publishingEndTime?: Date,
    sensorDevices?: Array<string>,
    sensorDataArchived?: boolean
  ) {
    this._id = _id;
    this.activeAt = activeAt;
    this.publishInformation = new GondolaPublishInformation(ClientPublishState.MATCHED);
    this.customerId = customerId;
    this.storeId = storeId;
    this.storeIdAzure = storeIdAzure;
    this.apimKey = apimKey;
    this.apiUrl = apiUrl;
    this.gondolaId = gondolaId;
    this.userId = userId;
    this.aisle = aisle;
    this.positionInAisle = positionInAisle;
    this.storeHash = storeHash;
    this.gondolaTemplateId = gondolaTemplateId;
    this.gondolaTemplateHash = gondolaTemplateHash;
    this.scheduleIds = scheduleIds || [];
    this.scheduleHashes = scheduleHashes || [];
    this.previousAttempts = previousAttempts || [];
    this.nextRunAt = nextRunAt;
    this.publishingEndTime = publishingEndTime;
    this.sensorDevices = sensorDevices || [];
    this.sensorDataArchived = sensorDataArchived;
  }

  static fromJSON(source: GondolaPublishingJSON): GondolaPublishing {
    const publishing: GondolaPublishing = new GondolaPublishing(
      source._id,
      source.customerId,
      source.apiUrl,
      source.storeId,
      source.storeIdAzure,
      source.apimKey,
      source.gondolaId,
      source.gondolaTemplateId || '',
      source.userId,
      source.aisle,
      source.positionInAisle,
      source.storeHash,
      source.gondolaTemplateHash || '',
      new Date(source.activeAt),
      source.scheduleIds,
      source.scheduleHashes,
      source.previousAttempts,
      source.nextRunAt ? new Date(source.nextRunAt) : undefined,
      source.publishingEndTime ? new Date(source.publishingEndTime) : undefined,
      source.sensorDevices,
      source.sensorDataArchived
    );
    publishing.publishInformation = GondolaPublishInformation.fromJSON(source.publishInformation);
    publishing.gondolaTemplateId = source.gondolaTemplateId;

    publishing.railGrid = source.railGrid.map((row: Array<DevicePublishingJSON>) => {
      return row.map((devicePublishingJSON: DevicePublishingJSON) => {
        return DevicePublishing.fromJSON(devicePublishingJSON);
      });
    });
    return publishing;
  }
}
