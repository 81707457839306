
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ContentItem } from '@client/models/ContentModels/types';

@Component({})
export default class EditTagsButton extends Vue {
  @Prop()
  private contentItem!: ContentItem;
  @Prop()
  private openEditTagsDialog!: (contentItemToEdit: ContentItem) => void;
}
