
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Store } from '@client/models';
import { getCreatedAtFormatted, getRelativeTimeFromNow } from '@client/utils/DateTimeUtils';
import { DeviceStatusEventType } from '@common/eventlog/types';
import { Optional } from '@common/types';
import { DeviceStatusEventLog } from '@client/models/EventLogModels/DeviceStatusEventLog/DeviceStatusEventLog.model';
import Notification from '@client/components/NotificationPanel/Notifications/Notification.vue';
import DeviceOptionsMenu from '@client/components/Gondola/DeviceOptionsMenu.vue';
import { getStoreDetailPath } from '@client/router/utils';

/**
 * Handles Registration notifications (registered/unregistered) and Online status notification (online/offline)
 */
@Component({
  methods: { getCreatedAtFormatted, getRelativeTimeFromNow },
  components: { DeviceOptionsMenu, Notification },
})
export default class DeviceStatusNotification extends Vue {
  @Prop()
  private toggleDrawerVisibility!: () => void;
  @Prop()
  private eventLog!: DeviceStatusEventLog;
  @Prop()
  private store: Optional<Store>;

  navigateToStore(): void {
    if (!this.store) {
      return;
    }
    const linkToStore: string = getStoreDetailPath(this.eventLog.storeId);

    const [path]: Array<string> = linkToStore.split('?');
    if (this.$router.currentRoute.path === path) {
      this.toggleDrawerVisibility();
      return;
    }
    this.$router.push(linkToStore);
  }

  getMessageFromDeviceEvent(notification: DeviceStatusEventLog): string {
    switch (notification.deviceStatus) {
      case DeviceStatusEventType.REGISTERED:
        return ` ${this.$t(this.$i18nTranslationKeys.notifications.eventType.registered.$path)} `;
      case DeviceStatusEventType.UNREGISTERED:
        return ` ${this.$t(this.$i18nTranslationKeys.notifications.eventType.unregistered.$path)} `;
      case DeviceStatusEventType.ONLINE:
        return ` ${this.$t(this.$i18nTranslationKeys.notifications.eventType.online.$path)}`;
      case DeviceStatusEventType.OFFLINE:
        return ` ${this.$t(this.$i18nTranslationKeys.notifications.eventType.offline.$path)}`;
      default:
        return ` ${notification.deviceStatus}`;
    }
  }

  get notificationTitle(): string {
    return ` - ${this.getMessageFromDeviceEvent(this.eventLog)}`;
  }

  get storeNameInTitle(): string {
    if (this.store) {
      return this.store.name;
    }
    return this.eventLog.azureStoreId;
  }

  get notificationIcon(): string {
    switch (this.eventLog.deviceStatus) {
      case DeviceStatusEventType.OFFLINE:
      case DeviceStatusEventType.ONLINE:
        return 'mdi-signal-variant';
      case DeviceStatusEventType.REGISTERED:
      case DeviceStatusEventType.UNREGISTERED:
        return 'mdi-note-edit';
      default:
        return 'mdi-note-edit';
    }
  }

  get dateToDisplay(): string | Date {
    return this.eventLog.eventExecutionTimestamp || this.eventLog.createdAt;
  }

  get isNotUnregisteredEvent(): boolean {
    return this.eventLog.deviceStatus !== DeviceStatusEventType.UNREGISTERED;
  }
}
