
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Store } from '@client/models';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import i18n from '@client/plugins/i18n/i18n';
import { TranslationKeys } from '@client/plugins/i18n/locales';
import { TranslateResult } from 'vue-i18n';

@Component({})
export default class EmailSubscribeButton extends Vue {
  @Prop()
  private store!: Store;
  @Prop()
  private isLoading!: boolean;

  private storesStore: StoresStore = useStoresStore();

  async changeSubscription(): Promise<void> {
    this.store.useEmailSubscription = !this.store.useEmailSubscription;
    await this.storesStore.updateEmailSubscriptionState(this.store.useEmailSubscription, this.store._id);
  }

  get buttonText(): TranslateResult {
    return !this.store.useEmailSubscription
      ? i18n.t(
          TranslationKeys.settings.releaseManagement.store.currentReleaseManagementModal.subscribeButton.subscribe.title
            .$path
        )
      : i18n.t(
          TranslationKeys.settings.releaseManagement.store.currentReleaseManagementModal.subscribeButton.unsubscribe
            .title.$path
        );
  }

  get tooltipMessage(): TranslateResult {
    return !this.store.useEmailSubscription
      ? i18n.t(
          TranslationKeys.settings.releaseManagement.store.currentReleaseManagementModal.subscribeButton.subscribe
            .tooltip.$path
        )
      : i18n.t(
          TranslationKeys.settings.releaseManagement.store.currentReleaseManagementModal.subscribeButton.unsubscribe
            .tooltip.$path
        );
  }

  get icon(): string {
    return !this.store.useEmailSubscription ? 'mdi-bell' : 'mdi-bell-off';
  }
}
