
import { Component, Vue } from 'vue-property-decorator';
import { TagsStore, useTagsStore } from '@client/stores/tags/store';
import TagDialog from '@client/components/Settings/Tags/TagDialog.vue';

@Component({
  components: { TagDialog },
})
export default class AddTagButton extends Vue {
  private showDialog: boolean = false;
  private tagsStore: TagsStore = useTagsStore();

  closeDialog(): void {
    this.showDialog = false;
  }
  get isLoading(): boolean {
    return this.tagsStore.isFetching || this.tagsStore.isActionPending;
  }
}
