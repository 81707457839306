import { BaseLayer } from '@client/models/ScheduleModels';
import BaseLayerModel from '@client/models/ScheduleModels/BaseLayer.model';
import { PublishingForegroundContentModelJSON, PublishingScheduledContentModelJSON } from '@common/schedule/types';
import DevicePublishingScheduledContentModel from '@client/models/DeviceModels/DevicePublishingScheduledContentModel';
import { Optional } from '@common/types';

export default class DevicePublishingForegroundContentModel {
  baseLayer: BaseLayer;
  scheduledContent?: Array<DevicePublishingScheduledContentModel>;

  constructor(baseLayer: BaseLayer, scheduledContent?: Optional<Array<DevicePublishingScheduledContentModel>>) {
    this.baseLayer = baseLayer;
    this.scheduledContent = scheduledContent || [];
  }

  public static fromJSON(
    publishingForegroundContentModelJSON: PublishingForegroundContentModelJSON
  ): DevicePublishingForegroundContentModel {
    const baseLayerModel: BaseLayerModel = BaseLayer.fromJSON(publishingForegroundContentModelJSON.baseLayer);
    const scheduledContentModel: DevicePublishingScheduledContentModel[] | undefined =
      publishingForegroundContentModelJSON.scheduledContent?.map(
        (scheduledContentItem: PublishingScheduledContentModelJSON) =>
          DevicePublishingScheduledContentModel.fromJSON(scheduledContentItem)
      );

    return new DevicePublishingForegroundContentModel(baseLayerModel, scheduledContentModel);
  }

  public toJSON(): PublishingForegroundContentModelJSON {
    return {
      baseLayer: this.baseLayer.toJSON(),
      scheduledContent: this.scheduledContent?.map(
        (publishingScheduledContentModel: DevicePublishingScheduledContentModel) =>
          publishingScheduledContentModel.toJSON()
      ),
    };
  }

  public clone(): DevicePublishingForegroundContentModel {
    return new DevicePublishingForegroundContentModel(
      this.baseLayer?.clone(),
      this.scheduledContent?.map((publishingScheduledContentModel: DevicePublishingScheduledContentModel) =>
        publishingScheduledContentModel.clone()
      )
    );
  }
}
