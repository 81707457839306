
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DetectedObjectEntry, DeviceSensorObjects } from '@common/apim/definitions';
import { secondsToHHMMSS } from '@client/components/SensorAnalytics/charts/utils';

@Component({
  methods: { secondsToHHMMSS },
})
export default class TimePerArea extends Vue {
  /* DECLARATIONS */
  @Prop()
  private sensorDataObjects!: DeviceSensorObjects | null;
  /* LIFECYCLE EVENTS */
  /* METHODS */
  /* GETTERS */
  get totalDwellObjects(): number {
    if (!this.sensorDataObjects) {
      return 0;
    }
    return this.sensorDataObjects.detectedObjects.filter(
      (detectedObject: DetectedObjectEntry) => detectedObject.detectedObject.dwellTime > 0
    ).length;
  }

  get totalDwellTime(): number {
    if (!this.sensorDataObjects) {
      return 0;
    }
    return this.sensorDataObjects.detectedObjects.reduce((acc: number, detectedObject: DetectedObjectEntry) => {
      return acc + detectedObject.detectedObject.dwellTime;
    }, 0);
  }

  get totalDecisionObjects(): number {
    if (!this.sensorDataObjects) {
      return 0;
    }
    return this.sensorDataObjects.detectedObjects.filter(
      (detectedObject: DetectedObjectEntry) => detectedObject.detectedObject.decisionTime > 0
    ).length;
  }

  get totalDecisionTime(): number {
    if (!this.sensorDataObjects) {
      return 0;
    }
    return this.sensorDataObjects.detectedObjects.reduce((acc: number, detectedObject: DetectedObjectEntry) => {
      return acc + detectedObject.detectedObject.decisionTime;
    }, 0);
  }

  get totalExposedObjects(): number {
    if (!this.sensorDataObjects) {
      return 0;
    }
    return this.sensorDataObjects.detectedObjects.filter(
      (detectedObject: DetectedObjectEntry) => detectedObject.detectedObject.exposureTime > 0
    ).length;
  }

  get totalExposedTime(): number {
    if (!this.sensorDataObjects) {
      return 0;
    }
    return this.sensorDataObjects.detectedObjects.reduce((acc: number, detectedObject: DetectedObjectEntry) => {
      return acc + detectedObject.detectedObject.exposureTime;
    }, 0);
  }

  get averageExposedTime(): number {
    return this.totalExposedObjects > 0 ? this.totalExposedTime / this.totalExposedObjects : 0;
  }

  get averageDwellTime(): number {
    return this.totalDwellObjects > 0 ? this.totalDwellTime / this.totalDwellObjects : 0;
  }

  get averageDecisionTime(): number {
    return this.totalDecisionObjects > 0 ? this.totalDecisionTime / this.totalDecisionObjects : 0;
  }
}
