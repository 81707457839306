
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class HeaderNumberRangeFilter extends Vue {
  @Prop()
  private headerText!: string;
  @Prop()
  private defaultValue!: [number?, number?];
  /**
   * This property indicates that clearing of a filter value will be done through chip displayed above the table
   * If not set, clearing of value will be done inside the v-menu where value is entered.
   */
  @Prop({ default: false })
  private useChips!: boolean;

  private filterValue: [number?, number?] = this.defaultValue;

  clearFilter(): void {
    this.filterValue = [];
  }

  validateInput(): void {
    if (!!this.filterValue[0] && !!this.filterValue[1] && this.filterValue[0] > this.filterValue[1]) {
      const temp: number | undefined = this.filterValue[0];
      Vue.set(this.filterValue, 0, this.filterValue[1]);
      Vue.set(this.filterValue, 1, temp);
    }
  }

  @Watch('filterValue')
  emitValueChanged(): void {
    this.$emit('change', this.filterValue);
  }

  @Watch('defaultValue')
  defaultValueChanged(): void {
    this.filterValue = this.defaultValue;
  }

  get filterButtonColor(): string {
    return !this.useChips && this.filterValue ? 'primary' : '';
  }
}
