var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"gondola-indicators",on:{"dragover":function($event){$event.preventDefault();}}},[_c('span',{class:{
      'gondola-indicator gondola-indicator__top': true,
      'hide-indicator': !_vm.isHovering(_vm.rowIndex - 1, _vm.colIndex, `${_vm.rowIndex}-${_vm.colIndex}`),
    },on:{"dragover":function($event){$event.preventDefault();},"dragenter":function($event){return _vm.onDragEnter(_vm.rowIndex - 1, _vm.colIndex, `${_vm.rowIndex}-${_vm.colIndex}`)},"dragleave":function($event){return _vm.onDragLeave(`${_vm.rowIndex}-${_vm.colIndex}`)},"drop":function($event){return _vm.handleDropToTop(_vm.rowIndex - 1)}}}),_c('span',{class:{
      'gondola-indicator gondola-indicator__bottom': true,
      'hide-indicator': !_vm.isHovering(_vm.rowIndex + 1, _vm.colIndex, `${_vm.rowIndex}-${_vm.colIndex}`),
    },on:{"dragover":function($event){$event.preventDefault();},"dragenter":function($event){return _vm.onDragEnter(_vm.rowIndex + 1, _vm.colIndex, `${_vm.rowIndex}-${_vm.colIndex}`)},"dragleave":function($event){return _vm.onDragLeave(`${_vm.rowIndex}-${_vm.colIndex}`)},"drop":function($event){return _vm.handleDropToBottom(_vm.rowIndex + 1)}}}),_c('span',{class:{
      'gondola-indicator gondola-indicator__left': true,
      'hide-indicator': !_vm.isHovering(_vm.rowIndex, _vm.colIndex - 1, `${_vm.rowIndex}-${_vm.colIndex}`),
    },on:{"dragover":function($event){$event.preventDefault();},"dragenter":function($event){return _vm.onDragEnter(_vm.rowIndex, _vm.colIndex - 1, `${_vm.rowIndex}-${_vm.colIndex}`)},"dragleave":function($event){return _vm.onDragLeave(`${_vm.rowIndex}-${_vm.colIndex}`)},"drop":function($event){return _vm.handleDropToLeft(_vm.rowIndex, _vm.colIndex - 1)}}}),_c('span',{class:{
      'gondola-indicator gondola-indicator__right': true,
      'hide-indicator': !_vm.isHovering(_vm.rowIndex, _vm.colIndex + 1, `${_vm.rowIndex}-${_vm.colIndex}`),
    },on:{"dragover":function($event){$event.preventDefault();},"dragenter":function($event){return _vm.onDragEnter(_vm.rowIndex, _vm.colIndex + 1, `${_vm.rowIndex}-${_vm.colIndex}`)},"dragleave":function($event){return _vm.onDragLeave(`${_vm.rowIndex}-${_vm.colIndex}`)},"drop":function($event){return _vm.handleDropToRight(_vm.rowIndex, _vm.colIndex + 1)}}}),_c('div',{attrs:{"draggable":"true"},on:{"click":function($event){$event.stopPropagation();return _vm.setSelectedDevice(_vm.deviceTemplate, _vm.rowIndex, _vm.colIndex)},"dragstart":function($event){return _vm.onDragStartDevice(_vm.deviceTemplate, _vm.rowIndex, _vm.colIndex, $event)},"dragover":function($event){$event.preventDefault();},"dragend":_vm.onDragEnd}},[_c('div',{staticClass:"device-container device-template shadowed",class:{ 'device-template__selected': _vm.isDeviceSelected }},[(_vm.isDeviceSelected)?_c('span',{staticClass:"selected-device-border"}):_vm._e(),_c('v-icon',{staticClass:"drag-icon",attrs:{"small":""}},[_vm._v("mdi-drag")]),_c('div',{staticClass:"device elevation-1",class:_vm.getSize},[[(_vm.deviceHasContent)?_c('Preview',{attrs:{"selected-schedule":_vm.getSelectedSchedule,"hardwareModel":_vm.hardwareModel,"foregroundContentItems":_vm.getForegroundContentItems,"backgroundContentItem":_vm.getBackgroundContentItem}}):_c('v-chip',{staticClass:"no-content",attrs:{"label":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-information-outline")]),_vm._v(" "+_vm._s(_vm.$t(_vm.$i18nTranslationKeys.device.noContent.$path))+" ")],1)]],2),_c('div',{staticClass:"device__badges"},[(!_vm.isDeviceGroupUnlinked)?_c('div',{staticClass:"device__badge elevation-2",style:({ background: _vm.railGroupColor })},[_c('v-icon',{staticClass:"device__badge-icon",attrs:{"color":_vm.railGroupIconColor,"small":""}},[_vm._v("mdi-link-variant")])],1):_vm._e()])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }