// Note: This file contains utility functions for the router

import { Optional } from '@common/types';

/**
 * Get the path for the store detail page
 * @param storeId id of the store
 * @param aisle the aisle to navigate to
 * @param position the position in aisle to navigate to
 */
export const getStoreDetailPath = (storeId: string, aisle?: string, position?: string) => {
  let storeLink: string = `/stores/${storeId}`;
  if (!aisle) {
    return storeLink;
  }
  if (aisle || position) {
    storeLink += `?`;
  }
  if (aisle) {
    storeLink += `aisle=${aisle}`;
  }
  if (aisle && position) {
    storeLink += `&`;
  }
  if (position) {
    storeLink += `position=${position}`;
  }
  return storeLink;
};

/**
 * Get the path for the device detail page
 * @param deviceId id of the device
 */
export const getDeviceDetailPath = (deviceId: string) => {
  return `/devices/${deviceId}`;
};

/**
 * Used to check if the path is an exception
 * The only exception is the assets page as it uses the id just to navigate without changing the page
 * @param routeName - The route name to check
 */
export const checkForRouteException = (routeName: Optional<string>): boolean => {
  if (!routeName) {
    return false;
  }
  const exceptionPaths: string[] = ['assets'];
  return exceptionPaths.includes(routeName);
};
