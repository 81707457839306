
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  DEFAULT_COMMON_STRING_MAX_LENGTH,
  validateNoSymbolsInString,
  validateTextFieldLength,
} from '@client/utils/validateTextFieldLength';
import { ContentItemsStore, useContentItemsStore } from '@client/stores/contentItems';
import FolderModel from '@client/models/ContentModels/Folder.model';
import { AppGlobalStore, useAppGlobalStore } from '@client/stores/app-global';
import { AxiosError, AxiosResponse, isAxiosError } from 'axios';
import { ContentItemJSON } from '@common/content-item/types';
import { BaseError } from '@common/error/types';
import { isStringSymbolsPure } from '@common/utils/validation';
import { TranslateResult } from 'vue-i18n';
import { Optional } from '@common/types';
import { ContentItem } from '@client/models/ContentModels/types';

@Component({
  methods: {
    validateTextFieldLength,
    validateNoSymbolsInString,
  },
})
export default class AddFolderDialog extends Vue {
  /* DECLARATIONS */
  @Prop()
  setSelectedFolder!: (folderId: string) => void;
  @Prop()
  onCancel!: () => void;
  @Prop()
  selectedFolder!: string;
  @Prop({ default: false })
  isDisabled!: boolean;
  @Prop({ default: false })
  private showDialog!: boolean;

  private contentItemsStore: ContentItemsStore = useContentItemsStore();
  private appGlobalStore: AppGlobalStore = useAppGlobalStore();

  private folderName: string = '';
  /* LIFECYCLE EVENTS */
  /* METHODS */
  cancel(): void {
    this.onCancel();
    this.folderName = '';
  }

  async createFolder(): Promise<void> {
    if (!this.folderName) {
      return;
    }
    if (
      validateNoSymbolsInString(this.folderName) !== true ||
      validateTextFieldLength(this.folderName, DEFAULT_COMMON_STRING_MAX_LENGTH) !== true
    ) {
      return;
    }
    const newFolder: FolderModel = FolderModel.getDefaultEmptyFolder();
    newFolder.customerId = this.appGlobalStore.customer;
    newFolder.name = this.folderName;
    if (this.selectedFolder) {
      newFolder.parentFolder = this.selectedFolder;
    }
    const response: AxiosResponse<ContentItemJSON> | AxiosError<BaseError> =
      await this.contentItemsStore.createContentItem(newFolder);
    if (!isAxiosError(response)) {
      this.setSelectedFolder(response.data._id);
      this.cancel();
    }
  }
  /* GETTERS */

  get folderNameTitle(): string {
    return `${this.$t(this.$i18nTranslationKeys.contentManagement.folder.$path)} ${this.$t(
      this.$i18nTranslationKeys.deviceView.name.$path
    )}`;
  }

  get DEFAULT_COMMON_STRING_MAX_LENGTH(): number {
    return DEFAULT_COMMON_STRING_MAX_LENGTH;
  }

  get selectedFolderName(): TranslateResult {
    const selectedFolder: Optional<ContentItem> = this.contentItemsStore.getContentItemById(this.selectedFolder);
    if (!selectedFolder) {
      return this.$t(this.$i18nTranslationKeys.contentManagement.root.$path);
    }
    return selectedFolder.name;
  }

  get isFolderNameValid(): boolean {
    return (
      isStringSymbolsPure(this.folderName) &&
      validateTextFieldLength(this.folderName, DEFAULT_COMMON_STRING_MAX_LENGTH, true) === true
    );
  }

  get isLoading(): boolean {
    return this.contentItemsStore.isActionPending;
  }
}
