import { ScheduleLayer } from '@client/models';
import { PublishingScheduledContentModelJSON } from '@common/schedule/types';
import ScheduleLayerModel from '@client/models/ScheduleModels/ScheduleLayer.model';
import ScheduleModel from '@client/models/ScheduleModels/Schedule.model';

export default class DevicePublishingScheduledContentModel {
  schedule?: ScheduleModel;
  layer: ScheduleLayer;

  constructor(layer: ScheduleLayer, schedule?: ScheduleModel) {
    this.schedule = schedule;
    this.layer = layer;
  }

  public static fromJSON(
    publishingScheduledContentModelJSON: PublishingScheduledContentModelJSON
  ): DevicePublishingScheduledContentModel {
    const scheduleLayerModel: ScheduleLayerModel = ScheduleLayer.fromJSON(publishingScheduledContentModelJSON.layer);
    return new DevicePublishingScheduledContentModel(
      scheduleLayerModel,
      publishingScheduledContentModelJSON.schedule
        ? ScheduleModel.fromJSON(publishingScheduledContentModelJSON.schedule)
        : undefined
    );
  }

  public toJSON(): PublishingScheduledContentModelJSON {
    return {
      schedule: this.schedule,
      layer: this.layer.toJSON(),
    };
  }

  public clone(): DevicePublishingScheduledContentModel {
    const scheduleLayerModel: ScheduleLayerModel = this.layer.clone();
    return new DevicePublishingScheduledContentModel(scheduleLayerModel, this.schedule);
  }
}
