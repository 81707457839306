
import { BackgroundContent, DeviceTemplate, ForegroundContent, ScheduledContent } from '@client/models';
import PlaylistModel from '@client/models/ContentModels/Playlist.model';
import { ContentItem, Media, MediaFile } from '@client/models/ContentModels/types';
import VideoModel from '@client/models/ContentModels/Video.model';
import GondolaTemplate from '@client/models/GondolaModels/GondolaTemplate.model';
import { ContentItemsStore, useContentItemsStore } from '@client/stores/contentItems';
import videoDurationParser from '@client/utils/videoDurationParser';
import { ContentType } from '@common/enums';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { getFPSDisplayValue, replaceContentItemInRailGrid } from '../ContentItems/utils';
import MediaItemExpanded from '../ContentItems/ListItemExpanded/MediaItemExpanded.vue';
import PlaylistItemExpanded from '../ContentItems/ListItemExpanded/PlaylistItemExpanded.vue';
import { CustomDataTableHeader } from '@client/definitions/CustomDataTableHeader';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import ContentItemsList from '../ContentItems/ContentItemsList.vue';
import { ContentItemSelectionTarget } from '@client/enums';
import Wrapper from '@client/components/Layouts/Wrapper.vue';
import PlaylistItemPreviewDialog from '@client/components/ContentItems/ContentItemPreviewDialog.vue';

interface UsedPosition {
  col: number;
  row: number;
  isForeground: boolean;
  schedule: string;
}

@Component({
  components: {
    PlaylistItemPreviewDialog,
    Wrapper,
    ContentItemsList,
    PlaylistItemExpanded,
    MediaItemExpanded,
  },
})
export default class TemplateAssets extends Vue {
  /* DECLARATIONS */
  @Prop()
  private gondolaTemplate!: GondolaTemplate;
  @Prop({ default: () => 'BACKGROUND' })
  private showAssetsMode!: 'BACKGROUND' | 'LABELS';

  private contentItemsStore: ContentItemsStore = useContentItemsStore();
  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();

  private showContentItemDialog: boolean = false;
  private selectedContentItem: Media | null = null;
  private contentItemsUsedInTemplate: Map<string, Array<UsedPosition>> = new Map();
  private contentItemsNamesUsedInRailGrid: Array<string> = [];

  private playlistItemToPreview: VideoModel = VideoModel.getDefaultEmptyVideo();
  private playlistItemPreviewUrl: string = '';
  private showPlaylistItemPreview: boolean = false;

  /* LIFECYCLE EVENTS */
  mounted() {
    this.computeContentItemsUsedInRailGrid();
  }

  /* METHODS */

  getContentItemName(contentItem: Media): string {
    if (contentItem.type === ContentType.Playlist) {
      return contentItem.name;
    }
    return (contentItem as MediaFile).name;
  }

  getContentItemOriginalName(contentItem: Media): string {
    if (contentItem.type === ContentType.Playlist) {
      return contentItem.name;
    }
    return (contentItem as MediaFile).originalName;
  }

  getContentItemDuration(contentItem: Media): string {
    if (contentItem.type === ContentType.Image) {
      return '-';
    }
    return videoDurationParser((contentItem as VideoModel | PlaylistModel).duration);
  }

  getContentItemDimensions(contentItem: Media): string {
    if (contentItem.type === ContentType.Playlist) {
      return '-';
    }
    return (contentItem as MediaFile).dimensions;
  }

  getContentItemFps(contentItem: Media): string {
    if (contentItem.type !== ContentType.Video) {
      return '-';
    }
    return getFPSDisplayValue((contentItem as VideoModel).fps);
  }

  async openPreviewVideo(video: VideoModel): Promise<void> {
    this.playlistItemPreviewUrl = await video.getBlobUrl();
    this.playlistItemToPreview = video;
    this.showPlaylistItemPreview = true;
  }

  closePreviewVideo(): void {
    this.showPlaylistItemPreview = false;
    this.playlistItemToPreview = VideoModel.getDefaultEmptyVideo();
    this.playlistItemPreviewUrl = '';
  }

  closeContentItemDialog(): void {
    this.selectedContentItem = null;
    this.showContentItemDialog = false;
  }

  replaceFile(contentItem: Media): void {
    this.selectedContentItem = contentItem;
    this.showContentItemDialog = true;
  }

  replaceFileInTemplate(contentItem: ContentItem): void {
    replaceContentItemInRailGrid(this.railGrid, this.selectedContentItemName, contentItem as Media);
    this.computeContentItemsUsedInRailGrid();
    this.closeContentItemDialog();
  }

  @Watch('gondolaTemplate.railGrid', { deep: true })
  computeContentItemsUsedInRailGrid(): void {
    this.contentItemsUsedInTemplate = new Map();
    this.railGrid.forEach((row: Array<DeviceTemplate>, rowIndex: number) => {
      row.forEach((deviceTemplate: DeviceTemplate, colIndex: number) => {
        if (this.isAssetsModeBackground) {
          deviceTemplate.backgroundContent.flat().forEach((backgroundContent: BackgroundContent) => {
            if (backgroundContent.baseLayer) {
              const item: Array<UsedPosition> | undefined = this.contentItemsUsedInTemplate.get(
                backgroundContent.baseLayer.name
              );
              if (item) {
                item.push({ col: colIndex, row: rowIndex, schedule: '', isForeground: false });
              } else {
                this.contentItemsUsedInTemplate.set(backgroundContent.baseLayer.name, [
                  { col: colIndex, row: rowIndex, schedule: '', isForeground: false },
                ]);
              }
            }
            backgroundContent.scheduledContent?.forEach((scheduledContent: ScheduledContent) => {
              const item: Array<UsedPosition> | undefined = this.contentItemsUsedInTemplate.get(
                scheduledContent.layer.name
              );
              if (item) {
                item.push({ col: colIndex, row: rowIndex, schedule: scheduledContent.scheduleId, isForeground: false });
              } else {
                this.contentItemsUsedInTemplate.set(scheduledContent.layer.name, [
                  { col: colIndex, row: rowIndex, schedule: scheduledContent.scheduleId, isForeground: false },
                ]);
              }
            });
          });
        } else {
          deviceTemplate.foregroundContent.flat().forEach((foregroundContent: ForegroundContent) => {
            const item: Array<UsedPosition> | undefined = this.contentItemsUsedInTemplate.get(
              foregroundContent.baseLayer.name
            );
            if (item) {
              item.push({ col: colIndex, row: rowIndex, schedule: '', isForeground: true });
            } else {
              this.contentItemsUsedInTemplate.set(foregroundContent.baseLayer.name, [
                { col: colIndex, row: rowIndex, schedule: '', isForeground: true },
              ]);
            }
            foregroundContent.scheduledContent?.forEach((scheduledContent: ScheduledContent) => {
              const item: Array<UsedPosition> | undefined = this.contentItemsUsedInTemplate.get(
                scheduledContent.layer.name
              );
              if (item) {
                item.push({ col: colIndex, row: rowIndex, schedule: scheduledContent.scheduleId, isForeground: true });
              } else {
                this.contentItemsUsedInTemplate.set(scheduledContent.layer.name, [
                  { col: colIndex, row: rowIndex, schedule: scheduledContent.scheduleId, isForeground: true },
                ]);
              }
            });
          });
        }
      });
    });
    this.contentItemsNamesUsedInRailGrid = Array.from(this.contentItemsUsedInTemplate.keys());
  }

  getTimesUsedInBackground(contentItem: Media): number {
    const usedPositions: Array<UsedPosition> = this.contentItemsUsedInTemplate.get(contentItem.name) || [];
    return usedPositions.filter((usedPosition: UsedPosition) => !usedPosition.isForeground).length;
  }

  getTimesUsedInLabels(contentItem: Media): number {
    const usedPositions: Array<UsedPosition> = this.contentItemsUsedInTemplate.get(contentItem.name) || [];
    return usedPositions.filter((usedPosition: UsedPosition) => usedPosition.isForeground).length;
  }

  /* GETTERS */
  get railGrid() {
    return this.gondolaTemplate.railGrid;
  }

  get contentItemsUsedInRailGrid() {
    const mediaFiles: Array<Media> = this.contentItemsStore.contentItems.filter((contentItem: ContentItem) => {
      return contentItem.type !== ContentType.Folder;
    }) as Array<Media>;
    return mediaFiles.filter((contentItem: Media) => {
      return this.contentItemsNamesUsedInRailGrid.includes(contentItem.name);
    });
  }

  get headers(): Array<CustomDataTableHeader> {
    const headers: Array<CustomDataTableHeader> = [
      {
        text: this.$t(this.$i18nTranslationKeys.deviceView.fileType.$path),
        align: 'left',
        value: 'type',
        sortable: true,
      },
      {
        text: this.$t(this.$i18nTranslationKeys.deviceView.backgroundFileName.$path),
        align: 'left',
        value: 'originalName',
      },
      {
        text: this.$t(this.$i18nTranslationKeys.deviceView.createDate.$path),
        align: 'left',
        value: 'createdAtAsTime',
      },
    ];
    if (this.isAssetsModeBackground) {
      headers.push({
        text: 'Times used in background',
        align: 'left',
        value: '_id',
      });
    } else {
      headers.push({
        text: 'Times used in labels',
        align: 'left',
        value: '_id',
      });
    }
    headers.push(
      {
        text: this.$t(this.$i18nTranslationKeys.contentManagement.dimensions.$path),
        align: 'left',
        value: 'dimensions',
      },
      {
        text: this.$t(this.$i18nTranslationKeys.deviceView.duration.$path),
        align: 'left',
        value: 'duration',
      },
      {
        text: this.$t(this.$i18nTranslationKeys.deviceView.fps.$path),
        align: 'left',
        value: 'fps',
      },
      {
        text: this.$t(this.$i18nTranslationKeys.action.actions.$path),
        align: 'center',
        value: '_id',
      }
    );
    return headers;
  }

  get isLoading(): boolean {
    return this.gondolaTemplatesStore.loadingIndicator.update;
  }

  get selectableContentType(): Array<ContentType> {
    if (this.isAssetsModeBackground) {
      return [ContentType.Image, ContentType.Playlist, ContentType.Video, ContentType.Folder];
    }
    if (!this.selectedContentItem) {
      return [ContentType.Image, ContentType.Folder];
    }
    return [this.selectedContentItem.type, ContentType.Folder];
  }

  get selectedContentItemName(): string {
    if (!this.selectedContentItem) {
      return '';
    }
    return this.getContentItemName(this.selectedContentItem);
  }

  get contentItemSelectionTarget(): ContentItemSelectionTarget {
    return ContentItemSelectionTarget.REPLACE_ITEM;
  }

  get isAssetsModeBackground(): boolean {
    return this.showAssetsMode === 'BACKGROUND';
  }
}
