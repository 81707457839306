
import { Component, Prop, Vue } from 'vue-property-decorator';
import ConfigDialog from '@client/components/Settings/ReleaseManagement/ConfigDialog.vue';
import { ReleaseManagementStore, useReleaseManagementStore } from '@client/stores/releaseManagement/store';

@Component({
  components: { ConfigDialog },
})
export default class AddButton extends Vue {
  @Prop()
  private configCount!: number;
  @Prop()
  private storesWithDefaultConfigCount!: number;

  private showDialog: boolean = false;

  private releaseManagementStore: ReleaseManagementStore = useReleaseManagementStore();

  closeDialog(): void {
    this.showDialog = false;
  }

  get isLoading(): boolean {
    return this.releaseManagementStore.isFetching || this.releaseManagementStore.isActionPending;
  }
}
