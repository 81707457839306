import { ja as vuetify } from 'vuetify/src/locale/';
import { TranslationType } from '@client/plugins/i18n/locales/translationTypes';
import VueI18n from 'vue-i18n';
import LocaleMessageObject = VueI18n.LocaleMessageObject;

const japaneseTranslationObject: TranslationType = {
  $vuetify: vuetify,
  language: '言語設定',
  contentManagement: {
    uploadFile: 'ファイルをアップロード',
    uploadFolder: 'フォルダをアップロード',
    files: 'ファイル',
    title: 'コンテンツ管理',
    openInNewTab: '新しいタブでコンテンツ管理ページを開く',
    folder: 'フォルダ',
    image: '画像',
    video: 'ビデオ',
    playlist: 'プレイリスト',
    file: 'ファイル',
    location: '場所',
    searchResults: '結果なし | 1件の結果 | {count}件の結果',
    maximumResults: '上位{count}件の結果を表示',
    filesToUpload: 'アップロードするファイル',
    isReadyToUpload: 'アップロード準備完了。',
    selectedFiles: '<strong>{numberOfFiles}</strong> 選択されたファイル <strong>({size})</strong>:',
    filteredFiles:
      '{originalNumberOfFiles}件の選択ファイルのうち、{allowedNumberOfFiles}件のみアップロード可能。受け入れ可能な拡張子は".gif",".png", ".apng", ".mp4" です。',
    noMatchingFiles:
      '選択されたファイルは互換性がありません。受け入れ可能な拡張子は".gif",".png", ".apng", ".mp4" です。',
    andOtherFiles: '他<strong>{numberOfFiles}</strong>件のファイル...',
    uploadQueue: 'アップロードキュー',
    uploadHistory: 'アップロード履歴',
    cancelOngoingUploads: 'アップロードをキャンセル',
    cancelUpload: 'アップロードキャンセル',
    uploadCancelled: 'キャンセルされました',
    error: 'エラー',
    uploadedSuccessfully: '正常にアップロードされました',
    recomputeMetadata: 'ファイルのメタデータを再計算',
    dimensions: '寸法',
    assign: '割り当て',
    addFolderTitle: 'フォルダを追加',
    root: 'ルート',
    noDataInFolder: 'このフォルダにはアイテムがありません。',
    folderNotFound: '探しているフォルダが存在しません！削除された可能性があります。',
    duplicateItemInFolder:
      'このフォルダには同じ名前のアイテムが既に存在します。プレイリストとフォルダはユニークな名前でなければなりません。',
    folderCannotMoveToChild: 'フォルダをその子に移動することはできません。',
    folderCannotMoveToSelf: 'フォルダを自分自身に移動することはできません。',
    itemAlreadyInFolder:
      ' - | このアイテムは既に選択されたフォルダにあります。 | 選択されたフォルダにはすでに一部または全部のアイテムが存在します。',
    movingItemTo:
      '<strong class="primary--text">{itemToMove}</strong>を<strong class="{targetClass}">{target}</strong>に移動',
    movingItems: '{count} アイテム',
    assignContent: 'コンテンツを割り当て',
    previewFailed: 'ポストプロセッシングが失敗しました。再試行してください。',
    recomputePreview: '<span class="text-lowercase">{type}</span>を再処理',
    folderDeletedFromAnotherInstance:
      '選択したフォルダは別のユーザーによって削除されました。ルートフォルダにリダイレクトされました。',
    keepFolderStructure: 'フォルダ構造を保持する',
    foldersCreated: '{numberOfFolders} 個のフォルダが作成されました/見つかりました。',
  },
  storeCreation: {
    title: '新しいストアを作成\n',
    optional: '任意\n',
    steps: {
      review: 'レビュー',
      idAzure: 'Azure ID',
      basicSettings: '基本設定',
    },
    validation: {
      error: '無効なAzure ID 詳細：{message}',
      success: 'このAzure IDは次のものと紐づいています',
      validAzureId: '有効なAzure ID',
      invalidAzureId: '無効なAzure ID',
    },
    associatedDevices: '紐づいてるデバイス',
    devices: '{devices} デバイス',
  },
  storeOverview: { breadcrumb: 'ストア一覧', heading: 'ストア', title: 'ストア一覧' },
  devices: {
    shortId: 'ショートID',
    longId: 'ロングID',
    hardwareModel: 'ハードウェアモデル',
    store: 'ストア',
    device: 'デバイス',
    aisle: '通路',
    section: 'セクション',
    onlineStatus: 'オンラインステータス',
    onlineStatusChanged: '{date}からオンライン',
    offlineStatusChanged: '{date}からオフライン',
    publishState: '公開状態',
    publishTemplateDate: '{date}に公開',
    publishTemplateName: '最後に公開されたテンプレート',
    deletedTemplate: '公開された後にテンプレートが削除されました。',
    registrationDate: '登録日',
    hardwareDimensions: 'ハードウェアの寸法',
    dimmingLevel: '調光レベル',
    firmware: 'ファームウェア',
    wifiConfig: {
      title: 'Wifi設定',
      status: 'ステータス',
      address: 'アドレス',
      bitRate: 'ビットレート',
      bssid: 'BSSID',
      channel: 'チャンネル',
      connectivity: '接続性',
      frequency: '周波数',
      hardwareAddress: 'ハードウェアアドレス',
      mask: 'マスク',
      linkQuality: 'リンク品質',
      ssid: 'SSID',
      signalLevel: '信号レベル',
      lastActivityDateTime: '最後のアクティビティのタイムスタンプ',
      expectedSSID: '期待されるSSID',
    },
    overviewBreadcrumb: 'デバイスの概要',
    overviewPageTitle: 'デバイスの概要ページ',
    dimensionOfDevice: 'デバイスの寸法: {width}x{height}',
    exportToCSV: '選択したアイテムをCSVにエクスポート',
    navigationTitle: 'デバイス',
    features: '機能',
    sensor: 'センサー',
    hardware: 'ハードウェア',
    loadWifiConfiguration: 'Wi-Fi設定の読み込み',
    showDeviceOnly: 'デバイスのみ表示',
    showInSection: 'セクション内に表示',
  },
  storeDetail: {
    breadcrumb: '{store}',
    heading: 'ストア情報',
    title: 'ストア情報ビュー',
    vrConnect: 'VUSIONRail: 接続する<br> QR コード',
    storeName: 'ストア名',
    editSettings: '一般設定を編集',
    storeAzureId: 'ストアID',
    timezone: 'タイムゾーン',
    tags: 'タグ',
    publishingInfo: '公開時とテンプレートが異なっていたため、プレビューが古くなっている可能性があります。',
    editStoreAzureIdWarning:
      'AzureのストアIDを変更すると、ストアのアクティブ時間がリセットされます。そのため、すべてのデバイスが常にアクティブになります。続行しますか？',
    missingAzureId: 'アクティブ時間は、ストアIDが空でない場合のみ設定可能です。',
    apimActionsDisabled: 'セクションにデバイスが設定されていないため、アクションを実行できません。',
    openTemplate: '最後に公開されたテンプレート"{template}"を開く',
    publishedTemplateDeleted: '公開されたテンプレートは削除されました。',
    sectionNotPublished: 'このセクションはまだ公開されていません。',
    createTemplateFromSection: 'セクション「{sectionName}」レイアウトからテンプレートを作成',
    activeHoursJobFailed: 'アクティブ時間のジョブに失敗しました。設定を再保存してください。',
    syncDevices: '同期',
    notFound: 'ストアが見つかりません。',
    storeNotFoundMessage: 'ストアが見つかりませんでした。削除されたか、存在しない可能性があります。',
    returnToPage: 'ストアのリストに戻る',
    aislesOverview: '通路の概要',
    settings: '設定',
    generalSettings: '一般設定',
    storeActions: {
      title: 'ストアアクション',
      deleteTitle: 'ストアの削除',
      warningTitle: '注意！',
      warningText:
        '次のアクションはストア全体に重大な影響を及ぼします、何をしているか理解してる場合のみに使用してください。',
      synchronizeTitle: 'ストアを同期\n',
    },
    noSectionsFound: '現在適用されているフィルタに一致するセクションがありません。',
    noSectionsCreated: 'まだセクションが作成されていません。',
    noAislesCreated: 'まだ通路が作成されていません',
    synchronizeStore: {
      instruction: 'ストアを同期では次のことを行います：',
      actionOne: 'ストアで利用可能なデバイスを同期',
      actionTwo: 'ストアのWebHookが最新であることを確認',
      actionThree: 'デバイスのステータスが古いなどのデバイスの割り当てにおける不整合を解消',
      warning: 'デバイスに公開されているコンテンツには一切影響しません',
    },
    createOrEditSections: {
      addSection: 'セクションを作成',
      editSection: 'セクションを編集',
      newSectionToAisle: '{aisle}に新しいセクションを追加',
      duplicateSection: 'このセクション名はすでに使用されています',
      validationErrors: {
        localDuplicateDevice: '重複するデバイスID{deviceId}',
        invalidDeviceId: '提供されたIDは有効なデバイスIDではありません',
        deviceDuplicate:
          'セクション<a href="/stores/{storeId}/gondola/{gondolaId}">{aisle} - {sectionName}</a>に重複するデバイスID {deviceId}があります',
        deviceNotFound: 'IDが{deviceId}のデバイスが見つかりません',
        requiredFields: 'すべての必須フィールドを正しく入力してください',
        oneSectionRequired: '少なくとも1つのセクションが必要',
        sensorDeviceDuplicate:
          'セクションにはすでにセンサー付きのデバイスがあります。セクションごとにセンサー付きデバイスは1台のみ許可されています。',
      },
    },
    createAisle: '通路を追加',
    duplicateAisle: 'この通路名はすでに使用されています',
    newAisleName: '新しい通路名',
    nameOfNewAisle: '新しい通路名',
    duplicateSection: 'このセクション名はすでに使用されています',
    copyAisleName: '通路名をクリップボードにコピーしました：<strong>{aisle}</strong>',
    copySectionName: 'セクション名をクリップボードにコピーしました：<strong>{section}</strong>',
    nameOfNewSection: '新しい通路名',
    deleteAisle: {
      title: '通路 <span class="error--text text-weight-medium">{aisle}</span> を削除しますか？',
      text: 'この操作は元に戻せません！この通路および紐づいているセクションに関連するすべての情報が失われます。',
    },
    deleteSection: {
      title: 'セクション <span class="error--text text-weight-medium">{section}</span> を削除しますか？',
      text: 'この操作は元に戻せません！このセクションに関連するすべての情報が失われます。',
    },
    deleteStore: {
      title: 'ストア <span class="error--text text-weight-medium">{store}</span> を削除しますか？',
      text: 'この操作は元に戻せません！このストアおよび紐づいている通路とセクションに関連するすべての情報が失われます。',
      validationErrors: {
        devicesRegistered:
          'この店舗にはデバイスが登録されているため、削除できません。<br/>続行するにはデバイスの登録を解除してください。',
      },
    },
  },
  gondolaView: {
    title: '新規/編集セクション',
    breadcrumbAdd: '新規セクション',
    breadcrumbEdit: '編集セクション',
    step1: '位置とレイアウト',
    step2: 'レイアウトの編集',
    step3: 'デバイスIDの設定',
    position: '通路の位置',
    validation: {
      aisleReq: '通路情報は必須です',
      templateReq: 'テンプレート情報が必要です。',
      positionReq: '通路の位置は必要です。',
      positionInUse: 'すでに使用されている通路の位置',
    },
    next: '次へ',
    back: '戻る',
    deviceId: 'デバイスID',
    confirmAndSave: 'ID情報を確認して保存',
    manual: '手動設定',
    fromTemplate: 'テンプレートを適用',
    validationError: {
      title: '検証エラー',
      text: 'セクション構成の保存中に問題が発生しました。',
      cancel: '保存を中止',
      confirm: 'エラーを無視して保存',
    },
    unsavedChanges: {
      title: '未保存の変更',
      text: '行った変更はまだ保存されていません!',
      cancel: '変更を無視',
      confirm: '変更内容を保存',
    },
    forceMove: {
      title: 'デバイスが既に割り当てられています！',
      cancel: '保存を中止',
      confirm: '強制移動して保存',
      message:
        'デバイスは一つのセクションにのみ追加できます。このセクションに現在のセクションからデバイスを強制的に移動できます。次のデバイスがこの変更に影響を受けます:',
    },
    finishEditing: '編集を終了して保存',
    gondolaNotFoundMessage: 'ゴンドラが見つかりませんでした。削除された可能性があるか、存在しないかもしれません。',
    returnToPage: 'ストアに戻る',
    notFound: 'セクションが見つかりません',
  },
  publishDetailView: {
    breadcrumb: '発行',
    heading: '新規作成発行',
    time: '活動時間',
    redirectMsg: '{time} 秒後にストア ページにリダイレクトされます',
    successMsg: 'すべてのデバイスに新しいコンテンツの公開を成功させました！',
    templatesFound:
      'セクションのレイアウトに一致するテンプレートが見つかりません | セクションのレイアウトに一致するテンプレートが1つあります | {n}のテンプレートがセクションのレイアウトと一致しています',
    matchingLayoutsFilter: '一致するレイアウトを持つテンプレートのみを表示',
    deviceError: {
      dimensionMismatch: 'テンプレートがセクションの寸法と一致しません',
      missingDeviceId: 'セクションにはIDが欠落しているデバイスがあります',
      publishingFailed: '発行に失敗しました: {devices}',
      publishingFailedDevice: 'ID {deviceId} を持つデバイスが {status} {error}で公開に失敗しました',
      backgroundBaseLayerMissing:
        'テンプレート内のいずれかのデバイスに背景値が設定されていないようです。ベースレイヤーにコンテンツが設定されていない場合、この背景に割り当てられた予定されたコンテンツは展開できません！',
      emptyPublishing: 'この発行の結果、現在選択されているテンプレートは空になります。',
      gondolaHasEmptyLabels: '空のラベルが含まれている場合、テンプレートを発行できません。',
      gondolaHasEmptyBackground: '空の背景を含むテンプレートは公開できません！',
      devicesNotCompatible:
        'テンプレートを公開することはできません。一部のデバイスがこのテンプレートをサポートしていません。',
      default: '不明なエラー',
    },
    deviceState: {
      backgroundMissing: '背景コンテンツがありません',
      backgroundSet: '新しい背景コンテンツが設定されました。',
    },
    validationError: {
      title: '検証エラー',
      text: 'ストアおよび/または選択したテンプレートが変更されました。 現在の構成を適用するか、リロードしてローカル データを更新できます。',
      cancel: '保存を中止',
      confirm: 'エラーを無視して保存',
    },
    timezoneError: { text: 'このストアを公開するには、タイムゾーンを設定する必要があります。' },
  },
  gondolaTemplateListView: {
    breadcrumb: 'テンプレート',
    title: 'テンプレート',
    actionPublish: 'セクションに公開',
    sameLayout: '同じレイアウトのセクション',
    alreadyPublished: '既に公開されたセクション',
    editModal: {
      titleEdit: 'テンプレート編集',
      titleAdd: 'テンプレート作成',
      titleClone: 'テンプレート複製',
    },
  },
  filtersAndPagination: {
    date: '日付',
    filters: 'フィルター',
    sortOrder: { name: '名前', createdAt: '作成日', updatedAt: '最終更新日' },
    resultsFound: '結果が見つかりませんでした。 | 1件の結果が見つかりました。 | {n}件の結果が見つかりました。',
    noResults: '現在のフィルタでは結果が見つかりません。',
    noEntries: 'エントリはまだ作成されていません。',
    dateFilterPrefill: {
      oneHour: '最後の時間',
      oneDay: '今日',
      oneWeek: '前週',
      oneMonth: '先月',
      custom: 'カスタム',
    },
    publishStateFilter: {
      published: '公開済み',
      pending: '保留中',
      accepted: '受け入れ済み',
      failed: '失敗',
      notSet: '何も公開されていない',
    },
    clearFilter: 'フィルターをクリア',
    enterSearchTerm: '検索語を入力',
    selectPublishingStatusType: '公開のステータスを選択',
    selectOnlineStatus: 'デバイスのステータスを選択',
    selectHardwareModel: 'ハードウェアモデルを選択',
    selectFileType: 'ファイルタイプを選択',
    enterMinValue: '最小値を入力',
    enterMaxValue: '最大値を入力',
    longerSeconds: '> {n} 秒 | > {n} 秒',
    shorterSeconds: '< {n} 秒 | < {n} 秒',
    betweenSeconds: '{0} - {1} 秒',
    clearAllFilters: 'すべてのフィルターをクリア',
  },
  gondolaTemplateDetailView: {
    breadcrumb: 'テンプレートの編集: {template}',
    templateName: 'テンプレート名',
    title: 'テンプレートの編集',
    layoutTab: 'レイアウト',
    scheduleTab: 'スケジュール管理',
    detailTab: '詳細',
    assetsTab: 'アセット',
    timesUsed: '使用回数',
    assetsUsedInBackground: '背景で使用されるアセット',
    assetsUsedInForeground: 'ラベルで使用されるアセット',
    tooltips: {
      invalidInput: 'すべての必須項目を正しく入力してください。',
      noChangesToSave: '現在、保存する必要のある変更はありません。',
    },
  },
  rePublishView: {
    breadcrumb: 'テンプレートの再発行: {template}',
    title: 'テンプレートの再発行',
    heading: 'テンプレート再発行',
    gondolas: '発行されたセクション',
    azureStoreId: 'ストア ID',
    lastPublishDate: '最新発行日',
    linkHint: '通路を新しいタブで開く',
    redirectMsg: '{time}秒でテンプレートの概要ページにリダイレクトされます。',
    noSections: {
      title: 'セクションが見つかりません',
      text: 'このテンプレートはどのセクションにも関連していません。',
      btn: '戻る',
    },
    publishStatus: '公開ステータス',
    notUpToDate: '最新ではありません。',
    upToDateText: '最新',
    templateContainsEmptyLabels: '現在のテンプレートには空のラベルが含まれています。',
    bulkPublish: '選択したセクションにテンプレートを公開',
    bulkRepublish: '選択したセクションにテンプレートを再公開',
    lastPublishedTemplate: '最後に公開されたテンプレート',
    publishBreadcrumb: 'テンプレート {template} のために公開',
    notUpToDateMessage: 'この公開は最新ではありません。',
    sectionNotSelectable: 'このセクションは、IDのないデバイスを含んでいるため選択できません。',
    validationFailed: '公開前の検証に失敗しました。',
    invalidHashTemplate: 'その間にテンプレートが更新されました。',
    missingTemplate: 'その間にテンプレートが削除されました。',
    backgroundMissing: 'テンプレートには背景がありません。',
    backgroundBaseLayerMissing: 'テンプレートにはベースコンテンツが設定されていません。',
    sectionError: '一部のセクションにエラーがあります。',
    invalidHashStore: '{store} の {section} はその間に更新されました。',
    dimensionMismatch: '{store} の {section} は選択したテンプレートと異なるレイアウトです。',
    missingDeviceId: '{store} の {section} にはIDのないデバイスがあります。',
    missingSection: 'ID: ${id} を持つセクションが見当たりません。',
    missingStore: 'ID: ${id} を持つストアが見当たりません。',
    missingTimezone: '{store} にはタイムゾーンが設定されていません。',
    publishingSuccessful: '公開が成功しました.',
  },
  deviceView: {
    title: 'デバイスを編集',
    breadcrumb: '行 {row} 列 {col} を編集',
    templateName: 'テンプレート名',
    rowIdx: 'テンプレートの行インデックス',
    colIdx: 'テンプレートの列インデックス',
    deleteFG: 'すべてのラベルを削除',
    deleteBG: '背景を削除',
    uploading: '{file}の追加:',
    uploadRate: '{progress}% - [{loaded} of {total} KB] をアップロードしています。',
    processing: '@ {progress}% 処理中',
    availableFiles: '利用可能なアイテムはありません | 1つの利用可能なアイテム | {count}つの利用可能なアイテム',
    contentItem: 'コンテンツアイテム',
    allLabels: 'すべてのラベル',
    uploadTarget: 'ラベル {label} 内容を設定',
    setContentBG: '背景のコンテンツを設定',
    fileType: '種類',
    previewImage: 'プレビュー画像',
    backgroundFileName: '名',
    createDate: '作成日',
    width: '幅',
    height: '高さ',
    duration: '再生時間',
    fps: 'FPS',
    checkAll: 'すべてチェック',
    uncheckAll: 'すべてチェックを外す',
    baseBackground: '基本の背景',
    baseBackgroundDeleteMessage:
      '基本の背景コンテンツを削除しますか? これにより、既存のスケジュールのバックグラウンドコンテンツもすべて削除されます。',
    baseBackgroundMissingMessage:
      '基本のコンテンツを設定して、バックグラウンドのコンテンツのスケジュールを有効にします。',
    baseForegroundMissingMessage: '基本のコンテンツを設定して、ラベルのコンテンツのスケジュールを有効にします。',
    createPlaylist: '新規プレイリストの作成',
    createFolder: '新しいフォルダ\n',
    name: 'リスト名',
    preview: 'プレビュー',
    playlist: 'プレイリスト',
    video: 'ビデオ',
    image: '画像',
    none: 'なし',
    playlistItems: 'プレイリストアイテム',
    hideLabels: 'ラベルを非表示',
    videosOnly: 'プレイリストにはビデオのみを含めることができます。このリストは利用可能なビデオのみを表示しています。',
    chooseContent: 'コンテンツを選択',
    currentContentType: '現在のコンテンツタイプ{type}',
    deletePlaylistItemWarning:
      'このコンテンツアイテムは次のプレイリストに存在します。これにより、このアイテムも上記のプレイリストから削除されます。',
    deleteContentItemTemplateReferenceWarning:
      'このコンテンツは、以下のテンプレートに含まれています。このテンプレートからこのコンテンツを削除することもできます：',
    noItemsInPlaylist: 'プレイリストにはまだアイテムがありません。',
    editContent: 'コンテンツ編集',
    background: '背景',
    labels: 'ラベル',
    label: 'ラベル {index}',
    contentBackgroundColor: 'プレビューの背景色を設定',
    addLabel: 'ラベルを追加',
    removeLabel: 'ラベルを削除',
    baseBackgroundAssignMessage: 'コンテンツが設定されていませんー編集ボタンをクリックして、コンテンツを割り当てます。',
    linkGroup: 'リンクグループ',
    addADevice: 'デバイスを追加',
    quickApplyBackground: '背景をすぐに適用',
  },
  device: {
    selectModel: 'デバイスモデル',
    noContent: 'コンテンツなし',
    status: { online: '{date}以降オンライン', offline: '{date}以降オフライン' },
    statusNames: {
      online: 'デバイスオンライン',
      offline: 'デバイスオフライン',
      unassigned: '割り当てられていないデバイス',
    },
    copyLongIdNotification: 'ロングデバイスID {shortId} がクリップボードにコピーされました。',
    goToDetailsPage: 'デバイス詳細ページに移動',
    copyLongId: 'デバイスIDをコピー',
    executing: 'デバイスでコンテンツを準備しています...',
  },
  gondolaTemplate: {
    deleteDeviceModal: {
      title: 'テンプレートからデバイスを削除',
      text: 'デバイスには既にコンテンツが設定されています！ このアクションは取り消しできません！',
    },
    label: { visible: 'ラベルを表示', hidden: 'ラベルを隠す' },
    notFound: 'テンプレートが見つかりません。',
    templateNotFoundMessage: 'テンプレートが見つかりませんでした。削除されたか、存在しない可能性があります。',
    returnToPage: 'テンプレートリストに戻る',
    createTemplate: 'テンプレート作成',
  },
  preview: { notAvailable: 'プレビューはまだ利用できません。' },
  aisle: '通路 | 通路',
  gondola: 'セクション | セクション',
  store: 'ストア | ストア',
  template: 'テンプレート | テンプレート',
  action: {
    new: '新規',
    actions: 'アクション',
    publish: '発行',
    clone: 'クローン',
    edit: '編集',
    delete: '削除',
    upload: 'アップロード',
    reload: 'ページをリロード',
    ignore: '無視',
    close: '閉じる',
    deleteEntity: '{entity} を削除',
    createEntity: '新しい {entity} を作成',
    editEntity: '編集 {entity}',
    remove: '削除',
    confirm: '確認',
    addNewItem: '新しいアイテムを追加',
    move: '移動',
    download: 'ダウンロード',
    copy: 'コピー',
    quickActions: 'クイックアクション',
    save: '保存',
    rename: '名前を変更',
    cancel: 'キャンセル',
    back: '戻る',
    next: '次へ',
    skip: 'スキップ',
    return: '戻る',
    exportAsJSON: '.jsonとしてエクスポート',
    refresh: '更新',
    bulkReplace: '一括交換',
  },
  confirm: {
    deleteEntity: {
      title: '{entity} を削除',
      text: 'システムから {entity} を削除しますか? このアクションは元に戻せません。',
      delete: '削除',
      cancel: '変更を無視',
    },
  },
  search: '検索',
  logout: 'ログアウト',
  login: 'ログイン中。。。',
  editProfile: 'プロフィール',
  error: {
    headline: 'エラー',
    validationHeadline: '検証エラー',
    ok: 'OK',
    searchFailed:
      '検索中にエラーが発生しました。結果が見つかりませんでした。 | 検索中にエラーが発生しました。1 件の結果が見つかりました。 | 検索中にエラーが発生し、{n} 件の結果が見つかりました。',
    duplicateAzureId: 'このストア ID はすでに別のストアに割り当てられています: {0}。 入力したIDは保存できません。',
    upload:
      'アップロード中にエラーが発生しました。おそらくサポートされていないコーデックです。 x264 mp4 でエンコードされたビデオ ファイルで再試行してください。',
    maxLabels: 'これ以上ラベルを追加することはできません! 現在のラベルの最大数は {max} です。',
    concurrentModification:
      'あなたが今取り組んでいるデータ構造を他の誰かが変更したようです。 書き込み操作を行うには、このページをリロードしてください。 ページをリロードするまで保存できない場合があります。',
    StoreCRUDError: {
      AZURE_ID_EXISTING_DEVICES:
        'デバイスがある店舗のazure idを再割り当てすることはできません。すべてのデバイスIDを先に削除する必要があります。',
      WEBHOOK_NOT_CREATED: 'このストアのWebhookを作成/更新できませんでした。しばらくしてからもう一度お試しください。',
      UPDATED_STORE_DID_NOT_EXIST: 'ストアを更新できませんでした:このIDの既存のストアが見つかりませんでした。',
      INPUT_ERROR:
        'サーバーに送信された値を検証できなかったか、エラーが発生しました。 ページをリロードして入力を確認してください。',
    },
    APIM: {
      MISSING_VRAIL_PERMISSIONS:
        '現在の azure ID でストアを作成できませんでした。ストアは見つかりましたが、アクセスが拒否されました。 管理者に連絡してください。',
      MISSING_VCLOUD_SUBSCRIPTION:
        'サブスクリプション キーが無効なため、アクセスが拒否されました。 管理者に連絡してください。',
      MISSING_VRAIL_KEY:
        '現在の azure ID でストアを作成できませんでした。アカウントに videorail API サブスクリプション構成がありません。 管理者に連絡してください。',
      INVALID_STORE:
        '現在のazure IDで有効なストアが見つかりませんでした、入力を確認して再試行してください。このエラーが続く場合は、管理者に連絡してください。',
    },
    maxLength: '入力が無効です。許可される最大文字数は{maxLength}です。',
    requiredField: 'この項目は必須です。',
    backendAuthentication:
      'バックエンドの認証ができません。サーバーが更新されている可能性がありますので、後でもう一度お試しください。',
    genericNotFound:
      '更新しようとしたリソースが見つかりませんでした。 ページを再読み込みして、もう一度お試しください。',
    genericDuplicate: '同じ名前のリソースがすでに存在します。',
    genericUnexpected: '不明なエラーです。再度トライしてください。',
    pageNotFoundTitle: 'ページが見つかりません。',
    pageNotFoundDescription: 'お探しのページは存在しません。',
    pageNotFoundFallback: 'ホームページへ戻る',
    authenticationErrorPageTitle: 'Oops！エラーです。',
    authenticationErrorPageDescription:
      '認証時に何か問題が発生したようです。もう一度試してみて、問題が解決しない場合は、サポートに連絡してください。',
    nonASCIIInput:
      '無効な入力です。ストアの Azure ID には、A から Z、0 から 9 の文字、および単純な句読点のみを使用できます。',
    noSymbolsAllowed: '無効な入力、特殊記号は許可されていません。',
    invalidEmail: '無効なe-mail',
    numberOutOfRange: '値は {min} と {max} の間である必要があります',
    mustBeGreater: '値は QA より大きくなければなりません。',
  },
  publishState: {
    failed: '公開失敗',
    published: '公開済み',
    pending: '公開保留',
    accepted: '受け入れ済み',
  },
  deviceState: {
    failed: '設定失敗',
    pending: '設定中',
    accepted: '設定を受け入れました',
    noContent: '未設定',
    missingDeviceId: 'デバイスIDが設定されていません。',
    unknown: '不明な発行',
  },
  deviceStateInfo: { unknown: 'デバイスIDが変更された可能性があります。' },
  copyOf: 'コピー',
  webSocketMessages: {
    connectionError:
      '接続に問題があるようです。 10 秒後に再接続を試みます。 この問題が解決しない場合は、ページをリロードしてください。',
    connectionClose:
      'サーバーによって接続が閉じられたようです。 10 秒後に再接続を試みます。 この問題が解決しない場合は、ページをリロードしてください。',
    connectionFail: 'サーバーへの接続を作成できませんでした。 10 秒後に再接続を試みます。',
  },
  schedules: {
    schedule: 'スケジュール',
    color: '色',
    dateRange: '日付範囲',
    timeRange: '時間範囲',
    name: '名前',
    recurrenceDays: '再発日',
    from: '設定',
    to: 'から',
    startTime: '開始時間',
    endTime: '終了時間',
    requiredField: '必須フィールドを示す',
    errors: {
      dateRange: '有効な日付範囲を選択してください。',
      name: '名前は空欄にできません。',
      recurrenceDays: '少なくとも1日は選択してください。',
      startTime: '開始時刻は有効である必要があります(HH:mm)',
      endTime: '終了時刻は有効(HH:mm)で、開始時刻よりも大きい必要があります。',
      color: '色をお選びください',
    },
    confirm: '有効なステータスを確認',
    confirmButton: '有効なステータスを確認して保存',
    conflictWarning: '次の重複するスケジュールを確認すると、無効として設定されます: \n',
    active: 'スケジュールが有効',
    inactive: 'スケジュールは無効',
    scheduleActive: 'スケジュールはアクティブ',
    scheduleInactive: 'スケジュールは非アクティブ',
    activeStatus: 'アクティブステータス',
    deleteOverride: 'スケジュールされたコンテンツを削除',
    labelOptions: '{index}アクションにラベルを付けます:',
    label: 'ラベル: ',
    manageSchedules: 'スケジュールを管理',
    publishedAlert:
      'このスケジュールはすでに展開されていますが、本当に削除しますか? 削除を有効にするには、テンプレートを再公開する必要があることに注意してください。',
    deleteTitle: 'スケジュール{スケジュール}を削除しますか?',
    editTitle: 'スケジュールの編集',
    deleteAlert: 'このスケジュールを削除してもよろしいですか?',
    scheduleOptions: 'アクションのスケジュール:',
    base: '基本',
    currentWeekFilter: '今週のみ表示',
    activeOnlyFilter: '有効のみを表示',
    filter: 'フィルター',
    selectedWeek: '選択した週:',
    currentWeek: '今週',
    editScheduleNote: '編集を有効にするには、テンプレートを再発行する必要があることに注意してください。',
    schedulesOverview: 'スケジュール概要\n',
    selectedSchedule: '選択したスケジュール',
  },
  notifications: {
    title: 'お知らせ',
    groupByType: '種類別にグループ化\n',
    types: {
      publishing: '更新中',
      online: 'オンライン状況',
      registration: '登録状況',
      activeHours: 'アクティブ時間',
      label: 'イベントタイプ',
      firmware: 'ファームウェア更新',
      storeSync: 'ストア同期',
    },
    publishingRetry: {
      startingIn: '{minutes}分と{seconds}秒後に再試行します',
      startingSoon: '間もなく開始...',
    },
    publishingAttempt: '再試行{attempt}',
    aisle: '通路',
    section: 'セクション',
    eventState: {
      inProgress: '更新中',
      error: 'エラー',
      accepted: '受け入れ済み',
      completed: '完了',
      cancelled: 'キャンセル',
      sendingToAPIM: '送信',
      sentToAPIM: '実行',
    },
    eventType: {
      updatingBackground: '背景の更新',
      deletingLabel: 'ラベルの削除',
      syncingLayer: 'コンテンツのダウンロード',
      initializingDevice: 'デバイスの初期化',
      deviceSkipped: 'デバイスは以前の試行ですでに公開されています',
      downloadingBackgroundContent: '背景コンテンツのダウンロード',
      downloadingLabelContent: 'ラベル {index} のコンテンツをダウンロードしています。',
      downloadingScheduledContentForLabel: 'ラベル {index} の予定されたコンテンツをダウンロードしています。',
      downloadingScheduledContentForBackground: '背景用の予定されたコンテンツのダウンロード',
      unableToPreviewDownloadedContent: 'ダウンロードしたコンテンツをプレビューできません。',
      updatingLabel: 'ラベルを更新しています。',
      online: 'オンライン',
      offline: 'オフライン',
      registered: 'ストア登録済',
      unregistered: 'ストアからの登録解除',
      templatePublished: 'テンプレートが公開されました。',
      firmwareReleased: 'ファームウェアがリリースされました',
      firmwareChanged: 'ファームウェアが変更されました',
    },
    brightnessLevelMessage: '輝度レベルを {brightnessLevel}% に設定しました。',
    screenStatusMessage: 'デバイスの画面をOFFに設定 | デバイスの画面をONに設定 | デバイスの画面をONに設定',
    screenStatusMessageShort: 'OFF | ON | ON',
    noNotificationsAvailable: '通知はありません。',
    resetFilters: 'フィルタをリセット',
    relativeTime: {
      secondsAgo: '{time} 秒前',
      minutesAgo: '{time} 分前',
      hoursAgo: '{time} 時間前',
      daysAgo: '約 {time} 日前',
      monthsAgo: '約 {time} か月前',
      yearsAgo: '約 {time} 年前',
    },
    publishedWithDeletedTemplate: 'これは公開されたため、テンプレートは削除されました。',
    publishedWithTemplate: '公開されたテンプレート',
    searchTerm: 'セクション、通路、デバイスIDによる検索',
    advancedSearch: '詳細検索',
    showMoreDevices: 'もっと見る（あと1つのデバイス） | もっと見る（あと {n} 個のデバイス）',
    showAllDevices: 'すべて表示（残り{n}台のデバイス）',
    activeHoursAccepted: 'デバイスの応答を待っています',
    activeHoursConfig: {
      activeHoursConfigSet: '{target} のアクティブ時間設定を変更しました',
      activeHoursConfigRemoved: '{target} のアクティブ時間設定を削除しました',
      off: '停止',
    },
    storeSync: {
      title: 'ストア同期済み',
      manual: '同期が手動で開始されました',
      automatic: '同期が自動で開始されました',
      webhookRecreated: 'Webhook が再作成されました',
      webhookUpToDate: 'Webhook は最新の状態です',
      devicesAdded: 'デバイスを1つ追加 | デバイスを{n}個追加',
      devicesRemoved: 'デバイスを1つ削除 | デバイスを{n}個削除',
      devicesUpdated: 'デバイスを1つ更新 | デバイスを{n}個更新',
      deviceSynced: 'デバイスが更新されました',
    },
    firmwareReleased: {
      title: '{store} 用の新しいファームウェアがリリースされました',
      version: 'バージョン：',
      build: 'ビルド：',
      branch: 'ブランチ：',
      date: '日付：',
      time: '時間：',
      releaseNotes: 'リリースノート：',
      affectedDevicesModal: {
        title: '対象デバイス',
        message: '次のデバイスがこのアップデートの対象：',
      },
      releaseNotesModal: {
        title: 'リリースノート',
        open: '開く',
      },
    },
    firmwareChanged: {
      title: '{device} 用の新しいファームウェアがリリースされました',
      version: 'バージョン：',
      systemVersion: 'システムバージョン：',
    },
  },
  activeHours: {
    activeHours: 'アクティブ時間',
    editDeviceMessage: 'セクションのアクティブ時間を編集',
    title: 'ストアの有効時間',
    status: '状態',
    day: '日',
    from: 'から',
    to: 'まで',
    activeAllDay: 'デバイスは終日アクティブです。',
    turnedOffAllDay: 'デバイスは終日オフです。',
    specificTimeActive: 'デバイスはこの日の指定された時間帯だけアクティブです。',
    revert: 'デバイスを終日アクティブに戻す',
    turnOff: 'この日のデバイスをオフにする',
    save: 'アクティブな時間を節約',
    on: 'オン',
    off: 'オフ',
    section: {
      revert: 'リセット',
      revertTooltip: '本来のアクティブ時間は削除され、ストアのアクティブ時間は有効になります。',
      filterMessage: 'アクティブ時間を無効にしました。',
      hideEmptyAisles: '空の通路を隠す',
      title: 'セクションの有効時間',
      noCompatibleDevicesTitle: '互換性のあるデバイスはありません。',
      noCompatibleDevicesError: 'セクションにはアクティブ時間を扱うデバイスが含まれていません。',
    },
    weekdays: {
      monday: '月',
      tuesday: '火',
      wednesday: '水',
      thursday: '木',
      friday: '金',
      saturday: '土',
      sunday: '日',
    },
  },
  qrCode: {
    copiedQRCodeToClipboard: 'QRコードをクリップボードにコピー済',
    copyQRCodeToClipboard: 'QRコードをクリップボードにコピー',
    downloadQRCodeAs: 'QR コードを {format} としてダウンロード',
    missingAzureId:
      'QRコードはまだ利用できません。QRコードを取得するには、ストア名とストアのAzure IDを設定してください。',
    title: 'QRコードをスキャン',
    description: 'デバイスをストアに接続するには、QRコードをスキャンしてください。',
  },
  deviceDetail: {
    title: 'デバイス詳細',
    breadcrumb: 'デバイス 詳細',
    notFound: 'デバイスが見つかりません！',
    loadMore: 'もっと読み込む',
    events: 'イベント',
    publishedSchedules: '公開されたスケジュール',
    notAssigned: '割り当てられていません。',
    noEvents: 'このデバイスには利用可能なイベントがありません。',
    noPublishing: 'まだテンプレートは公開されていません。',
    deviceNotFoundMessage: 'お探しのデバイスは見つかりませんでした。',
    returnToPage: 'デバイスのページに戻る',
    synchronize: '同期',
    eventsCount: '中の',
  },
  settings: {
    title: '設定',
    breadcrumb: '設定',
    about: {
      title: '概要',
      build: 'ビルド番号',
      version: 'バージョン',
      description: 'アプリ概要',
    },
    releaseManagement: {
      title: 'リリース管理',
      addButtonTitle: '設定追加',
      configDialog: {
        title: '新規設定',
        labels: {
          name: '名前',
          email: '購読メールアドレス',
          qaDelay: 'QA遅延',
          releaseDelay: 'リリース遅延',
          description: '説明',
          isDefault: 'デフォルト設定に選択',
        },
        infobox: {
          title: '情報',
          infoMessage:
            'これは新しいデフォルト設定であり、すべての店舗に適用されます。 | これは新しいデフォルト設定であり、{count} 店舗に適用されます。',
        },
      },
      deleteConfigDialog: {
        warningText: 'このアイテムを削除してもよろしいですか？この操作は元に戻せません！',
        warningTextStoreReference: 'この設定を削除すると、次のストアがデフォルト設定に割り当てられます：',
      },
      affectedStoreDialog: {
        title: '対象ストア',
        warningTitle: '注意！',
        warningText: 'これらの変更を保存してもよろしいですか？',
        warningTextStoreReference: 'この変更は次のストアに影響します： | この設定は次のストアに割り当てられています：',
        okButton: 'OK',
        useEmailSubscription: 'メール購読の使用',
      },
      tableHeaders: {
        name: '名前',
        description: '説明',
        email: 'メールアドレス',
        qaDelay: 'QA遅延',
        releaseDelay: 'リリース遅延',
        affectedStores: '対象ストア',
        actions: 'アクション',
      },
      tooltips: {
        defaultConfig: 'これがデフォルト設定です',
      },
      store: {
        missingAzureId:
          'リリース管理はまだ利用できません。リリース管理の設定を行うには、ストア名とストアのAzure IDを設定してください。',
        currentReleaseManagementModal: {
          title: '適用されたリリース管理設定',
          message: 'リリース管理の設定がまだありません。<br/>ストアに割り当てる前に作成してください。',
          createLink: 'リリース設定を作成',
          subscribeButton: {
            subscribe: {
              title: '購読',
              tooltip: 'メールで今後のリリースについて通知を受け取るには購読してください',
            },
            unsubscribe: {
              title: '購読を解除',
              tooltip: 'リリースの更新をメールで受け取りたくない場合は、購読を解除してください',
            },
          },
        },
        changeReleaseManagementModal: {
          changeButton: '設定変更',
          title: 'リリース管理を適用',
          assignButton: '割り当て',
          assigned: '割り当て済み',
          continueUsingEmailTitle: 'メール購読を続ける',
          continueUsingEmailMessage:
            'あなたはすでに新しいファームウェアに関するメール更新に登録されています。引き続き登録を希望しますか？',
        },
      },
    },
    tags: {
      title: 'タグ',
      tableHeaders: {
        name: '名前',
        description: '説明',
        color: '色',
        stores: 'ストア',
        templates: 'テンプレート',
        sections: 'セクション',
        contentItems: 'コンテンツ',
        actions: 'アクション',
      },
      addTagButton: 'タグを追加',
      tagsDialog: {
        createTitle: '新規タグを作成',
        name: '名前',
        color: '色',
        description: '説明',
        colorError: 'この色のタグはすでに存在します！',
      },
      referenceModal: {
        title: 'このタグが付いた：{item}',
        warningText: 'この変更は次のエンティティに影響しますので、ご注意ください：',
        assignedEntitiesText: 'このタグが割り当てられているすべてのエンティティは次のとおりです：',
        assignedEntitiesEmptyText: 'このタグはまだどのエンティティにも割り当てられていません。',
        items: {
          store: 'ストア',
          section: 'セクション',
          template: 'テンプレート',
          contentItem: 'コンテンツ',
          entities: 'エンティティ',
        },
      },
      deleteModal: {
        warningText: 'このアイテムを削除してもよろしいですか？この操作は元に戻せません！',
        affectedEntitiesWarning: 'このタグは現在、次のエンティティに割り当てられています：',
      },
      assignTag: {
        tagCountWarning: '選択できるタグは最大5つまでです',
        andOthers: '(+ {count} 他) | (+ {count} 他)',
      },
      editTag: 'タグ編集',
    },
    tooltips: {
      fillInAllRequiredFieldsCorrectly: 'すべての必須項目を正しく入力してください。',
      noChangesThatNeedToBeSaved: '現在、保存する必要のある変更はありません。',
    },
  },
  sensors: {
    navigationTitle: 'センサー分析',
    publishingStatus: {
      completed: '完了',
      running: '実行中',
    },
    overallEngagedPersons: '全体のエンゲージした顧客',
    totalEngagementTime: '総エンゲージメント時間',
    averageEngagementTime: '平均エンゲージメント時間',
    averagePersonsDetected: '平均エンゲージした顧客',
    areas: {
      awareness: '認識',
      dwell: '滞留',
      decision: '決定',
      awarenessThreshold: '認識閾値',
      dwellThreshold: '滞留閾値',
      decisionThreshold: '決定閾値',
      thresholdsInformation:
        '閾値は<strong>秒</strong>で表されます。<br />閾値を設定すると、そのエリアで指定された時間より短く滞在したすべての検出対象がフィルタリングされます。<br /><i>例：決定エリアの閾値を3秒に設定すると、決定エリアに3秒未満しか滞在しなかった検出対象がすべてフィルタリングされます。</i>',
    },
    timeSpentPerArea: 'エリアごとの滞在時間',
    average: '平均',
    absolute: '絶対値',
    total: '合計',
    peoplePerArea: 'エリアごとの人数',
    hourly: '毎時',
    daily: '毎日',
    overall: '全体',
    totalTime: '総時間',
    playedTimes: '再生回数',
    awarenessTime: '認識時間',
    decisionTime: '決定時間',
    dwellTime: '滞留時間',
    noDataAvailable: 'データがありません',
    hideEmptyDays: 'データのない日を非表示にする',
    lastDetectedObject: '最後に検出されたオブジェクト',
    lastDetectedObjectMessage:
      'これはセンサーが最後に検出したオブジェクトです。データは毎日同期されるため、最新のデータは昨日のものになります。',
  },
};

export default japaneseTranslationObject as unknown as LocaleMessageObject;
