
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { HardwareModelIdentifier } from '@common/device/types';

@Component({})
export default class HeaderHardwareModelFilter extends Vue {
  @Prop()
  private headerText!: string;
  @Prop()
  private defaultValue!: HardwareModelIdentifier[];

  private filterValues: HardwareModelIdentifier[] = this.defaultValue;

  private filterTypesOptions = (): Array<{ text: TranslateResult; value: HardwareModelIdentifier }> => [
    {
      text: HardwareModelIdentifier.VR06001,
      value: HardwareModelIdentifier.VR06001,
    },
    {
      text: HardwareModelIdentifier.VR09001,
      value: HardwareModelIdentifier.VR09001,
    },
    {
      text: HardwareModelIdentifier.VR12001,
      value: HardwareModelIdentifier.VR12001,
    },
    {
      text: HardwareModelIdentifier.VP1080P,
      value: HardwareModelIdentifier.VP1080P,
    },
  ];

  @Watch('filterValues')
  emitValueChanged(): void {
    this.$emit('change', this.filterValues);
  }

  @Watch('defaultValue')
  defaultValueChanged(): void {
    this.filterValues = this.defaultValue;
  }

  clearFilter(): void {
    this.filterValues = [];
  }

  get filterButtonColor(): string {
    return this.filterValues?.length ? 'primary' : '';
  }

  get filterTypes(): Array<{ text: TranslateResult; value: HardwareModelIdentifier }> {
    return this.filterTypesOptions();
  }
}
