
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DeviceTemplate, GondolaTemplate } from '@client/models';
import ModalDialog from '../ModalDialog/ModalDialog.vue';
import PlaylistDialog from '../PlaylistDialog/PlaylistDialog.vue';
import videoDurationParser from '@client/utils/videoDurationParser';
import { ContentItemSelectionTarget } from '@client/enums';
import { ContentType } from '@common/enums';
import { ErrorType } from '@common/error/types';
import { AppGlobalStore, useAppGlobalStore } from '@client/stores/app-global';
import { ErrorObserver, ErrorStore, useErrorStore } from '@client/stores/error';
import { ContentItemsStore, useContentItemsStore } from '@client/stores/contentItems';
import { Optional } from '@common/types';
import {
  DeviceContentSelection,
  GondolaTemplatesStore,
  useGondolaTemplatesStore,
} from '@client/stores/gondolaTemplates';
import MediaItemExpanded from '@client/components/ContentItems/ListItemExpanded/MediaItemExpanded.vue';
import ContentItemsListItem from '@client/components/ContentItems/ListItems/ContentItemsListItem.vue';
import {
  ContentItem,
  ContentItemFilters,
  ContentItemFiltersClearOptions,
  ContentReferences,
} from '@client/models/ContentModels/types';
import VideoModel from '@client/models/ContentModels/Video.model';
import PlaylistModel from '@client/models/ContentModels/Playlist.model';
import { CustomDataTableHeader } from '@client/definitions/CustomDataTableHeader';
import ContentItemsActionButtons from '@client/components/ContentItems/ContentItemsActionButtons.vue';
import PlaylistItemExpanded from '@client/components/ContentItems/ListItemExpanded/PlaylistItemExpanded.vue';
import PlaylistItemPreviewDialog from '@client/components/ContentItems/ContentItemPreviewDialog.vue';
import {
  findLastIndexOfFolder,
  handleBackgroundContentUpdate,
  handleForegroundContentUpdate,
} from '@client/components/ContentItems/utils';
import InfiniteScroll from '@client/components/InfiniteScroll/InfiniteScroll.vue';
import ContentItemNavigation from '@client/components/ContentItems/ContentItemNavigation.vue';
import MoveDialog from '@client/components/ContentItems/MoveDialog.vue';
import Search, { SearchParams } from '@client/components/ContentItems/Search/Search.vue';
import { ROOT_NODE } from '@common/content-item/types';
import HeaderTextFilter from '@client/components/Filters/HeaderFilters/HeaderTextFilter.vue';
import HeaderFileTypeFilter from '@client/components/Filters/HeaderFilters/HeaderFileTypeFilter.vue';
import HeaderDateFilter from '@client/components/Filters/HeaderFilters/HeaderDateFilter.vue';
import ContentItemFiltersChips from '@client/components/ContentItems/ContentItemFiltersChips.vue';
import HeaderNumberFilter from '@client/components/Filters/HeaderFilters/HeaderNumberFilter.vue';
import HeaderNumberRangeFilter from '@client/components/Filters/HeaderFilters/HeaderNumberRangeFilter.vue';
import { isContentItemFiltered } from '@client/models/ContentModels/utils';
import Wrapper from '@client/components/Layouts/Wrapper.vue';
import SelectTag from '@client/components/Settings/Tags/Actions/SelectTag.vue';
import TagReference from '@client/models/SettingsModels/TagReference';
import EditTagsModal from '@client/components/Settings/Tags/EditTagsModal.vue';
import HeaderTagsFilter from '@client/components/Filters/HeaderFilters/HeaderTagsFilter.vue';

/**
 * This component renders the list of content items available.
 * Contains the config of the data table, as well as the modals.
 */
@Component({
  components: {
    HeaderTagsFilter,
    EditTagsModal,
    SelectTag,
    Wrapper,
    HeaderNumberRangeFilter,
    HeaderNumberFilter,
    ContentItemFiltersChips,
    HeaderDateFilter,
    HeaderFileTypeFilter,
    HeaderTextFilter,
    MoveDialog,
    ContentItemNavigation,
    InfiniteScroll,
    PlaylistItemPreviewDialog,
    PlaylistItemExpanded,
    ContentItemsActionButtons,
    ContentItemsListItem,
    MediaItemExpanded,
    PlaylistDialog,
    ModalDialog,
    Search,
  },
  methods: {
    videoDurationParser,
  },
})
export default class ContentItemsList extends Vue {
  /* DECLARATIONS */
  private static readonly ERROR_OBSERVER_KEY: string = 'ContentItemsList';

  @Prop()
  private selectedSchedule!: string;
  @Prop()
  private currentlyActiveContentItemName?: string;
  @Prop({ default: ContentItemSelectionTarget.DEVICE_LABEL_OR_BACKGROUND })
  private contentItemSelectionTarget!: ContentItemSelectionTarget;
  @Prop({ default: () => Object.values(ContentType) })
  private selectableContentType?: Array<ContentType>;
  @Prop({
    default: () => {
      // Will be overridden later
    },
  })
  private selectVideo!: (item: VideoModel) => void;
  @Prop({ default: false })
  private isModalEmbedded!: boolean;
  @Prop({ default: () => {} })
  private closeContentItemsDialog!: () => void;
  @Prop({ default: ROOT_NODE })
  private defaultSelectedFolder!: string;
  @Prop({
    default: () => {
      // Will be overridden later
    },
  })
  private selectItem!: (item: ContentItem) => void;
  @Prop()
  private deviceTemplate!: DeviceTemplate;

  private showDeleteContentItemDialog: boolean = false;
  private showPlaylistDialog: boolean = false;
  private deleteWarningText: string = '';
  private playlistToEdit: PlaylistModel = PlaylistModel.getDefaultEmptyPlaylist();
  private readonly PAGE_SIZE_DEFAULT: number = 10;
  private readonly PAGE_SIZE_ALL: number = -1;
  private contentItemsStore: ContentItemsStore = useContentItemsStore();
  private isLoading: boolean = false;
  private selectedFolder: string = '';
  private contentItems: Array<ContentItem> = [];
  private isFetching: boolean = false;
  private isSearchMode: boolean = false;
  private searchTerm: string = '';
  private typeFilters: Array<ContentType> = [];
  private searchRerenderKey: number = 0;
  private searchTags: Array<TagReference> = [];

  private playlistItemToPreview?: VideoModel = VideoModel.getDefaultEmptyVideo();
  private playlistItemPreviewUrl: string = '';
  private showPlaylistItemPreview: boolean = false;

  private showMoveDialog: boolean = false;
  private contentItemsToMove: Array<ContentItem> = [];

  private showTagsDialog: boolean = false;
  private contentItemToEdit: ContentItem | null = null;

  private maximumLoadedItems: number = this.PAGE_SIZE_DEFAULT;
  private selectedContentItems: Array<ContentItem> = [];
  /**
   * Contains the content items as shown in the datatable, after sorting and filtering
   * This is used to compute the bulk action menu position
   */
  private currentlyDisplayedContentItems: Array<ContentItem> = [];
  private contentItemFilters: ContentItemFilters = this.initContentItemFilters();

  private cancelHandler = () => {
    // will be overridden later
  };
  private confirmHandler = () => {
    // will be overridden later
  };

  private search: string = '';
  private selectedContentItemId: string = '';

  private appGlobalStore: AppGlobalStore = useAppGlobalStore();
  private errorStore: ErrorStore = useErrorStore();
  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  /* LIFECYCLE EVENTS */
  async created(): Promise<void> {
    const folderId: Optional<string> = this.$route.params.folderId;
    if (folderId && !this.isModalEmbedded) {
      this.setSelectedFolder(folderId, false);
    }
    if (this.defaultSelectedFolder !== ROOT_NODE) {
      this.setSelectedFolder(this.defaultSelectedFolder, false);
    }
    ErrorObserver.create(ContentItemsList.ERROR_OBSERVER_KEY)
      .attachHandler(ErrorType.NOT_FOUND, (): void => {
        this.selectedFolder = '';
        this.$router.push({ path: '/assets' });
        this.getContentItems(false);
        this.appGlobalStore.updateGenericErrorModal({
          showGenericErrorModal: true,
          showGenericErrorModalReloadButton: false,
          genericErrorModalText: this.$t(this.$i18nTranslationKeys.contentManagement.folderNotFound.$path),
        });
      })
      .attachHandler(ErrorType.DUPLICATE, (): void => {
        this.appGlobalStore.updateGenericErrorModal({
          showGenericErrorModal: true,
          showGenericErrorModalReloadButton: false,
          genericErrorModalText: this.$t(this.$i18nTranslationKeys.error.genericDuplicate.$path),
        });
      })
      .attachHandler(ErrorType.UNEXPECTED_ERROR, (): void => {
        if (this.$route.path !== '/assets') {
          this.selectedFolder = '';
          this.$router.push({ path: '/assets' });
          this.getContentItems(false);
        }
        this.appGlobalStore.updateGenericErrorModal({
          showGenericErrorModal: true,
          showGenericErrorModalReloadButton: false,
          genericErrorModalText: this.$t(this.$i18nTranslationKeys.error.genericUnexpected.$path),
        });
      })
      .register();
    await this.getContentItems(false);
    this.$root.$on('onContentItemAdded', this.onContentItemAdded);
    this.$root.$on('onContentItemUpdated', this.onContentItemUpdated);
    this.$root.$on('onContentItemDeleted', this.onContentItemDeleted);
  }

  beforeDestroy(): void {
    this.errorStore.deregister(ContentItemsList.ERROR_OBSERVER_KEY);
    if (!this.isModalEmbedded) {
      this.selectedFolder = '';
      this.$root.$emit('selectedFolderChanged', this.selectedFolder);
    }
  }

  /* METHODS */
  /* METHODS */
  /**
   * Handles adding or removing an item from selection
   */
  onToggleSelectContentItem(contentItem: ContentItem): void {
    const indexOfContent: number = this.selectedContentItems.findIndex(
      (item: ContentItem) => item._id === contentItem._id
    );
    if (indexOfContent >= 0) {
      this.selectedContentItems.splice(indexOfContent, 1);
    } else {
      this.selectedContentItems.push(contentItem);
    }
  }

  /**
   * When any event happens on the data table, and changes the positions/filters the current items,
   * We need to update the position of bulk action popup
   * @param items Current items with the order shown in the datatable
   */
  onSortByChanged(items: Array<ContentItem>): void {
    this.currentlyDisplayedContentItems = items;
    this.updateBulkActionMenuPosition();
  }

  /**
   * Same event we need to handle as in onSortByChanged when the selected content items length changes
   */
  @Watch('selectedContentItems.length')
  onSelectedContentItems(): void {
    this.updateBulkActionMenuPosition();
  }

  triggerUpdateBulkActionMenuPosition(): void {
    setTimeout(() => {
      this.updateBulkActionMenuPosition();
    }, 100);
  }

  /**
   * Updates the position of the bulk actions menu to the last selected item
   */
  updateBulkActionMenuPosition(): void {
    if (!this.selectedContentItems.length || !this.currentlyDisplayedContentItems) {
      return;
    }
    const indexOfLastElement: number = this.selectedContentItems.length - 1;
    const idOfLastElement: string = this.selectedContentItems[indexOfLastElement]._id;
    const bulkActionElement: Optional<HTMLElement> = document.getElementById('bulk-actions');
    const targetRow: Optional<HTMLElement> = document.getElementById(idOfLastElement);
    if (!bulkActionElement) {
      return;
    }
    if (!targetRow && this.selectedContentItems.length !== this.contentItems.length) {
      // Only triggered when not all items are selected
      // If the target row couldn't be found, then it has not been loaded yet because of lazy loading.
      // Set the position to the end of the datatable until it is loaded
      const anchor: Optional<HTMLElement> = document.getElementById('actions-anchor');
      bulkActionElement.style.top = `${(anchor?.offsetTop || 0) + 63}px`;
      return;
    }
    if (this.selectedContentItems.length === this.contentItems.length) {
      bulkActionElement.style.top = '63px';
      return;
    }
    bulkActionElement.style.top = `${(targetRow?.offsetTop || 0) + 63}px`;
  }

  /**
   * Check if any items are selected, then select all
   * otherwise unselect all items
   */
  onCheckAllClicked(): void {
    if (this.allItemsChecked) {
      this.clearSelectedItems();
    } else {
      this.selectedContentItems = [...this.contentItems];
      this.updateBulkActionMenuPosition();
    }
  }

  /**
   * Checks if the item is selected
   */
  isItemChecked(item: ContentItem): boolean {
    return this.selectedContentItems.findIndex((selectedItem: ContentItem) => selectedItem._id === item._id) >= 0;
  }

  /**
   * Event triggered when we receive the add content item webhook event
   */
  onContentItemAdded(contentItem: ContentItem): void {
    if (this.isSearchMode) {
      return;
    }
    if (contentItem.parentFolder === this.selectedFolder || (!contentItem.parentFolder && !this.selectedFolder)) {
      if (contentItem.isFolder()) {
        this.contentItems.unshift(contentItem);
      } else {
        const lastIndexOfFolder: number = findLastIndexOfFolder(this.contentItems);
        // Add this item just after the last folder
        this.contentItems.splice(lastIndexOfFolder + 1, 0, contentItem);
      }
    }
  }
  @Watch('$route.fullPath')
  onRouteChanged(): void {
    const folderId: Optional<string> = this.$route.params.folderId;
    if (!this.isModalEmbedded) {
      this.setSelectedFolder(folderId || '', false);
    }
  }

  /**
   * Event triggered when we receive the update content item webhook event
   */
  onContentItemUpdated(contentItem: ContentItem): void {
    const indexOfContentItemInStore: number = this.contentItems.findIndex(
      (item: ContentItem) => item._id === contentItem._id
    );
    if (
      contentItem.parentFolder === this.selectedFolder ||
      (!contentItem.parentFolder && !this.selectedFolder) ||
      this.isSearchMode
    ) {
      if (indexOfContentItemInStore >= 0) {
        Vue.set(this.contentItems, indexOfContentItemInStore, contentItem);
        this.removeItemFromSelectedItems(contentItem._id);
      } else {
        console.warn(`Could not find content item with id ${contentItem._id} in store`);
      }
    } else {
      if (indexOfContentItemInStore >= 0) {
        this.contentItems.splice(indexOfContentItemInStore, 1);
        this.removeItemFromSelectedItems(contentItem._id);
      } else {
        console.warn(`Could not find content item with id ${contentItem._id} in store`);
      }
    }
  }

  /**
   * Event triggered when we receive the delete content item webhook event
   */
  onContentItemDeleted(contentItemId: string): void {
    if (contentItemId === this.selectedFolder) {
      this.setSelectedFolder('');
      this.appGlobalStore.updateGenericErrorModal({
        showGenericErrorModal: true,
        showGenericErrorModalReloadButton: false,
        genericErrorModalText: this.$t(
          this.$i18nTranslationKeys.contentManagement.folderDeletedFromAnotherInstance.$path
        ),
      });
    }
    const index: number = this.contentItems.findIndex(
      (contentItemIterator: ContentItem) => contentItemIterator._id === contentItemId
    );
    if (index < 0) {
      return;
    }
    this.contentItems.splice(index, 1);
    this.removeItemFromSelectedItems(contentItemId);
  }

  /**
   * Removes the content item from the selected content items array if it's selected and adjusts the bulk actions menu position
   */
  removeItemFromSelectedItems(contentItemId: string) {
    const indexInSelected: number = this.selectedContentItems.findIndex(
      (selectedItem: ContentItem) => selectedItem._id === contentItemId
    );
    if (indexInSelected < 0) {
      return;
    }
    this.selectedContentItems.splice(indexInSelected, 1);
    // Wait for the item to be removed with animations and update the position
    setTimeout(() => {
      this.updateBulkActionMenuPosition();
    }, 100);
  }

  clearFilter(key: ContentItemFiltersClearOptions) {
    this.clearSelectedItems();
    switch (key) {
      case 'all':
        this.contentItemFilters = this.initContentItemFilters();
        break;
      case 'originalName':
        this.contentItemFilters.originalName = '';
        break;
      case 'type':
        this.contentItemFilters.type = [];
        break;
      case 'createdAtAsTime':
        this.contentItemFilters.createdAtAsTime = [];
        break;
      case 'dimensions':
        this.contentItemFilters.dimensions = '';
        break;
      case 'duration':
        this.contentItemFilters.duration = [];
        break;
      case 'fps':
        this.contentItemFilters.fps = null;
        break;
      case 'parentFolder':
        this.contentItemFilters.parentFolder = '';
        break;
      case 'tags':
        this.contentItemFilters.tags = [];
        break;
    }
  }

  setSelectedRow(id: string): void {
    this.selectedContentItemId = id;
  }

  /**
   * Sets the items to move and show the move modal
   */
  openBulkMoveDialog(): void {
    this.contentItemsToMove = this.selectedContentItems;
    this.showMoveDialog = true;
  }

  /**
   * Sets the item to move and show the move modal
   */
  openMoveDialog(contentItem: ContentItem): void {
    this.contentItemsToMove = [contentItem];
    this.showMoveDialog = true;
  }

  /**
   * Closes the move dialog and resets the state
   */
  closeMoveDialog(): void {
    this.contentItemsToMove = [];
    this.showMoveDialog = false;
  }

  /**
   * Closes the playlist dialog and resets the state
   */
  closePlaylistDialog(): void {
    this.showPlaylistDialog = false;
    this.playlistToEdit = PlaylistModel.getDefaultEmptyPlaylist();
  }

  /**
   * Opens the playlist dialog and sets the state
   */
  openPlaylistDialog(playlist: PlaylistModel): void {
    this.playlistToEdit = playlist;
    this.showPlaylistDialog = true;
  }

  openEditTagsDialog(contentItem: ContentItem): void {
    this.contentItemToEdit = contentItem;
    this.showTagsDialog = true;
  }

  closeTagsDialog(): void {
    this.contentItemToEdit = null;
    this.showTagsDialog = false;
  }

  /**
   * Called when deleting a content item
   */
  async deleteContentItem(contentItem: ContentItem): Promise<void> {
    // Get the playlists where this content item is used.
    this.isLoading = true;
    const contentReferences: Optional<ContentReferences> =
      await this.contentItemsStore.fetchReferencesContainingContentItem(contentItem._id);
    this.isLoading = false;
    if (!contentReferences) {
      return;
    }
    this.deleteWarningText = this.extractDeleteWarningText(contentReferences);
    this.showDeleteContentItemDialog = true;
    this.confirmHandler = () => {
      this.contentItemsStore.deleteContentItem(contentItem);
      this.showDeleteContentItemDialog = false;
      this.selectedContentItemId = ''; // reset the UI table row selection highlighting
    };
    this.cancelHandler = () => {
      this.showDeleteContentItemDialog = false;
    };
  }

  async bulkDeleteContentItems(): Promise<void> {
    if (this.selectedContentItems.length === 0) {
      return;
    }
    this.isLoading = true;
    const contentReferences: Optional<ContentReferences> =
      await this.contentItemsStore.bulkFetchReferencesContainingContentItem(this.selectedContentItems);
    this.isLoading = false;
    if (!contentReferences) {
      return;
    }
    this.deleteWarningText = this.extractDeleteWarningText(contentReferences);
    this.showDeleteContentItemDialog = true;
    this.confirmHandler = () => {
      this.contentItemsStore.bulkDeleteContentItems(this.selectedContentItems);
      this.showDeleteContentItemDialog = false;
      this.selectedContentItemId = ''; // reset the UI table row selection highlighting
    };
    this.cancelHandler = () => {
      this.showDeleteContentItemDialog = false;
    };
  }

  /**
   * Method that determines text in confirmation dialog while deleting content item
   * Format:
   * 1. general text
   * 2. Playlists that reference content item(bolded)
   * 3. Links to templates that reference content item
   * @param contentReferences all usages of mentioned content item
   * @private
   */
  private extractDeleteWarningText(contentReferences: ContentReferences): string {
    const errorMessage: string = `<p>${this.$t(this.$i18nTranslationKeys.confirm.deleteEntity.text.$path, {
      entity: this.$t(this.$i18nTranslationKeys.deviceView.contentItem.$path),
    })}</p>`;

    const playlistReferenceWarning: string = contentReferences.playlistsContainingItem?.length
      ? this.getWarningTextForReferences(
          contentReferences.playlistsContainingItem
            .map((playlist: ContentItem) => `<li><strong>${playlist.name}</strong></li>`)
            .join(''),
          this.$i18nTranslationKeys.deviceView.deletePlaylistItemWarning.$path
        )
      : '';
    const templateReferenceWarning: string = contentReferences.templatesContainingItem?.length
      ? this.getWarningTextForReferences(
          contentReferences.templatesContainingItem
            .map((template: GondolaTemplate) => `<li><a href="/template/${template._id}">${template.name}</a></li>`)
            .join(''),
          this.$i18nTranslationKeys.deviceView.deleteContentItemTemplateReferenceWarning.$path
        )
      : '';
    return `${errorMessage}${playlistReferenceWarning}${templateReferenceWarning}`;
  }

  /**
   * Returns HTML paragraph with warning message and list of html list elements
   * @param conflictsListAsHtml list of references converted to html elements and joined to one string
   * @param warningMessage introductory warning message
   * @private
   */
  private getWarningTextForReferences(conflictsListAsHtml: string, warningMessage: string): string {
    return `<p>${this.$t(warningMessage)}  <ul>${conflictsListAsHtml}</ul></p>`;
  }

  /**
   * Helper method that determines the target of both newly uploaded ContentItems and ContentItems selected
   * from the List.
   * Then triggers appropriate vuex store actions.
   */
  async updateContentItem(contentItem: ContentItem): Promise<void> {
    if (this.isReplaceMode) {
      this.selectItem(contentItem);
      this.closeContentItemsDialog();
      return;
    }
    // If playlistMode is true, we're adding items to a playlist
    if (this.isPlaylistMode && contentItem.isVideo()) {
      this.selectVideo(contentItem);
      this.closeContentItemsDialog();
      return;
    }
    // default to background if no current selection
    const railContentSelection: DeviceContentSelection = this.gondolaTemplatesStore.deviceContentSelection || {
      isForeground: false,
      index: -1,
    };
    if (railContentSelection.isForeground) {
      await handleForegroundContentUpdate(
        contentItem,
        railContentSelection,
        this.deviceTemplate,
        this.selectedSchedule
      );
    } else {
      await handleBackgroundContentUpdate(contentItem, this.deviceTemplate, this.selectedSchedule);
    }
    this.$emit('rail-grid-change');
    this.closeContentItemsDialog();
  }

  /**
   * When a pagination update occurs, clear (visual indicator of) content item selection.
   * This is a hacky trade off to avoid confusing situations such as:
   * Select item -> It's highlighted -> switch page -> item at same position is highlighted, even tough it is not actually
   * assigned.
   */
  paginationUpdate(): void {
    this.selectedContentItemId = '';
  }

  async openPreviewVideo(video: VideoModel): Promise<void> {
    this.playlistItemPreviewUrl = await video.getBlobUrl();
    this.playlistItemToPreview = video;
    this.showPlaylistItemPreview = true;
  }

  closePreviewVideo(): void {
    this.playlistItemToPreview = undefined;
    this.showPlaylistItemPreview = false;
  }

  /**
   * Triggered on intersection with the user view.
   * It will load more items into the list
   */
  onLoadMoreItems(isIntersecting: boolean): void {
    if (isIntersecting) {
      this.maximumLoadedItems += this.PAGE_SIZE_DEFAULT;
      this.updateBulkActionMenuPosition();
    }
  }

  /**
   * Sets the selected folder and emits the appropriate events depending on the modal/mode we're using
   */
  setSelectedFolder(folderId: string, updatePath: boolean = true): string {
    this.isFetching = true;
    this.contentItemsToMove = [];
    this.clearSelectedItems();
    this.selectedFolder = folderId;
    this.resetSearchBarFields();
    this.contentItemFilters = this.initContentItemFilters();
    if (this.isMoveItemMode) {
      this.$root.$emit('onSelectedTargetFolderMoveDialogChanged', this.selectedFolder);
    }
    if (!this.isModalEmbedded) {
      this.$root.$emit('selectedFolderChanged', this.selectedFolder);
      if (updatePath) {
        this.handleUpdatePath();
      }
    }
    this.onSelectedFolderChanged();
    return folderId;
  }

  resetSearchBarFields() {
    this.searchTags = [];
    this.searchRerenderKey += 1;
  }

  /**
   * Updates the path based on the selected folder
   */
  handleUpdatePath(): void {
    if (this.selectedFolder) {
      if (this.$route.fullPath === `/assets/${this.selectedFolder}`) {
        return;
      }
      this.$router.push({ params: { folderId: this.selectedFolder } });
    } else {
      if (this.$route.fullPath === `/assets`) {
        return;
      }
      this.$router.push({ path: '/assets' });
    }
  }

  private initContentItemFilters(): ContentItemFilters {
    return {
      originalName: '',
      type: [],
      createdAtAsTime: [],
      dimensions: '',
      duration: [],
      fps: null,
      datePrefillOption: '',
      parentFolder: '',
      tags: [],
    };
  }

  async getContentItems(isSearchMode: boolean): Promise<void> {
    try {
      const deviceContentSelection: DeviceContentSelection = this.gondolaTemplatesStore.deviceContentSelection || {
        isForeground: false,
        index: -1,
      };
      this.isFetching = true;
      this.maximumLoadedItems = this.PAGE_SIZE_DEFAULT;
      let contentItems: Array<ContentItem>;
      if (isSearchMode) {
        contentItems = await this.contentItemsStore.search(
          this.searchTerm,
          this.typeFilters,
          this.searchTags.map((tag: TagReference) => tag.id)
        );
      } else {
        contentItems = await this.contentItemsStore.getFolderContentItem(this.selectedFolder);
      }
      // If selectable content type length is !== number of items in the ContentType enum, some filters are set, filter the content list array
      if (this.selectableContentType?.length !== Object.keys(ContentType).length) {
        this.contentItems = contentItems.filter((item: ContentItem) => this.selectableContentType?.includes(item.type));
        this.isFetching = false;
        this.$root.$emit('onCurrentlyDisplayedContentItems', this.contentItems);
        return;
      }
      // If a foreground item is selected, remove the playlists from the content items array since playlists can only be assigned to the background
      // Skip this if it has already been filtered in the above condition
      if (deviceContentSelection.isForeground && this.selectableContentType?.includes(ContentType.Playlist)) {
        this.contentItems = contentItems.filter((item: ContentItem) => item.type !== ContentType.Playlist);
        this.isFetching = false;
        this.$root.$emit('onCurrentlyDisplayedContentItems', this.contentItems);
        return;
      }
      this.contentItems = contentItems;
      this.isFetching = false;
      this.$root.$emit('onCurrentlyDisplayedContentItems', this.contentItems);
      return;
    } catch (e: unknown) {
      console.error(e);
      this.isFetching = false;
    }
  }

  /**
   * Listen to changes on selected folder and emit it to the upload component.
   * Only when the modal is used in the content management page.
   */
  private onSelectedFolderChanged() {
    // If the selectedFolder has been changed, then the user is navigating, thus it exits search mode
    this.isSearchMode = false;
    this.getContentItems(false);
  }

  /**
   * Sets the search parameters and triggered the search
   */
  triggerSearch(searchParams: SearchParams): void {
    this.clearSelectedItems();
    const { searchTerm, typeFilter, tags }: SearchParams = searchParams;
    if (searchParams.searchTerm.length == 0 && searchParams.typeFilter.length == 0 && tags.length == 0) {
      return this.onSelectedFolderChanged();
    }
    this.selectedFolder = '';
    this.typeFilters = typeFilter;
    this.searchTerm = searchTerm;
    this.searchTags = tags;
    this.isSearchMode = true;
    this.getContentItems(true);
  }

  changeFileNameFilter(fileNameFilterValue: string) {
    this.contentItemFilters.originalName = fileNameFilterValue;
    this.clearSelectedItems();
  }
  changeFileTypeFilter(fileTypeFilterValue: ContentType[]) {
    this.contentItemFilters.type = fileTypeFilterValue;
    this.clearSelectedItems();
  }
  changeCreatedDateFilter(createdAtFilterValue: [string?, string?]) {
    this.contentItemFilters.createdAtAsTime = createdAtFilterValue;
    this.clearSelectedItems();
  }
  changeDimensionsFilter(dimensionsFilterValue: string) {
    this.contentItemFilters.dimensions = dimensionsFilterValue;
    this.clearSelectedItems();
  }

  changeDurationFilter(durationFilterValue: [number?, number?]) {
    this.contentItemFilters.duration = durationFilterValue;
    this.clearSelectedItems();
  }

  changeFpsFilter(fpsFilterValue: number | null) {
    this.contentItemFilters.fps = fpsFilterValue;
    this.clearSelectedItems();
  }
  changeTagsFilter(selectedTags: Array<TagReference>) {
    this.contentItemFilters.tags = selectedTags;
    this.clearSelectedItems();
  }

  prefillChange(datePrefillValue: string): void {
    this.contentItemFilters.datePrefillOption = datePrefillValue;
    this.clearSelectedItems();
  }

  changeParentFolderFilter(parentFolderFilterValue: string): void {
    this.contentItemFilters.parentFolder = parentFolderFilterValue;
    this.clearSelectedItems();
  }

  clearSelectedItems(): void {
    this.selectedContentItems = [];
    this.updateBulkActionMenuPosition();
  }

  /* GETTERS */

  get noItemsChecked(): boolean {
    return this.selectedContentItems.length === 0;
  }

  get allItemsChecked(): boolean {
    return this.selectedContentItems.length === this.contentItems.length && this.selectedContentItems.length > 0;
  }

  get someItemsChecked(): boolean {
    return this.selectedContentItems.length > 0 && this.selectedContentItems.length < this.contentItems.length;
  }

  get headers(): Array<CustomDataTableHeader> {
    const defaultHeaders: Array<CustomDataTableHeader> = [
      {
        text: this.$t(this.$i18nTranslationKeys.deviceView.fileType.$path),
        align: 'left',
        sortable: true,
        value: 'type',
      },
      {
        text: this.$t(this.$i18nTranslationKeys.deviceView.backgroundFileName.$path),
        value: 'originalName',
      },
      {
        text: this.$t(this.$i18nTranslationKeys.deviceView.createDate.$path),
        value: 'createdAtAsTime',
      },
    ];
    if (this.isSearchMode) {
      // If it's search mode, add the parentFolder column as the 3rd column
      defaultHeaders.splice(2, 0, {
        text: this.$t(this.$i18nTranslationKeys.contentManagement.location.$path),
        value: 'parentFolder',
      });
    }
    if (this.isMoveItemMode) {
      // If this is the move view, only show minimal details
      return defaultHeaders;
    }
    if (!this.isModalEmbedded) {
      // If this is in a modal don't show the selection column
      defaultHeaders.splice(0, 0, {
        text: '',
        align: 'left',
        sortable: false,
        value: 'selection',
        class: 'pr-3',
      });
    }
    // Else this is listing the full details of the items
    return [
      ...defaultHeaders,
      {
        text: this.$t(this.$i18nTranslationKeys.contentManagement.dimensions.$path),
        value: 'dimensions',
      },
      {
        text: this.$t(this.$i18nTranslationKeys.deviceView.duration.$path),
        value: 'duration',
      },
      {
        text: this.$t(this.$i18nTranslationKeys.deviceView.fps.$path),
        value: 'fps',
      },
      {
        text: this.$t(this.$i18nTranslationKeys.settings.tags.title.$path),
        value: 'tags',
      },
      {
        text: this.$t(this.$i18nTranslationKeys.action.actions.$path),
        sortable: false,
        value: '_id',
        align: 'center',
      },
    ];
  }

  get filteredItems(): Array<ContentItem> {
    return this.contentItems.filter((contentItem: ContentItem) =>
      isContentItemFiltered(contentItem, this.contentItemFilters)
    );
  }

  /**
   * Returns the list of parsed content items, limited by size for lazy loading reasons.
   */
  get parsedContentItems(): Array<ContentItem> {
    return this.filteredItems.slice(0, this.maximumLoadedItems);
  }

  get isPlaylistMode(): boolean {
    return this.contentItemSelectionTarget === ContentItemSelectionTarget.PLAYLIST;
  }

  get hasValidContentItemTarget(): boolean {
    return this.contentItemSelectionTarget !== ContentItemSelectionTarget.NONE;
  }

  get isReplaceMode(): boolean {
    return this.contentItemSelectionTarget === ContentItemSelectionTarget.REPLACE_ITEM;
  }

  get amountOfItemsPerPage(): number {
    // we only have no target for the item selection on the full-screen global content management page
    // and there we want to show all content items
    return !this.hasValidContentItemTarget ? this.PAGE_SIZE_ALL : this.PAGE_SIZE_DEFAULT;
  }

  get canLoadMoreItems(): boolean {
    return this.filteredItems.length > this.maximumLoadedItems;
  }

  get isMoveItemMode(): boolean {
    return this.contentItemSelectionTarget === ContentItemSelectionTarget.MOVE_ITEM;
  }

  get defaultSelectedSearchFilters(): Array<ContentType> {
    return this.isMoveItemMode ? [ContentType.Folder] : [];
  }

  get searchParams(): SearchParams {
    return {
      searchTerm: this.searchTerm,
      typeFilter: this.typeFilters,
      tags: this.searchTags,
    };
  }

  get searchRowClass(): string {
    return this.isModalEmbedded ? '' : 'mt-2';
  }

  get contentItemsWrapperClass(): string {
    return this.isModalEmbedded ? 'elevation-0 themed p-relative' : 'themed p-relative mt-6';
  }
}
