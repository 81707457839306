import HardwareModel from './HardwareModel.model';
import { DeviceJSON, Firmware, WifiConfig } from '@common/device/types';
import { OnlineStatus } from '@common/enums';
import { DeviceCSV } from '@client/models/DeviceOverviewModels';

/**
 * This class represents an actual physical device.
 */
export default class Device {
  _id: string;
  hardwareModel: HardwareModel;
  longId: string;
  shortId: string;
  hash: string;
  onlineStatus?: OnlineStatus;
  onlineStatusLastChanged?: Date;
  onlineStatusLoading: boolean;
  gondolaId: string;
  customerId: string;
  storeId: string;
  dimmingLevel?: number;
  wifiConfig?: WifiConfig;
  firmware?: Firmware;
  sensor?: string;

  constructor(
    hardwareModel: HardwareModel = HardwareModel.getByIdentifier(),
    _id: string = '',
    longId: string = '',
    shortId: string = '',
    hash: string = '',
    onlineStatus?: OnlineStatus,
    onlineStatusLastChanged?: Date,
    onlineStatusLoading: boolean = false,
    gondolaId: string = '',
    customerId: string = '',
    storeId: string = '',
    dimmingLevel?: number,
    wifiConfig?: WifiConfig,
    firmware?: Firmware,
    sensor?: string
  ) {
    this._id = _id;
    this.hardwareModel = hardwareModel;
    this.longId = longId || '';
    this.shortId = shortId || '';
    this.hash = hash;
    this.onlineStatus = onlineStatus;
    this.onlineStatusLastChanged = onlineStatusLastChanged;
    this.onlineStatusLoading = onlineStatusLoading;
    this.gondolaId = gondolaId;
    this.customerId = customerId;
    this.storeId = storeId;
    this.dimmingLevel = dimmingLevel;
    this.wifiConfig = wifiConfig;
    this.firmware = firmware;
    this.sensor = sensor;
  }

  static clone(source: Device): Device {
    return new Device(
      source.hardwareModel,
      source._id,
      source.longId,
      source.shortId,
      source.hash,
      source.onlineStatus,
      source.onlineStatusLastChanged,
      source.onlineStatusLoading,
      source.gondolaId,
      source.customerId,
      source.storeId,
      source.dimmingLevel,
      source.wifiConfig,
      source.firmware,
      source.sensor
    );
  }

  static fromJSON(source: DeviceJSON): Device {
    return new Device(
      HardwareModel.getByIdentifier(source.hardwareModel),
      source._id,
      source.longId,
      source.shortId,
      source.hash,
      source.onlineStatus,
      source.onlineStatusLastChanged,
      false,
      source.gondolaId,
      source.customerId,
      source.storeId,
      source.dimmingLevel,
      source.wifiConfig,
      source.firmware,
      source.sensor
    );
  }

  toJSON(): DeviceJSON {
    return {
      _id: this._id,
      hardwareModel: this.hardwareModel.identifier,
      longId: this.longId,
      shortId: this.shortId,
      hash: this.hash,
      onlineStatus: this.onlineStatus,
      onlineStatusLastChanged: this.onlineStatusLastChanged,
      gondolaId: this.gondolaId,
      customerId: this.customerId,
      storeId: this.storeId,
      dimmingLevel: this.dimmingLevel,
      wifiConfig: this.wifiConfig,
      firmware: this.firmware,
    };
  }

  toDeviceCSV(storeAzureId: string, aisle?: string, positionInAisle?: string): DeviceCSV {
    return {
      longId: this.longId,
      shortId: this.shortId,
      onlineStatus: this.onlineStatus,
      onlineStatusLastChanged: this.onlineStatusLastChanged,
      hardwareModel: this.hardwareModel.identifier,
      dimmingLevel: this.dimmingLevel,
      status: this.wifiConfig?.status,
      lastActivityDateTime: this.wifiConfig?.lastActivityDateTime,
      systemVersion: this.firmware?.systemVersion,
      flashDate: this.firmware?.flashDate,
      version: this.firmware?.version,
      hardwareAddress: this.wifiConfig?.lastKnownActiveNetwork.hardwareAddress,
      address: this.wifiConfig?.lastKnownActiveNetwork.address,
      mask: this.wifiConfig?.lastKnownActiveNetwork.mask,
      ssid: this.wifiConfig?.lastKnownActiveNetwork.ssid,
      bssid: this.wifiConfig?.lastKnownActiveNetwork.bssid,
      linkQuality: this.wifiConfig?.lastKnownActiveNetwork.linkQuality,
      signalLevel: this.wifiConfig?.lastKnownActiveNetwork.signalLevel,
      channel: this.wifiConfig?.lastKnownActiveNetwork.channel,
      frequency: this.wifiConfig?.lastKnownActiveNetwork.frequency,
      bitRate: this.wifiConfig?.lastKnownActiveNetwork.bitRate,
      connectivity: this.wifiConfig?.lastKnownActiveNetwork.connectivity,
      flags: this.wifiConfig?.lastKnownActiveNetwork.flags,
      security: this.wifiConfig?.lastKnownActiveNetwork.security,
      expectedSsid: this.wifiConfig?.expectedSsid,
      storeAzureId: storeAzureId,
      aisle: aisle,
      section: positionInAisle,
      sensor: this.sensor,
    };
  }
}
