import Moment from 'moment';
import { Locale, TranslateResult } from 'vue-i18n';
import { END_OF_DAY_TIME, END_OF_DAY_TIME_WITH_SECONDS, START_OF_DAY_TIME } from '@client/models';
import i18n from '@client/plugins/i18n/i18n';
import { MILLISECONDS_PER_MINUTE } from '@common/constants';
import { TranslationKeys } from '@client/plugins/i18n/locales';

/**
 * Uses Moment library to get the date string from a full date string will return a date in "YYYY/MM/DD" format
 * @param date to get as {@class string}
 * @param locale to be used for the translation
 */
function dateToMomentDate(date: Date, locale: Locale): string {
  return Moment(date, 'YYYY/MM/DD')
    .toDate()
    .toLocaleDateString(locale, { day: '2-digit', month: '2-digit', year: 'numeric' })
    .slice(0, 10);
}

/**
 * Uses Moment library to get the time string from a full date string will return a date in "HH:ss" format
 * It uses 24h formatting so the time will be from 00:00 - 23:59, doesn't use AM/PM
 * @param date to get as {@class string}
 * @param locale to be used for the translation
 * @param isStartDate indicate if this is the start date
 */
function dateToMomentTime(date: string, locale: Locale, isStartDate?: boolean): string {
  const formatedTime: string = Moment(date, 'hh-mm')
    .toDate()
    .toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit', hour12: false });
  if (isStartDate && formatedTime === END_OF_DAY_TIME) {
    return START_OF_DAY_TIME;
  }
  return formatedTime;
}

/**
 * Uses the js internationalization API to translate the {@param day} into the currently selected locale
 * format short: will return i.e "Mon" for Monday in english
 * @param day to get as {@class string} the translation for
 * @param locale to be used for the translation
 */
function getShortDayName(day: string, locale: Locale): string {
  return `${Moment().day(day).toDate().toLocaleDateString(locale, { weekday: 'short' }).toLocaleUpperCase()}`;
}

/**
 * Returns the date formatted to the current locale
 * @param locale Current locale
 * @param date Date as string or date object
 * @param isFullDate if true it will return a full datetime string else it will return a time stream
 */
function getCreatedAtFormatted(locale: Locale, date: string | Date | undefined, isFullDate?: boolean): string {
  if (!date) {
    return '';
  }
  const formattedDate: Date = Moment(date).toDate();
  return isFullDate
    ? formattedDate.toLocaleString(locale, { hour12: false })
    : formattedDate.toLocaleTimeString(locale, { hour12: false });
}

function getRelativeTimeFromNow(locale: Locale, previous: Date | string | undefined): TranslateResult {
  const currentDate: number = new Date().getTime();
  const msPerMinute: number = MILLISECONDS_PER_MINUTE;
  const msPerHour: number = msPerMinute * 60;
  const msPerDay: number = msPerHour * 24;
  const msPerMonth: number = msPerDay * 30;
  const msPerYear: number = msPerDay * 365;

  if (!previous) {
    return '';
  }

  const elapsed: number = Math.max(0, currentDate - new Date(previous).getTime());

  if (elapsed < msPerMinute) {
    return i18n.t(TranslationKeys.notifications.relativeTime.secondsAgo.$path, { time: Math.round(elapsed / 1000) });
  } else if (elapsed < msPerHour) {
    return i18n.t(TranslationKeys.notifications.relativeTime.minutesAgo.$path, {
      time: Math.round(elapsed / msPerMinute),
    });
  } else if (elapsed < msPerDay) {
    return i18n.t(TranslationKeys.notifications.relativeTime.hoursAgo.$path, { time: Math.round(elapsed / msPerHour) });
  } else if (elapsed < msPerMonth) {
    return i18n.t(TranslationKeys.notifications.relativeTime.daysAgo.$path, { time: Math.round(elapsed / msPerDay) });
  } else if (elapsed < msPerYear) {
    return i18n.t(TranslationKeys.notifications.relativeTime.monthsAgo.$path, {
      time: Math.round(elapsed / msPerMonth),
    });
  } else {
    return i18n.t(TranslationKeys.notifications.relativeTime.yearsAgo.$path, { time: Math.round(elapsed / msPerYear) });
  }
}

/**
 * Regex to validate a time input value
 * Accepted formats : HH:mm H:mm HH:m H:m
 */
const timeValidationRegex: RegExp = /^(\d|0\d|1\d|2[0-3]):(\d|[0-5]\d)$/;

/**
 * Compares two time strings in HH:mm format
 * @param startTime
 * @param endTime
 */
const isEndTimeBeforeStartTime = (startTime: string, endTime: string): boolean => {
  return Moment(endTime === START_OF_DAY_TIME ? END_OF_DAY_TIME_WITH_SECONDS : endTime, 'HH:mm').isSameOrBefore(
    Moment(startTime, 'HH:mm')
  );
};

export {
  getShortDayName,
  dateToMomentDate,
  dateToMomentTime,
  getCreatedAtFormatted,
  getRelativeTimeFromNow,
  timeValidationRegex,
  isEndTimeBeforeStartTime,
};
