import { ContentType } from '@common/enums';
import { LayerPlaylistItemJSON } from '@common/schedule/types';
import AzureBlobService from '@client/plugins/azureblobservice';

/**
 * Single playlist item layer
 */
export default class LayerPlaylistItem {
  _id?: string;
  name: string;
  url: string;
  previewUrl: string;
  type: ContentType;
  height: number;
  width: number;
  duration: number;
  fps: number;
  offsetX: number;
  offsetY: number;
  zIndex: number;
  checksum?: string;
  hideLabels: boolean;
  layerId: string;

  constructor(
    id: string,
    name: string,
    url: string,
    previewUrl: string,
    type: ContentType,
    height: number,
    width: number,
    duration: number = 0,
    fps: number = 0,
    offsetX: number,
    offsetY: number,
    zIndex: number,
    checksum: string | undefined,
    hideLabels: boolean,
    layerId: string
  ) {
    this._id = id;
    this.name = name;
    this.url = url;
    this.previewUrl = previewUrl;
    this.type = type;
    this.height = height;
    this.width = width;
    this.duration = duration;
    this.fps = fps;
    this.offsetX = offsetX;
    this.offsetY = offsetY;
    this.zIndex = zIndex;
    this.checksum = checksum;
    this.hideLabels = hideLabels;
    this.layerId = layerId;
  }

  public static init(): LayerPlaylistItem {
    return new LayerPlaylistItem('', '', '', '', ContentType.Video, 0, 0, 0, 0, 0, 0, 0, '', false, '');
  }

  public static fromJSON(source: LayerPlaylistItemJSON): LayerPlaylistItem {
    return new LayerPlaylistItem(
      source._id || '',
      source.name,
      source.url,
      source.previewUrl ?? '',
      source.type,
      source.height,
      source.width,
      source.duration,
      source.fps,
      source.offsetX,
      source.offsetY,
      source.zIndex,
      source.checksum,
      source.hideLabels,
      source.layerId
    );
  }

  public toJSON(): LayerPlaylistItemJSON {
    return {
      _id: this._id,
      name: this.name,
      url: this.url,
      previewUrl: this.previewUrl,
      type: this.type,
      height: this.height,
      width: this.width,
      duration: this.duration,
      fps: this.fps,
      offsetX: this.offsetX,
      offsetY: this.offsetY,
      zIndex: this.zIndex,
      checksum: this.checksum,
      hideLabels: this.hideLabels,
      layerId: this.layerId,
    };
  }

  /**
   * Returns the original content URL wrapped with the azure authorization token
   */
  public async getBlobUrl(): Promise<string> {
    return AzureBlobService.wrapUrlWithReadToken(this.url);
  }

  /**
   * Returns the preview content URL wrapped with the azure authorization token
   * returns the original URL as a fallback
   */
  public async getPreviewBlobUrl(): Promise<string> {
    if (!this.previewUrl) {
      return this.getBlobUrl();
    }
    return AzureBlobService.wrapUrlWithReadToken(this.previewUrl);
  }
}
