
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DeviceTemplate, GondolaTemplate, Schedule, ScheduledContent, ScheduleLayer } from '@client/models';
import ModalHeader from '@client/components/Layouts/ModalHeader.vue';
import { TranslateResult } from 'vue-i18n';
import { SchedulesStore, useSchedulesStore } from '@client/stores/schedules';
import Preview from '../Preview/Preview.vue';
import ForegroundContentModel from '@client/models/ScheduleModels/ForegroundContent.model';
import BackgroundContentModel from '@client/models/ScheduleModels/BackgroundContent.model';
import ContentItemsList from '../ContentItems/ContentItemsList.vue';
import { ContentType } from '@common/enums';
import { ContentItemSelectionTarget } from '@client/enums';
import { ContentItem } from '@client/models/ContentModels/types';
import { all } from 'axios';

interface Step {
  title: TranslateResult;
  icon: string;
  rules?: Array<() => TranslateResult | boolean>;
}

interface SelectedDeviceIndexes {
  rowIndex: number;
  colIndex: number;
}

@Component({
  methods: { all },
  components: { ContentItemsList, Preview, ModalHeader },
})
export default class QuickApplyBackground extends Vue {
  @Prop()
  private gondolaTemplate!: GondolaTemplate;

  private schedulesStore: SchedulesStore = useSchedulesStore();

  private currentStep: number = 1;
  private showDialog: boolean = false;
  private selectedSchedule: string = '';
  private selectedDevicesMap: Map<string, DeviceTemplate> = new Map();
  private selectedDevicesIndexes: Array<SelectedDeviceIndexes> = [];

  private steps: Array<Step> = [
    {
      title: this.$t(this.$i18nTranslationKeys.devices.navigationTitle.$path),
      icon: 'mdi-check',
      rules: [],
    },
    {
      title: this.$t(this.$i18nTranslationKeys.deviceView.editContent.$path),
      icon: 'mdi-magic-staff',
      rules: [],
    },
  ];

  closeDialog(): void {
    this.showDialog = false;
    this.selectedSchedule = '';
  }

  private nextStep(): void {
    this.currentStep++;
  }

  private previousStep(): void {
    this.currentStep--;
  }

  private getSize(deviceTemplate: DeviceTemplate): string {
    return `device-${deviceTemplate.hardwareModel.identifier}`;
  }

  private deviceHasContent(deviceTemplate: DeviceTemplate): boolean {
    return deviceTemplate.foregroundContent.length > 0 || deviceTemplate.backgroundContent.length > 0;
  }

  private getForegroundContentItems(deviceTemplate: DeviceTemplate): Array<ForegroundContentModel> {
    return deviceTemplate.foregroundContent;
  }

  private getBackgroundContentItem(deviceTemplate: DeviceTemplate): BackgroundContentModel {
    return deviceTemplate.backgroundContent[0];
  }

  private setSelectedDevice(deviceTemplate: DeviceTemplate, rowIndex: number, colIndex: number): void {
    if (this.selectedDevicesMap.has(`${rowIndex}-${colIndex}`)) {
      this.selectedDevicesMap.delete(`${rowIndex}-${colIndex}`);
    } else {
      this.selectedDevicesMap.set(`${rowIndex}-${colIndex}`, deviceTemplate);
    }
    this.selectedDevicesIndexes = Array.from(this.selectedDevicesMap.keys()).map((key: string) => {
      const [rowIndex, colIndex]: Array<string> = key.split('-');
      return { rowIndex: Number(rowIndex), colIndex: Number(colIndex) };
    });
  }

  private isDeviceSelected(rowIndex: number, colIndex: number): boolean {
    return this.selectedDevicesIndexes.some(
      (device: SelectedDeviceIndexes) => device.rowIndex === rowIndex && device.colIndex === colIndex
    );
  }

  private setFileToSelectedDevicesBackground(contentItem: ContentItem): void {
    this.selectedDevicesIndexes.forEach((selectedDevice: SelectedDeviceIndexes) => {
      const deviceTemplate: DeviceTemplate =
        this.gondolaTemplate.railGrid[selectedDevice.rowIndex][selectedDevice.colIndex];
      let backgroundContent: BackgroundContentModel = new BackgroundContentModel();
      if (!deviceTemplate.backgroundContent[0]) {
        deviceTemplate.backgroundContent[0] = new BackgroundContentModel();
      } else {
        backgroundContent = deviceTemplate.backgroundContent[0].clone();
      }
      if (!this.selectedSchedule) {
        // This is a base layer
        backgroundContent.baseLayer = contentItem.toBaseLayer();
      }
      if (this.selectedSchedule) {
        if (!backgroundContent.scheduledContent) {
          backgroundContent.scheduledContent = [];
        }
        const scheduledLayer: ScheduleLayer = contentItem.toScheduledLayer();
        const scheduledContent: ScheduledContent = new ScheduledContent(this.selectedSchedule, scheduledLayer);
        const scheduleIndex: number = backgroundContent.scheduledContent.findIndex(
          (schedule: ScheduledContent) => schedule.scheduleId === this.selectedSchedule
        );
        // If the scheduled doesn't exist yet then create it, else override it
        if (scheduleIndex < 0) {
          backgroundContent.scheduledContent.push(scheduledContent);
        } else if (backgroundContent.scheduledContent[scheduleIndex]) {
          backgroundContent.scheduledContent[scheduleIndex] = scheduledContent;
        }
      }
      Vue.set(deviceTemplate.backgroundContent, 0, backgroundContent);
    });
    this.showDialog = false;
    this.currentStep = 1;
    this.selectedDevicesMap.clear();
    this.selectedDevicesIndexes = [];
  }

  get isBackButtonVisible(): boolean {
    return this.currentStep > 1;
  }

  get isNextButtonVisible(): boolean {
    return this.currentStep < this.steps.length;
  }

  get schedules(): Array<Schedule> {
    return this.schedulesStore.getSchedulesForTemplate(this.id);
  }

  get areSelectedRailsValid(): boolean {
    return this.selectedDevicesIndexes.length > 0;
  }

  get id(): string {
    return this.$route.params.id;
  }

  get areAllBaseBackgroundsSet(): boolean {
    return this.selectedDevicesIndexes.every((selectedDevice: SelectedDeviceIndexes) => {
      const deviceTemplate: DeviceTemplate =
        this.gondolaTemplate.railGrid[selectedDevice.rowIndex][selectedDevice.colIndex];
      return deviceTemplate.backgroundContent?.[0]?.baseLayer !== undefined;
    });
  }

  get isScheduleSelectDisabled(): boolean {
    return this.schedules.length === 0 || !this.areAllBaseBackgroundsSet;
  }

  get selectableContentType(): Array<ContentType> {
    return [ContentType.Image, ContentType.Playlist, ContentType.Video, ContentType.Folder];
  }

  get contentItemSelectionTarget(): ContentItemSelectionTarget {
    return ContentItemSelectionTarget.REPLACE_ITEM;
  }
}
