import { BaseEventLogJSON, EventLogJSON, EventType } from '@common/eventlog/types';
import { StoreActiveHoursEventLog } from './StoreActiveHoursEventLog/StoreActiveHoursEventLog.model';
import { SectionActiveHoursEventLog } from './SectionActiveHoursEventLog/SectionActiveHoursEventLog.model';
import { PublishingEventLog } from './PublishingEventLog/PublishingEventLog.model';
import { DeviceStatusEventLog } from './DeviceStatusEventLog/DeviceStatusEventLog.model';
import { ActiveHoursConfigEventLog } from './ActiveHoursConfigEventLog/ActiveHoursConfigEventLog.model';
import { FirmwareReleasedEventLog } from '@client/models/EventLogModels/ReleaseManagementEventLog/FirmwareReleasedEvent.modal';
import { FirmwareChangedEventLog } from '@client/models/EventLogModels/ReleaseManagementEventLog/FirmwareChangedEventLog.modal';
import { StoreSyncEventLog } from '@client/models/EventLogModels/StoreSyncEventLog/StoreSyncEventLog.model';

export class BaseEventLog {
  _id: string;
  schemaVersion: number;
  type: EventType;
  azureStoreId: string;
  storeId: string;
  customerId: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(
    id: string,
    schemaVersion: number,
    type: EventType,
    azureStoreId: string,
    storeId: string,
    customerId: string,
    createdAt: Date,
    updatedAt: Date
  ) {
    this.type = type;
    this.schemaVersion = schemaVersion;
    this.azureStoreId = azureStoreId;
    this.storeId = storeId;
    this.customerId = customerId;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this._id = id;
  }

  isStoreActiveHoursEventLog(): this is StoreActiveHoursEventLog {
    return this.type === EventType.STORE_ACTIVE_HOURS;
  }

  isSectionActiveHoursEventLog(): this is SectionActiveHoursEventLog {
    return this.type === EventType.SECTION_ACTIVE_HOURS;
  }

  isFirmwareReleasedEventLog(): this is FirmwareReleasedEventLog {
    return this.type === EventType.FIRMWARE_RELEASED;
  }

  isFirmwareChangedEventLog(): this is FirmwareChangedEventLog {
    return this.type === EventType.FIRMWARE_CHANGED;
  }

  isPublishingEventLog(): this is PublishingEventLog {
    return this.type === EventType.PUBLISHING;
  }

  isDeviceStatusEventLog(): this is DeviceStatusEventLog {
    return this.type === EventType.REGISTERED_STATUS || this.type === EventType.ONLINE_STATUS;
  }

  isActiveHoursConfigEventLog(): this is ActiveHoursConfigEventLog {
    return this.type === EventType.ACTIVE_HOURS_CONFIG;
  }

  isStoreSyncEventLog(): this is StoreSyncEventLog {
    return this.type === EventType.STORE_SYNC;
  }

  public static fromJSON(eventLogJSON: BaseEventLogJSON): BaseEventLog {
    const { _id, azureStoreId, storeId, customerId, type, updatedAt, createdAt, schemaVersion }: EventLogJSON =
      eventLogJSON;
    return new BaseEventLog(_id, schemaVersion, type, azureStoreId, storeId, customerId, createdAt, updatedAt);
  }
}
