
import { Component, Prop, Vue } from 'vue-property-decorator';
import TagModel from '@client/models/SettingsModels/Tag.model';
import { CustomDataTableHeader } from '@client/definitions/CustomDataTableHeader';
import ReferenceNumberButton from '@client/components/Settings/Tags/Actions/ReferenceNumberButton.vue';
import EditButton from '@client/components/Settings/Tags/Actions/EditButton.vue';
import DeleteButton from '@client/components/Settings/Tags/Actions/DeleteButton.vue';
import TagPreview from '@client/components/Settings/Tags/TagPreview.vue';

@Component({
  components: { DeleteButton, EditButton, ReferenceNumberButton, TagPreview },
})
export default class TagsTable extends Vue {
  @Prop()
  private tags!: Array<TagModel>;
  @Prop()
  private isLoading!: boolean;

  get headers(): CustomDataTableHeader[] {
    return [
      {
        text: this.$t(this.$i18nTranslationKeys.settings.tags.tableHeaders.name.$path),
        value: 'name',
      },
      {
        text: this.$t(this.$i18nTranslationKeys.settings.tags.tableHeaders.description.$path),
        value: 'description',
        sortable: false,
        width: '60%',
      },
      {
        text: this.$t(this.$i18nTranslationKeys.settings.tags.tableHeaders.color.$path),
        value: 'color',
        sortable: false,
      },
      {
        text: this.$t(this.$i18nTranslationKeys.settings.tags.tableHeaders.stores.$path),
        value: 'stores',
      },

      {
        text: this.$t(this.$i18nTranslationKeys.settings.tags.tableHeaders.templates.$path),
        value: 'templates',
      },
      {
        text: this.$t(this.$i18nTranslationKeys.settings.tags.tableHeaders.sections.$path),
        value: 'sections',
      },
      {
        text: this.$t(this.$i18nTranslationKeys.settings.tags.tableHeaders.contentItems.$path),
        value: 'contentItems',
      },
      {
        text: this.$t(this.$i18nTranslationKeys.settings.tags.tableHeaders.actions.$path),
        value: 'actions',
        sortable: false,
      },
    ];
  }
}
