import { defineStore, Store, StoreDefinition } from 'pinia';
import axios, { AxiosResponse } from 'axios';
import Config from '@client/utils/config';
import { Optional } from '@common/types';
import Vue from 'vue';
import { GondolaTemplate, GondolaTemplate as GondolaTemplateModel } from '@client/models';
import { DeviceContentSelection, GondolaTemplateZoomCache } from './types';
import { GondolaTemplateWithIdJSON, NewGondolaTemplateJSON } from '@common/gondola-template/types';
import { ScheduleModelJSON } from '@common/schedule/types';
import { SchedulesStore, useSchedulesStore } from '@client/stores/schedules';
import ScheduleModel from '@client/models/ScheduleModels/Schedule.model';
import TagReference from '@client/models/SettingsModels/TagReference';

export interface GondolaTemplatesGetters {
  getById: (state: GondolaTemplatesState) => (id: string) => Optional<GondolaTemplate>;
  getZoomLevelById: (state: GondolaTemplatesState) => (id: string) => number;
  findLikeName: (state: GondolaTemplatesState) => (nameQuery: string) => Optional<GondolaTemplate>;
  findName: (state: GondolaTemplatesState) => (nameQuery: string) => Optional<GondolaTemplate>;
  getTemplates: (state: GondolaTemplatesState) => () => Array<GondolaTemplate>;
}

export interface GondolaTemplatesActions {
  fetch(): Promise<void>;
  updateAll(gondolaTemplates: Array<GondolaTemplateModel>): void;
  create(gondolaTemplate: NewGondolaTemplateJSON): Promise<string | undefined>;
  clone(
    gondolaTemplateId: string,
    newName: Optional<string>,
    selectedTags: TagReference[]
  ): Promise<string | undefined>;
  updateGondolaTemplate(gondolaTemplate: GondolaTemplate): Promise<void>;
  updateDeviceContentSelection(
    data: DeviceContentSelection & {
      selectedSchedule?: string;
    }
  ): void;
  deleteDevice(gondolaTemplateId: string, row: number, col: number): Promise<void>;
  delete(gondolaTemplateId: string): Promise<void>;
  search(): Promise<void>;
  clearSearchResults(): void;
  setSearchFilters(
    sortOrder?: string,
    orderBy?: string,
    searchTerm?: string,
    startDate?: string,
    endDate?: string,
    searchTags?: string[]
  ): void;
  clearSearchFilters(): void;
  clear(): void;
  update(data: GondolaTemplateWithIdJSON): void;
  updateGondolaZoomLevelById(gondolaTemplateId: string, zoomLevel: number): void;
  loadGondolaZoomFromLocalStorage(): void;
  setIsCanvasLoading(status: boolean): void;
  setIsUpdating(status: boolean): void;
}

export interface GondolaTemplatesState {
  gondolaTemplates: Array<GondolaTemplate>;
  /*
  Fetching status used to prevent concurrent calls
  If this is set to true and a fetch is called, it will be ignored
   */
  isFetching: boolean;
  fetched: boolean;
  deviceContentSelection: DeviceContentSelection;
  gondolaTemplatesZoom: GondolaTemplateZoomCache;
  searchResults: {
    results: Array<GondolaTemplate>;
    totalCount: number;
  };
  isSearching: boolean;
  currentPage: number;
  searchFilters: {
    sortOrder?: string;
    orderBy?: string;
    searchTerm?: string;
    startDate?: string;
    endDate?: string;
    searchTags?: string[];
  };
  loadingIndicator: {
    update: boolean;
    isCanvasLoading: boolean;
  };
}

export type GondolaTemplatesStoreDefinition = StoreDefinition<
  'gondolaTemplates',
  GondolaTemplatesState,
  GondolaTemplatesGetters,
  GondolaTemplatesActions
>;

export type GondolaTemplatesStore = Store<
  'gondolaTemplates',
  GondolaTemplatesState,
  GondolaTemplatesGetters,
  GondolaTemplatesActions
>;

export const useGondolaTemplatesStore: GondolaTemplatesStoreDefinition = defineStore('gondolaTemplates', {
  state: (): GondolaTemplatesState => ({
    gondolaTemplates: new Array<GondolaTemplate>(),
    isFetching: false,
    fetched: false,
    deviceContentSelection: {
      isForeground: false,
      index: -1,
      offsetX: 0,
      offsetY: 0,
    },
    gondolaTemplatesZoom: {},
    searchResults: {
      results: new Array<GondolaTemplate>(),
      totalCount: 0,
    },
    isSearching: false,
    currentPage: 0,
    searchFilters: {},
    loadingIndicator: {
      isCanvasLoading: false,
      update: false,
    },
  }),
  getters: {
    getById: (state: GondolaTemplatesState) => (id: string) => {
      return state.gondolaTemplates.find((gondolaTemplate: GondolaTemplateModel) => gondolaTemplate._id == id);
    },
    findName: (state: GondolaTemplatesState) => (nameQuery: string) => {
      return state.gondolaTemplates.find(
        (gondolaTemplate: GondolaTemplateModel) =>
          gondolaTemplate.name && gondolaTemplate.name.toUpperCase() === nameQuery.toUpperCase()
      );
    },
    findLikeName: (state: GondolaTemplatesState) => (nameQuery: string) => {
      return state.gondolaTemplates.filter(
        (gondolaTemplate: GondolaTemplateModel) => gondolaTemplate.name && gondolaTemplate.name.includes(nameQuery)
      );
    },
    getZoomLevelById: (state: GondolaTemplatesState) => (id: string) => {
      return state.gondolaTemplatesZoom[id] || 100;
    },
    getTemplates: (state: GondolaTemplatesState) => () => {
      return state.gondolaTemplates;
    },
  },
  actions: {
    async fetch(): Promise<void> {
      if (!this.isFetching) {
        this.isFetching = true;
        const response: AxiosResponse<Array<GondolaTemplateWithIdJSON>> = await axios.get(
          `${Config.getApiUrl()}/gondola-templates`
        );
        if (response.status === 200) {
          const gondolaTemplates: Array<GondolaTemplateModel> = response.data.map(
            (gondolaTemplateObject: GondolaTemplateWithIdJSON) => GondolaTemplateModel.fromJSON(gondolaTemplateObject)
          );
          this.updateAll(gondolaTemplates);
          this.isFetching = false;
          this.fetched = true;
        } else {
          console.error(response);
        }
      }
    },
    updateAll(gondolaTemplates: Array<GondolaTemplateModel>) {
      Vue.set(this, 'gondolaTemplates', gondolaTemplates);
      // When updating all the gondola templates, filter the obsolete gondola templates zoom from the store and local storage
      const newGondolaTemplatesZoom: GondolaTemplateZoomCache = {};
      for (const gondolaTemplatesKey of gondolaTemplates) {
        if (gondolaTemplatesKey._id && this.gondolaTemplatesZoom[gondolaTemplatesKey._id]) {
          newGondolaTemplatesZoom[gondolaTemplatesKey._id] = this.gondolaTemplatesZoom[gondolaTemplatesKey._id];
        }
      }
      Vue.set(this, 'gondolaTemplatesZoom', newGondolaTemplatesZoom);
      localStorage.setItem('gondolaTemplatesZoom', JSON.stringify(newGondolaTemplatesZoom));
    },
    async create(gondolaTemplate: NewGondolaTemplateJSON) {
      this.loadingIndicator.update = true;
      const response: AxiosResponse<GondolaTemplateWithIdJSON> = await axios.post(
        `${Config.getApiUrl()}/gondola-templates`,
        gondolaTemplate
      );
      if (response.status === 200) {
        const createdGondolaTemplate: GondolaTemplateModel = GondolaTemplateModel.fromJSON(response.data);
        this.gondolaTemplates.push(createdGondolaTemplate);
        this.loadingIndicator.update = false;
        return createdGondolaTemplate._id;
      } else {
        console.error(response);
        this.loadingIndicator.update = false;
      }
    },
    async clone(gondolaTemplateId: string, newName: string, selectedTags: TagReference[]): Promise<string | undefined> {
      const response: AxiosResponse<GondolaTemplateWithIdJSON> = await axios.post(
        `${Config.getApiUrl()}/gondola-templates/clone/${gondolaTemplateId}`,
        {
          newName: newName,
          selectedTags: selectedTags,
        }
      );
      if (response.status === 200) {
        const createdGondolaTemplate: GondolaTemplateModel = GondolaTemplateModel.fromJSON(response.data);
        this.gondolaTemplates.push(createdGondolaTemplate);
        return createdGondolaTemplate._id;
      } else {
        console.error(response);
      }
    },
    async updateGondolaTemplate(gondolaTemplate: GondolaTemplate): Promise<void> {
      if (!gondolaTemplate._id) {
        return;
      }
      const response: AxiosResponse<GondolaTemplateWithIdJSON> = await axios.post(
        `${Config.getApiUrl()}/gondola-templates/${gondolaTemplate._id}`,
        gondolaTemplate,
        {
          params: {
            hash: this.getById(gondolaTemplate._id)?.hash,
          },
        }
      );
      if (response.status === 200) {
        const gondolaTemplateToUpdate: Optional<GondolaTemplate> = this.getById(gondolaTemplate._id);
        if (gondolaTemplateToUpdate) {
          gondolaTemplateToUpdate.name = gondolaTemplate.name;
          gondolaTemplateToUpdate.tags = gondolaTemplate.tags;
          gondolaTemplateToUpdate.railGrid = gondolaTemplate.clone().railGrid;
          gondolaTemplateToUpdate.hash = response.data.hash;
        }
        this.searchResults.results.forEach((gondolaTemplateResult: GondolaTemplateModel) => {
          if (gondolaTemplateResult._id === gondolaTemplate._id) {
            gondolaTemplateResult.name = gondolaTemplate.name;
            gondolaTemplateResult.tags = gondolaTemplate.tags;
            gondolaTemplateResult.railGrid = gondolaTemplate.railGrid;
            gondolaTemplateResult.hash = response.data.hash;
          }
        });
      } else {
        console.error(response);
      }
    },
    updateDeviceContentSelection(
      data: DeviceContentSelection & {
        selectedSchedule?: string;
      }
    ) {
      this.deviceContentSelection = data;
    },
    async deleteDevice(gondolaTemplateId: string, row: number, col: number) {
      const gondolaTemplate: Optional<GondolaTemplate> = this.getById(gondolaTemplateId);
      if (!gondolaTemplate) {
        return;
      }
      const response: AxiosResponse<{ hash: string }> = await axios.delete(
        `${Config.getApiUrl()}/gondola-templates/${gondolaTemplateId}/device/${row}/${col}`,
        {
          params: {
            hash: gondolaTemplate.hash,
          },
        }
      );
      if (response.status === 200) {
        gondolaTemplate.railGrid[row].splice(col, 1);
        gondolaTemplate.hash = response.data.hash;

        if (gondolaTemplate.railGrid[row].length === 0) {
          gondolaTemplate.railGrid.splice(row, 1);
        }
      } else {
        console.error(response);
      }
    },
    async delete(gondolaTemplateId: string) {
      const gondolaTemplate: Optional<GondolaTemplate> = this.getById(gondolaTemplateId);
      if (!gondolaTemplate) {
        return;
      }
      const schedulesStore: SchedulesStore = useSchedulesStore();
      const response: AxiosResponse<{
        gondolaTemplate: GondolaTemplateWithIdJSON;
        deletedSchedules: Array<ScheduleModelJSON>;
      }> = await axios.delete(`${Config.getApiUrl()}/gondola-templates/${gondolaTemplateId}`, {
        params: {
          hash: gondolaTemplate.hash,
        },
      });
      if (response.status === 200) {
        schedulesStore.deleteAll(
          response.data.deletedSchedules.map((schedule: ScheduleModelJSON) => ScheduleModel.fromJSON(schedule))
        );
        const gondolaTemplateIndex: number = this.gondolaTemplates.findIndex(
          (gondolaTemplate: GondolaTemplateModel) => gondolaTemplate._id === gondolaTemplateId
        );
        if (gondolaTemplateIndex >= 0) {
          this.gondolaTemplates.splice(gondolaTemplateIndex, 1);
          this.searchResults.totalCount -= 1;
        }
        const searchItemIndex: number = this.searchResults.results.findIndex(
          (gondolaTemplate: GondolaTemplateModel) => gondolaTemplate._id === gondolaTemplateId
        );
        if (searchItemIndex >= 0) {
          this.searchResults.results.splice(searchItemIndex, 1);
        }
      } else {
        console.error(response);
      }
    },
    async search() {
      if (this.isSearching) {
        return;
      }
      this.isSearching = true;
      const response: AxiosResponse<{
        gondolaTemplates: Array<GondolaTemplateWithIdJSON>;
        count: number;
        page: number;
        pageSize: number;
      }> = await axios.get(`${Config.getApiUrl()}/gondola-templates/search`, {
        params: {
          startDate: this.searchFilters.startDate,
          endDate: this.searchFilters.endDate,
          searchTerm: this.searchFilters.searchTerm,
          searchTags: this.searchFilters.searchTags,
          sortOrder: this.searchFilters.sortOrder,
          orderBy: this.searchFilters.orderBy,
          page: this.currentPage,
          pageSize: 10,
        },
      });
      if (response.status === 200) {
        const gondolaTemplates: Array<GondolaTemplateModel> = response.data.gondolaTemplates.map(
          (gondolaTemplateObject: GondolaTemplateWithIdJSON) => GondolaTemplateModel.fromJSON(gondolaTemplateObject)
        );
        this.searchResults.results = this.searchResults.results.concat(gondolaTemplates);
        this.searchResults.totalCount = response.data.count;
        this.currentPage += 1;
      } else {
        console.error(response);
      }
      this.isSearching = false;
    },
    clearSearchResults() {
      this.currentPage = 0;
      this.searchResults = {
        results: new Array<GondolaTemplate>(),
        totalCount: 0,
      };
    },
    setSearchFilters(
      sortOrder?: string,
      orderBy?: string,
      searchTerm?: string,
      startDate?: string,
      endDate?: string,
      searchTags?: string[]
    ) {
      this.searchFilters = {
        startDate,
        endDate,
        sortOrder,
        orderBy,
        searchTerm,
        searchTags,
      };
    },
    clearSearchFilters() {
      this.searchFilters = {};
    },
    clear() {
      Vue.set(this, 'gondolaTemplates', new Array<GondolaTemplateModel>());
    },
    update(gondolaTemplateJSON: GondolaTemplateWithIdJSON) {
      const index: number = this.gondolaTemplates.findIndex(
        (gondola: GondolaTemplateModel) => gondola._id === gondolaTemplateJSON._id
      );
      if (index >= 0) {
        const updatedTemplate: GondolaTemplateModel = GondolaTemplateModel.fromJSON(gondolaTemplateJSON);
        // For some reason, assigning updated template directly to the template in the array, generates a bug where we can't update the content anymore
        // The vue watcher won't detect any changes in the object after that!!
        this.gondolaTemplates[index].railGrid = updatedTemplate.railGrid;
        this.gondolaTemplates[index].layoutHash = updatedTemplate.layoutHash;
        this.gondolaTemplates[index].hash = updatedTemplate.hash;
        this.gondolaTemplates[index].name = updatedTemplate.name;
      }
    },
    updateGondolaZoomLevelById(gondolaTemplateId: string, zoomLevel: number) {
      Vue.set(this.gondolaTemplatesZoom, gondolaTemplateId, zoomLevel);
      localStorage.setItem('gondolaTemplatesZoom', JSON.stringify(this.gondolaTemplatesZoom));
    },
    loadGondolaZoomFromLocalStorage() {
      const localStorageZoomData: string | null = localStorage.getItem('gondolaTemplatesZoom');
      const cachedGondolaZoom: GondolaTemplateZoomCache = localStorageZoomData ? JSON.parse(localStorageZoomData) : {};
      Vue.set(this, 'gondolaTemplatesZoom', cachedGondolaZoom);
    },
    setIsCanvasLoading(status: boolean) {
      this.loadingIndicator.isCanvasLoading = status;
    },
    setIsUpdating(status: boolean) {
      this.loadingIndicator.update = status;
    },
  },
});
