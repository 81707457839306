
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DetectedObjectEntry, DeviceSensorObjects } from '@common/apim/definitions';
import { secondsToHHMMSS } from '@client/components/SensorAnalytics/charts/utils';

@Component({
  methods: {
    secondsToHHMMSS,
  },
})
export default class Overview extends Vue {
  /* DECLARATIONS */
  @Prop()
  private sensorDataObjects!: DeviceSensorObjects | null;
  private isDataLoaded: boolean = false;

  private data: DeviceSensorObjects | null = null;
  /* LIFECYCLE EVENTS */
  @Watch('sensorDataObjects')
  onSensorDataChange(): void {
    this.data = this.sensorDataObjects;
    this.isDataLoaded = true;
  }

  /* METHODS */
  /* GETTERS */
  get totalDetectedObjects(): number {
    if (!this.data) {
      return 0;
    }
    return this.data.detectedObjects.length;
  }

  get totalEngageTime(): number {
    if (!this.data) {
      return 0;
    }
    return { ...this.data }.detectedObjects.reduce((acc: number, detectedObject: DetectedObjectEntry) => {
      return acc + detectedObject.detectedObject.decisionTime + detectedObject.detectedObject.dwellTime;
    }, 0);
  }

  get averageInteractionTime(): number {
    if (!this.data || this.totalDetectedObjects === 0 || this.totalEngageTime === 0) {
      return 0;
    }
    return this.totalEngageTime / this.totalDetectedObjects;
  }
}
