
import { Component, Prop, Vue } from 'vue-property-decorator';
import ScheduleModel from '@client/models/ScheduleModels/Schedule.model';
import { dateToMomentDate, dateToMomentTime, getShortDayName } from '@client/utils/DateTimeUtils';

@Component({
  methods: {
    getShortDayName,
    dateToMomentDate,
    dateToMomentTime,
  },
})
export default class ScheduleDetails extends Vue {
  @Prop()
  private selectedSchedule!: ScheduleModel;
}
