
import { Component, Prop, Vue } from 'vue-property-decorator';
import { downloadQRCodeAsPNG, downloadQRCodeAsSVG, getQRCodeFileName } from '@client/components/QRCode/utils';
import { Store } from '@client/models';

@Component({
  methods: { getQRCodeFileName, downloadQRCodeAsPNG, downloadQRCodeAsSVG },
})
export default class QRCodeDownloadButton extends Vue {
  /* DECLARATIONS */
  @Prop()
  private store!: Store;

  @Prop({ default: false, type: Boolean })
  private onlyIcon!: boolean;

  /* LIFECYCLE EVENTS */

  /* METHODS */

  /* GETTERS */
}
