import { Canvas, Image } from 'fabric/fabric-impl';
import { BaseLayer } from '@client/models/ScheduleModels';
import { DeviceTemplate } from '@client/models/DeviceModels';
import ScheduleLayerModel from '@client/models/ScheduleModels/ScheduleLayer.model';
import { ContentType } from '@common/enums';
import { createImage, createVideoImage } from '@client/utils/CanvasUtils';

export const OPTIMIZED_PREVIEW_POSTFIX: string = '-webp';

/**
 * This is a rendered Component inside a RailCanvas.
 * It can either be a Foreground (Label) or Background Item.
 * It wraps a given ContentItem and handles all steps necessary to render the ContentItem's content inside
 * a fabric.js canvas (video or image).
 */
export default abstract class DeviceCanvasItem {
  protected baseLayerModel: BaseLayer;
  protected readonly canvas: Canvas;
  protected readonly deviceTemplate: DeviceTemplate;
  protected renderedImage!: Image;
  protected readonly index: number;
  protected readonly row: number;
  protected readonly col: number;
  protected readonly gondolaTemplateId: string;

  constructor(
    canvas: Canvas,
    deviceTemplate: DeviceTemplate,
    baseLayerModel: BaseLayer | ScheduleLayerModel,
    row: number,
    col: number,
    gondolaTemplateId: string,
    index?: number
  ) {
    this.baseLayerModel = baseLayerModel;
    this.canvas = canvas;
    this.deviceTemplate = deviceTemplate;
    this.col = col;
    this.row = row;
    this.gondolaTemplateId = gondolaTemplateId;
    this.index = index != null ? index : -1;
  }

  /**
   * This handles the actual rendering inside the fabric.js canvas and sets all necessary properties and events
   */
  protected abstract setupImage(
    image: Image,
    isSelected?: boolean,
    isHidden?: boolean,
    color?: string,
    openEditContentDialog?: () => void
  ): void;

  /**
   * Initial render or rerender of the item.
   * Also handles the changing of a ContentItem
   *
   */
  public async render(
    isSelected?: boolean,
    newContentItem?: BaseLayer,
    isHidden?: boolean,
    color?: string,
    openEditContentDialog?: () => void
  ): Promise<void> {
    if (newContentItem) {
      this.baseLayerModel = newContentItem;
    }
    const newContentUrl: string = await this.getBlobUrl();
    let newImage: Image;
    if (this.baseLayerModel.type === ContentType.Image) {
      newImage = await createImage(newContentUrl);
    } else {
      // This is a video or a playlist
      const video: Image = createVideoImage(newContentUrl, this.getWidth(), this.getHeight());
      await (video.getElement() as HTMLVideoElement).play();
      newImage = video;
    }
    this.setupImage(newImage, isSelected, isHidden, color, openEditContentDialog);
    this.canvas.add(newImage);
    if (this.renderedImage) {
      this.canvas.remove(this.renderedImage);
    }
    this.renderedImage = newImage;
  }

  public getWidth(): number {
    return this.baseLayerModel.type === ContentType.Playlist
      ? this.baseLayerModel.playlist?.[0]?.width || 0
      : this.baseLayerModel.width;
  }

  public getHeight(): number {
    return this.baseLayerModel.type === ContentType.Playlist
      ? this.baseLayerModel.playlist?.[0]?.height || 0
      : this.baseLayerModel.height;
  }
  public async getBlobUrl(): Promise<string> {
    return this.baseLayerModel.getBlobUrl();
  }
}
