import { render, staticRenderFns } from "./TagDialog.vue?vue&type=template&id=104c2bb1&scoped=true"
import script from "./TagDialog.vue?vue&type=script&lang=ts"
export * from "./TagDialog.vue?vue&type=script&lang=ts"
import style0 from "./TagDialog.vue?vue&type=style&index=0&id=104c2bb1&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "104c2bb1",
  null
  
)

export default component.exports