
import { Component, Prop, Vue } from 'vue-property-decorator';
import ModalHeader from '@client/components/Layouts/ModalHeader.vue';

@Component({
  components: { ModalHeader },
})
export default class ConfirmLeaveModal extends Vue {
  @Prop({ type: Boolean, default: false })
  private isVisible!: boolean;
  @Prop({ type: Boolean, default: false })
  private isValid!: boolean;

  closeModal() {
    this.$emit('close');
  }
  onConfirm() {
    this.$emit('confirm');
  }
  onCancel() {
    this.$emit('cancel');
  }
}
