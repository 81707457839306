import { defineStore, Store, StoreDefinition } from 'pinia';
import axios, { AxiosResponse } from 'axios';
import Config from '@client/utils/config';
import { GondolaTemplateWithIdJSON } from '@common/gondola-template/types';
import { Optional } from '@common/types';
import ScheduleModel from '@client/models/ScheduleModels/Schedule.model';
import {
  AddOrUpdateScheduleResponse,
  DeleteScheduleJSONResponse,
  DeleteScheduleResult,
  GetAllSchedulesResponse,
  SchedulesResponse,
} from '@client/stores/schedules/types';
import { ScheduleModelJSON } from '@common/schedule/types';
import Vue from 'vue';
import { Spacetime } from 'spacetime/types/types';
import spacetime from 'spacetime';
import ScheduleSpanModel from '@client/models/ScheduleModels/ScheduleSpan.model';
import Moment from 'moment/moment';
import { ScheduleDay } from '@common/enums';
import { GondolaTemplate } from '@client/models';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';

export interface SchedulesGetters {
  getSchedules: (state: SchedulesState) => () => Array<ScheduleModel>;
  getSchedulesForTemplate: (state: SchedulesState) => (gondolaTemplateId: string) => Array<ScheduleModel>;
  getCurrentlyDisplayedScheduleForTemplate: (
    state: SchedulesState
  ) => (gondolaTemplateId: string, storeTimezone: string) => string;
  getSelectedSchedule: (state: SchedulesState) => () => string;
  getById: (state: SchedulesState) => (id: string) => Optional<ScheduleModel>;
  getSelectedTimespanCalendarDateRange: (state: SchedulesState) => () => Array<string>;
}

export interface SchedulesActions {
  fetch(): Promise<void>;
  editSchedule(schedule: ScheduleModel, hash?: string, force?: boolean): Promise<Optional<SchedulesResponse>>;
  addSchedule(schedule: ScheduleModelJSON | ScheduleModel, force?: boolean): Promise<Optional<SchedulesResponse>>;
  setScheduleActive(scheduleId: string, hash?: string, force?: boolean): Promise<Optional<SchedulesResponse>>;
  deleteSchedule(
    scheduleId: string,
    gondolaTemplateId: string,
    force: boolean,
    hash?: string
  ): Promise<Optional<DeleteScheduleResult>>;
  setSchedulesInactive(schedules: ScheduleModel[]): void;
  deleteAll(schedulesToDelete: Array<ScheduleModel>): void;
  updateSelectedSchedule(scheduleId: string): void;
  clearSelectedSchedule(): void;
  updateSelectedTimespanCalendarDateRange(dateRange: Array<string>): void;
  add(newSchedule: ScheduleModel): void;
  edit(updatedSchedule: ScheduleModel): void;
}

export interface SchedulesState {
  schedules: Array<ScheduleModel>;
  selectedSchedule: string;
  isUpdatingActiveStatus: boolean;
  selectedTimespanCalendarDateRange: Array<string>;
}

export type SchedulesStoreDefinition = StoreDefinition<'schedules', SchedulesState, SchedulesGetters, SchedulesActions>;

export type SchedulesStore = Store<'schedules', SchedulesState, SchedulesGetters, SchedulesActions>;

export const useSchedulesStore: SchedulesStoreDefinition = defineStore('schedules', {
  state: (): SchedulesState => ({
    schedules: new Array<ScheduleModel>(),
    selectedSchedule: '',
    isUpdatingActiveStatus: false,
    selectedTimespanCalendarDateRange: [],
  }),
  getters: {
    getSchedules: (state: SchedulesState) => () => {
      return state.schedules;
    },
    getSchedulesForTemplate: (state: SchedulesState) => (gondolaTemplateId: string) => {
      if (!gondolaTemplateId) {
        return [];
      }
      return state.schedules.filter((s: ScheduleModel) => s.gondolaTemplates.includes(gondolaTemplateId));
    },
    getCurrentlyDisplayedScheduleForTemplate:
      (state: SchedulesState) => (gondolaTemplateId: string, storeTimezone: string) => {
        // Get all active schedules related to the gondola template
        const gondolaSchedules: ScheduleModel[] = state.schedules.filter(
          (s: ScheduleModel) => s.gondolaTemplates.includes(gondolaTemplateId) && s.active
        );
        if (!gondolaSchedules.length) {
          return '';
        }
        // Get the current time of the store using its timezone
        const currentTime: Spacetime = spacetime.now(storeTimezone);
        const currentTimeInMinutes: number = currentTime.hour() * 60 + currentTime.minute();
        // Try to find an active schedule that's active in this time frame
        const activeSchedule: ScheduleModel | undefined = gondolaSchedules.find((schedule: ScheduleModel) =>
          schedule.spans.find((span: ScheduleSpanModel) => {
            const startTime: Moment.Moment = Moment(span.recurrenceStart, 'HH:mm');
            const endTime: Moment.Moment = Moment(span.recurrenceEnd, 'HH:mm');
            return (
              // Set the same time for the compared dates so it doesn't conflict with time range
              currentTime.time('12:00pm').isBetween(
                spacetime(span.validityFrom).goto(storeTimezone).time('12:00pm'),
                spacetime(span.validityTo).goto(storeTimezone).time('12:00pm'),
                true // inclusive set to true: Will return true if one of the dates is exactly the same
              ) &&
              // Check if the current time is between the span validity period
              currentTimeInMinutes >= startTime.hour() * 60 + startTime.minutes() &&
              currentTimeInMinutes <= endTime.hour() * 60 + endTime.minutes() &&
              // Check if the current day exists in the schedule recurrence days
              span.recurrenceDays.includes(
                ScheduleDay[currentTime.format('day').toLocaleUpperCase() as keyof typeof ScheduleDay]
              )
            );
          })
        );
        return activeSchedule?._id || '';
      },
    getSelectedSchedule: (state: SchedulesState) => () => {
      return state.selectedSchedule;
    },
    getById: (state: SchedulesState) => (id: string) => {
      return state.schedules.find((schedule: ScheduleModel) => schedule._id == id);
    },
    getSelectedTimespanCalendarDateRange: (state: SchedulesState) => () => {
      return state.selectedTimespanCalendarDateRange;
    },
  },
  actions: {
    async fetch(): Promise<void> {
      const response: AxiosResponse<GetAllSchedulesResponse> = await axios.get(`${Config.getApiUrl()}/schedules`);
      const schedules: Array<ScheduleModel> = response.data.schedules.map((value: ScheduleModelJSON) => {
        return ScheduleModel.fromJSON(value);
      });
      Vue.set(this, 'schedules', schedules);
    },
    async addSchedule(
      schedule: ScheduleModelJSON | ScheduleModel,
      force?: boolean
    ): Promise<Optional<SchedulesResponse>> {
      const response: AxiosResponse<AddOrUpdateScheduleResponse> = await axios.post(
        `${Config.getApiUrl()}/schedules`,
        schedule,
        {
          params: {
            force: !!force,
          },
        }
      );
      if (response.status === 200) {
        return {
          schedule: ScheduleModel.fromJSON(response.data.schedule),
          conflicts: response.data.conflicts.map((conflict: ScheduleModelJSON) => ScheduleModel.fromJSON(conflict)),
        };
      } else {
        console.error(response);
      }
    },
    async editSchedule(schedule: ScheduleModel, hash?: string, force?: boolean): Promise<Optional<SchedulesResponse>> {
      const response: AxiosResponse<AddOrUpdateScheduleResponse> = await axios.post(
        `${Config.getApiUrl()}/schedules/${schedule._id}`,
        schedule,
        {
          params: {
            force: !!force,
            hash,
          },
        }
      );
      if (response.status === 200) {
        return {
          schedule: ScheduleModel.fromJSON(response.data.schedule),
          conflicts: response.data.conflicts.map((conflict: ScheduleModelJSON) => ScheduleModel.fromJSON(conflict)),
        };
      } else {
        console.error(response);
      }
    },
    async setScheduleActive(scheduleId: string, hash?: string, force?: boolean): Promise<Optional<SchedulesResponse>> {
      this.isUpdatingActiveStatus = true;
      const response: AxiosResponse<AddOrUpdateScheduleResponse> = await axios.put(
        `${Config.getApiUrl()}/schedules/${scheduleId}/toggleactive`,
        undefined,
        {
          params: {
            force: !!force,
            hash,
          },
        }
      );
      if (response.status === 200) {
        this.isUpdatingActiveStatus = false;
        return {
          schedule: ScheduleModel.fromJSON(response.data.schedule),
          conflicts: response.data.conflicts.map((conflict: ScheduleModelJSON) => ScheduleModel.fromJSON(conflict)),
        };
      } else {
        console.error(response);
      }
      this.isUpdatingActiveStatus = false;
    },
    async deleteSchedule(
      scheduleId: string,
      gondolaTemplateId: string,
      force: boolean,
      hash?: string
    ): Promise<Optional<DeleteScheduleResult>> {
      const gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
      const response: AxiosResponse<DeleteScheduleJSONResponse> = await axios.delete(
        `${Config.getApiUrl()}/schedules/${scheduleId}?gondolaTemplateId=${gondolaTemplateId}`,
        {
          params: {
            force,
            hash,
          },
        }
      );
      if (response.status === 200) {
        this.selectedSchedule = '';
        if (response.data.schedule) {
          if (response.data.deleted) {
            this.deleteAll([ScheduleModel.fromJSON(response.data.schedule)]);
          } else {
            const schedule: ScheduleModel = ScheduleModel.fromJSON(response.data.schedule);
            const index: number = this.schedules.findIndex(
              (_schedule: ScheduleModel) => _schedule._id === schedule._id
            );
            this.schedules[index] = schedule;
          }
        }

        response.data.gondolaTemplates.forEach((gondolaTemplateJson: GondolaTemplateWithIdJSON) => {
          gondolaTemplatesStore.update(gondolaTemplateJson);
        });
        return {
          schedule: ScheduleModel.fromJSON(response.data.schedule),
          gondolaTemplates: response.data.gondolaTemplates.map((gondolaTemplate: GondolaTemplateWithIdJSON) =>
            GondolaTemplate.fromJSON(gondolaTemplate)
          ),
        };
      } else {
        console.error(response);
      }
    },
    setSchedulesInactive(schedules: ScheduleModel[]): void {
      schedules.forEach((schedule: ScheduleModel) => {
        this.edit(schedule);
      });
    },
    deleteAll(schedulesToDelete: Array<ScheduleModel>): void {
      schedulesToDelete.forEach((schedule: ScheduleModel) => {
        if (this.selectedSchedule === schedule._id) {
          this.selectedSchedule = '';
        }
        this.schedules.splice(
          this.schedules.findIndex((_schedule: ScheduleModel) => _schedule._id === schedule._id),
          1
        );
      });
    },
    updateSelectedSchedule(scheduleId: string): void {
      this.selectedSchedule = scheduleId;
    },
    clearSelectedSchedule(): void {
      this.selectedSchedule = '';
    },
    updateSelectedTimespanCalendarDateRange(dateRange: Array<string>) {
      this.selectedTimespanCalendarDateRange = dateRange;
    },
    edit(updatedSchedule: ScheduleModel): void {
      this.selectedSchedule = updatedSchedule._id || '';
      const index: number = this.schedules.findIndex(
        (_schedule: ScheduleModel) => _schedule._id === updatedSchedule._id
      );
      this.schedules.splice(index, 1, updatedSchedule);
    },
    add(newSchedule: ScheduleModel): void {
      this.selectedSchedule = newSchedule._id || '';
      this.schedules.push(newSchedule);
    },
  },
});
