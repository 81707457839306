
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import videoDurationParser from '@client/utils/videoDurationParser';
import BaseLayerModel from '@client/models/ScheduleModels/BaseLayer.model';
import ScheduleLayerModel from '@client/models/ScheduleModels/ScheduleLayer.model';
import LayerPlaylistItem from '@client/models/ScheduleModels/LayerPlaylistItem.model';
import { ContentType } from '@common/enums';
import { ContentItemsStore, useContentItemsStore } from '@client/stores/contentItems';
import { getFPSDisplayValue } from '@client/components/ContentItems/utils';

@Component({
  components: {},
  methods: {
    videoDurationParser,
    getFPSDisplayValue,
  },
})
export default class ContentPreview extends Vue {
  @Prop()
  private selectedLayer!: ScheduleLayerModel | BaseLayerModel;

  private contentItemsStore: ContentItemsStore = useContentItemsStore();

  private previewUrl: string = '';

  private playlistItemPreviewUrl: string = '';
  private playlistItemSelected: LayerPlaylistItem | null = null;
  private selectedContentTitle: string = '';
  private showPlaylistItemPreview: boolean = false;

  created(): void {
    this.onSelectedLayerChanged();
  }

  @Watch('selectedLayer')
  async onSelectedLayerChanged(): Promise<void> {
    this.previewUrl = await this.selectedLayer.getPreviewBlobUrl();
  }

  async openPreviewVideo(layerPlaylistItem: LayerPlaylistItem): Promise<void> {
    this.playlistItemSelected = layerPlaylistItem;
    this.playlistItemPreviewUrl = await layerPlaylistItem.getPreviewBlobUrl();
    this.selectedContentTitle = this.getContentItemOriginalName(layerPlaylistItem.name);
    this.showPlaylistItemPreview = true;
  }

  closePreviewVideo(): void {
    this.playlistItemPreviewUrl = '';
    this.selectedContentTitle = '';
    this.showPlaylistItemPreview = false;
  }

  getContentItemOriginalName(contentItemAzureBlobName: string): string {
    return this.contentItemsStore.getOriginalNameByAzureBlobName(contentItemAzureBlobName);
  }

  get isSelectedLayerAPlaylist(): boolean {
    return this.selectedLayer.type === ContentType.Playlist;
  }

  get isSelectedLayerAnImage(): boolean {
    return (
      this.selectedLayer.type === ContentType.Image ||
      !!(this.selectedLayer.type === ContentType.Video && this.selectedLayer.previewUrl)
    );
  }

  get isSelectedPlaylistItemAnImage(): boolean {
    return (
      this.playlistItemSelected?.type === ContentType.Image ||
      !!(this.playlistItemSelected?.type === ContentType.Video && this.playlistItemSelected?.previewUrl)
    );
  }
}
