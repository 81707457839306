
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Device, DevicePublishing, GondolaPublishing } from '@client/models';
import Preview from '../Preview/Preview.vue';
import { TranslateResult } from 'vue-i18n';
import spacetime from 'spacetime';
import { OnlineStatus } from '@common/enums';
import ClientPublishState from '@client/enums/ClientPublishState';
import DevicePublishingForegroundContentModel from '@client/models/DeviceModels/DevicePublishingForegroundContentModel';
import DevicePublishingBackgroundContentModel from '@client/models/DeviceModels/DevicePublishingBackgroundContentModel';
import PublishingNextRetryCountdown from '@client/components/NotificationPanel/Notifications/PublishingNextRetryCountdown.vue';
import DeviceOptionsMenu from '@client/components/Gondola/DeviceOptionsMenu.vue';
import Moment from 'moment';
import { Spacetime } from 'spacetime/types/types';

@Component({
  components: {
    DeviceOptionsMenu,
    PublishingNextRetryCountdown,
    Preview,
  },
})
export default class GondolaDevice extends Vue {
  @Prop()
  private device!: Device;
  @Prop()
  private row!: number;
  @Prop()
  private col!: number;
  @Prop()
  private lastPublishing?: GondolaPublishing;
  @Prop()
  private selectedSchedule?: string;
  @Prop()
  private storeTimezone!: string;

  get showOnlineStatus(): boolean {
    return !!this.device.onlineStatus;
  }

  get showRailGroupIcon(): boolean {
    return (this.devicePublishing?.railGroup?.color && !this.devicePublishing.railGroup.isUnlinked) || false;
  }

  get isPublishingValid(): boolean {
    return !!this.devicePublishing && this.devicePublishing.longId === this.device.longId;
  }

  get hasValidContent(): boolean {
    return this.isPublishingValid && (!!this.getBackgroundContentItem || !!this.getForegroundContentItems.length);
  }

  get isPublishingPending(): boolean | undefined {
    return (
      this.devicePublishing?.publishInformation.state !== ClientPublishState.PUBLISHED &&
      this.devicePublishing?.publishInformation.state !== ClientPublishState.DIMENSION_MISMATCH &&
      this.devicePublishing?.publishInformation.state !== ClientPublishState.ACCEPTED &&
      this.devicePublishing?.publishInformation.state !== ClientPublishState.EXECUTING
    );
  }

  get isPublishingAccepted(): boolean | undefined {
    return this.devicePublishing?.publishInformation.state === ClientPublishState.ACCEPTED;
  }

  get isPublishingFailed(): boolean | undefined {
    return (
      this.devicePublishing?.publishInformation.state === ClientPublishState.PUBLISHING_DENIED ||
      this.devicePublishing?.publishInformation.state === ClientPublishState.PUBLISHING_FAILED
    );
  }

  get publishingFailedMessage(): string | undefined {
    return this.devicePublishing?.publishInformation?.message;
  }

  get onlineStatusClass(): string {
    return this.device.onlineStatus === OnlineStatus.ONLINE ? 'success' : 'error';
  }

  get formatOnlineStatus(): TranslateResult {
    if (!this.device.onlineStatus) {
      return '';
    }
    const dateWithTimezone: Spacetime = spacetime(this.device.onlineStatusLastChanged || new Date()).goto(
      this.storeTimezone
    );
    return this.$t(this.$i18nTranslationKeys.device.status[this.device.onlineStatus].$path, {
      date: Moment(dateWithTimezone.iso()).locale(this.$i18n.locale).format('llll'),
    });
  }

  get getSize(): string {
    return `device-${this.device.hardwareModel.identifier}`;
  }

  get getContainerClass(): string {
    if (!this.device.shortId) {
      return `${this.getSize} mr-2`;
    }
    return `${this.getSize} device-info-margin`;
  }

  get getRailClass(): string {
    if (!this.device.shortId) {
      return 'device__badges';
    }
    return 'device__badges device__gondola_badges';
  }

  get getRailGroupColor(): string | undefined {
    return this.devicePublishing?.railGroup.color;
  }

  get getBackgroundContentItem(): DevicePublishingBackgroundContentModel | undefined {
    return this.devicePublishing?.publishInformation.state === ClientPublishState.PUBLISHED ||
      this.devicePublishing?.publishInformation.state === ClientPublishState.EXECUTING
      ? this.devicePublishing.backgroundContent[0]
      : undefined;
  }

  get getForegroundContentItems(): Array<DevicePublishingForegroundContentModel> {
    return this.devicePublishing?.publishInformation.state === ClientPublishState.PUBLISHED ||
      this.devicePublishing?.publishInformation.state === ClientPublishState.EXECUTING
      ? this.devicePublishing.foregroundContent
      : new Array<DevicePublishingForegroundContentModel>();
  }

  get isLastAttemptReached(): boolean {
    return (this.lastPublishing?.previousAttempts?.length || 0) > 5;
  }

  get isNextRunValueAvailable(): boolean {
    return !!this.lastPublishing && !!this.lastPublishing.nextRunAt && !this.isLastAttemptReached;
  }

  get showSensorBadge(): boolean {
    return !!this.device.sensor;
  }

  get isExecuting(): boolean {
    return this.devicePublishing?.publishInformation.state === ClientPublishState.EXECUTING || false;
  }

  get devicePublishing(): DevicePublishing | undefined {
    if (this.lastPublishing && this.lastPublishing.railGrid?.[this.row]) {
      return this.lastPublishing?.railGrid?.[this.row][this.col];
    }
    return undefined;
  }
}
