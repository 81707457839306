
import { Component, Prop, Vue } from 'vue-property-decorator';
import AffectedStoresModal from '@client/components/Settings/ReleaseManagement/AffectedStoresModal.vue';
import ReleaseManagement from '@client/models/SettingsModels/ReleaseManagement.model';

@Component({
  components: { AffectedStoresModal },
})
export default class AffectedStoresNumberButton extends Vue {
  @Prop()
  private releaseManagement!: ReleaseManagement;

  private showDialog: boolean = false;

  closeDialog(): void {
    this.showDialog = false;
  }

  get isDisabled(): boolean {
    return this.releaseManagement.appliedStores.length === 0;
  }
}
