
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  DeviceContentSelection,
  GondolaTemplatesStore,
  useGondolaTemplatesStore,
} from '@client/stores/gondolaTemplates';
import { BaseLayer, DeviceTemplate, ScheduledContent, ScheduleLayer } from '@client/models';
import { SchedulesStore, useSchedulesStore } from '@client/stores/schedules';
import LabelItem from '@client/components/Device/CurrentSelectedContentDetails/LabelItem.vue';
import ModalDialog from '../../ModalDialog/ModalDialog.vue';
import ContentItemsList from '../../ContentItems/ContentItemsList.vue';
import { ContentType } from '@common/enums';
import { ContentItemSelectionTarget } from '@client/enums';

/**
 * Contains the list of object available in the canvas/template
 * Contains a button to select the background, and each of the labels
 * Also contains a button to add a label (only if the base layer is selected and number of labels < 12)
 */
@Component({ components: { ContentItemsList, ModalDialog, LabelItem } })
export default class EditContentModal extends Vue {
  @Prop()
  private deviceTemplate!: DeviceTemplate;
  @Prop({ default: () => false })
  private showContentItemDialog!: boolean;
  @Prop()
  private closeContentItemDialog!: () => void;

  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  private schedulesStore: SchedulesStore = useSchedulesStore();

  get deviceContentSelection(): DeviceContentSelection {
    return this.gondolaTemplatesStore.deviceContentSelection;
  }

  get selectedSchedule(): string | undefined {
    return this.schedulesStore.selectedSchedule;
  }

  get selectableContentType(): Array<ContentType> {
    const allTypes: Array<ContentType> = Object.values(ContentType) as Array<ContentType>;
    return this.deviceContentSelection.isForeground
      ? allTypes.filter((type: string) => type !== ContentType.Playlist)
      : allTypes;
  }

  get contentItemSelectionTarget(): ContentItemSelectionTarget {
    return ContentItemSelectionTarget.DEVICE_LABEL_OR_BACKGROUND;
  }

  get currentlyActiveContentItemName(): string {
    const currentlySelectedLayer: ScheduleLayer | BaseLayer | undefined = this.selectedLayer;
    if (!currentlySelectedLayer) {
      return '';
    }
    return currentlySelectedLayer.getName();
  }

  get selectedLayer(): ScheduleLayer | BaseLayer | undefined {
    if (this.deviceContentSelection.isForeground) {
      if (this.selectedSchedule) {
        const scheduleContent: ScheduledContent | undefined = this.deviceTemplate.foregroundContent[
          this.deviceContentSelection.index
        ]?.scheduledContent?.find(
          (schedule: ScheduledContent) => schedule.scheduleId === this.schedulesStore.getSelectedSchedule()
        );
        if (scheduleContent) {
          return scheduleContent?.layer;
        }
      }
      return this.deviceTemplate.foregroundContent[this.deviceContentSelection.index]?.baseLayer;
    } else {
      if (this.selectedSchedule) {
        const scheduleContent: ScheduledContent | undefined =
          this.deviceTemplate.backgroundContent?.[0]?.scheduledContent?.find(
            (schedule: ScheduledContent) => schedule.scheduleId === this.schedulesStore.getSelectedSchedule()
          );
        if (scheduleContent) {
          return scheduleContent?.layer;
        }
      }
      return this.deviceTemplate.backgroundContent?.[0]?.baseLayer;
    }
  }
}
