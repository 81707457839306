
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ContentType } from '@common/enums';
import { contentItemTypes } from '@client/components/ContentItems/utils';

@Component({
  methods: { contentItemTypes },
})
export default class TypeFilter extends Vue {
  /* DECLARATIONS */
  @Prop()
  onChange!: (filters: Array<ContentType>) => void;
  @Prop({ default: () => [] })
  private defaultSelectedValues!: Array<ContentType>;

  private selectedTypes: Array<ContentType> = [];

  /* LIFECYCLE EVENTS */
  created(): void {
    if (this.defaultSelectedValues.length) {
      this.selectedTypes = this.defaultSelectedValues;
    }
  }
  /* METHODS */
  onValueClicked(value: ContentType[]) {
    this.selectedTypes = value;
    this.onChange(this.selectedTypes);
  }
  /* GETTERS */
}
