
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ActiveHoursConfigEventLog } from '@client/models/EventLogModels/ActiveHoursConfigEventLog/ActiveHoursConfigEventLog.model';
import { getShortDayName } from '@client/utils/DateTimeUtils';
import { Optional } from '@common/types';
import { Gondola, Store } from '@client/models';
import { TranslateResult } from 'vue-i18n';
import { getStoreDetailPath } from '@client/router/utils';
import Notification from '@client/components/NotificationPanel/Notifications/Notification.vue';

@Component({
  methods: { getShortDayName },
  components: {
    Notification,
  },
})
export default class ActiveHoursConfigNotification extends Vue {
  /* DECLARATIONS */
  @Prop()
  private toggleDrawerVisibility!: () => void;
  @Prop()
  private eventLog!: ActiveHoursConfigEventLog;
  @Prop()
  private store: Optional<Store>;

  /* LIFECYCLE EVENTS */
  /* METHODS */

  /* GETTERS */
  get activeHoursStoreName(): string {
    return this.store?.name || this.eventLog.azureStoreId;
  }

  get notificationTitle(): string {
    if (!this.store) {
      return `${this.eventLog.azureStoreId} - ${this.messageFromConfig}`;
    }
    return ` - ${this.messageFromConfig}`;
  }

  get dateToDisplay(): string | Date {
    return this.eventLog.createdAt;
  }

  get isSectionActiveHoursConfig(): boolean {
    return !!this.eventLog.gondolaId;
  }

  get gondola(): Optional<Gondola> {
    if (!this.store || !this.eventLog.gondolaId) {
      return undefined;
    }
    return this.store.gondolas.find((gondola: Gondola) => gondola._id === this.eventLog.gondolaId);
  }

  get aisleName(): string {
    return this.gondola?.aisle || '';
  }

  get positionInAisle(): string {
    return this.gondola?.positionInAisle || '';
  }

  get linkToStore(): string {
    if (!this.store) {
      return '';
    }
    return getStoreDetailPath(this.eventLog.storeId);
  }

  get messageFromConfig(): TranslateResult {
    const isConfigSet: boolean = !!this.eventLog.activeHoursConfig;
    if (this.isSectionActiveHoursConfig) {
      return isConfigSet
        ? this.$i18n.t(this.$i18nTranslationKeys.notifications.activeHoursConfig.activeHoursConfigSet.$path, {
            target: `${this.aisleName} - ${this.positionInAisle}`,
          })
        : this.$i18n.t(this.$i18nTranslationKeys.notifications.activeHoursConfig.activeHoursConfigRemoved.$path, {
            target: `${this.aisleName} - ${this.positionInAisle}`,
          });
    } else {
      return isConfigSet
        ? this.$i18n.t(this.$i18nTranslationKeys.notifications.activeHoursConfig.activeHoursConfigSet.$path, {
            target: this.activeHoursStoreName,
          })
        : this.$i18n.t(this.$i18nTranslationKeys.notifications.activeHoursConfig.activeHoursConfigRemoved.$path, {
            target: this.activeHoursStoreName,
          });
    }
  }
}
