
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Preview from '../Preview/Preview.vue';
import { BackgroundContent, DeviceTemplate, ForegroundContent, HardwareModel, Store } from '@client/models';
import DeviceGroup, { UNLINKED_GROUP_COLOR } from '@client/models/DeviceModels/DeviceGroup.model';
import { Optional } from '@common/types';
import { SchedulesStore, useSchedulesStore } from '@client/stores/schedules';
import { StoresStore, useStoresStore } from '@client/stores/stores';

@Component({
  components: {
    Preview,
  },
})
export default class Device extends Vue {
  @Prop() private deviceTemplate!: DeviceTemplate;
  @Prop() private gondolaTemplateId!: string;
  @Prop() private getCurrentlyDisplayedScheduleForTemplate!: boolean;
  @Prop() private isDeviceSelected!: boolean;

  @Prop() private onDragEnter!: (rowIndex: number, colIndex: number, key: string) => void;
  @Prop() private onDragLeave!: (key: string) => void;
  @Prop() private handleDropToTop!: (rowIndex: number) => void;
  @Prop() private handleDropToBottom!: (rowIndex: number) => void;
  @Prop() private handleDropToLeft!: (rowIndex: number, colIndex: number) => void;
  @Prop() private handleDropToRight!: (rowIndex: number, colIndex: number) => void;
  @Prop() private setSelectedDevice!: (deviceTemplate: DeviceTemplate, rowIndex: number, colIndex: number) => void;
  @Prop() private onDragStartDevice!: (
    deviceTemplate: DeviceTemplate,
    rowIndex: number,
    colIndex: number,
    event: DragEvent
  ) => void;
  @Prop() private onDragEnd!: () => void;
  @Prop() private isHovering!: (rowIndex: number, colIndex: number, key: string) => boolean;
  @Prop() private rowIndex!: number;
  @Prop() private colIndex!: number;

  private schedulesStore: SchedulesStore = useSchedulesStore();
  private storesStore: StoresStore = useStoresStore();

  private deviceGroup: DeviceGroup = this.deviceTemplate.railGroup;
  private hardwareModel: HardwareModel = this.deviceTemplate.hardwareModel;

  created() {
    this.deviceGroup = this.getDeviceGroup;
    this.hardwareModel = this.deviceTemplate.hardwareModel;
  }

  @Watch('deviceTemplate', { deep: true })
  onDeviceTemplateChange(): void {
    this.deviceGroup = this.getDeviceGroup;
    this.hardwareModel = this.deviceTemplate.hardwareModel;
  }

  get getSize(): string {
    return `device-${this.hardwareModel.identifier}`;
  }

  get getSelectedSchedule(): string {
    const storeModel: Optional<Store> = this.storesStore.getStoreById(this.$route.params.storeid);
    if (this.getCurrentlyDisplayedScheduleForTemplate && storeModel) {
      return this.schedulesStore.getCurrentlyDisplayedScheduleForTemplate(this.gondolaTemplateId, storeModel.timezone);
    }
    return this.schedulesStore.getSelectedSchedule();
  }

  get getDeviceGroup(): DeviceGroup {
    return this.deviceTemplate.railGroup;
  }

  get getForegroundContentItems(): Array<ForegroundContent> {
    return this.deviceTemplate?.foregroundContent || [];
  }

  get getBackgroundContentItem(): BackgroundContent | undefined {
    return this.deviceTemplate?.backgroundContent?.[0];
  }

  get deviceHasContent(): boolean {
    return !!this.getBackgroundContentItem?.baseLayer || !!this.getForegroundContentItems.length;
  }

  get railGroupColor(): string {
    return this.deviceGroup.color ?? UNLINKED_GROUP_COLOR;
  }

  get railGroupIconColor(): string {
    return this.deviceGroup.isUnlinked ? 'error' : 'white';
  }

  get isDeviceGroupUnlinked(): boolean {
    return this.deviceGroup.isUnlinked === true;
  }
}
