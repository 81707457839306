
import { Component, Prop, Vue } from 'vue-property-decorator';
import ModalHeader from '@client/components/Layouts/ModalHeader.vue';
import showdown from 'showdown';

@Component({
  components: { ModalHeader },
})
export default class ReleaseNoteModal extends Vue {
  @Prop()
  private releaseNote!: string;

  private showDialog: boolean = false;
  private converter: showdown.Converter = new showdown.Converter();

  closeDialog(): void {
    this.showDialog = false;
  }

  get text() {
    return this.converter.makeHtml(this.releaseNote);
  }
}
