
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import GondolaTemplateContentEdit from '@client/components/GondolaTemplate/GondolaTemplateContentEdit.vue';
import { SchedulesStore, useSchedulesStore } from '@client/stores/schedules';
import { DeviceTemplate, GondolaTemplate, Schedule } from '@client/models';
import Wrapper from '@client/components/Layouts/Wrapper.vue';

@Component({
  components: { GondolaTemplateContentEdit, Wrapper },
})
export default class LayoutTab extends Vue {
  /* DECLARATIONS */
  @Prop({ default: () => [] })
  private schedules!: Array<Schedule>;
  @Prop()
  private gondolaTemplate!: GondolaTemplate;

  private selectedSchedule: string = '';

  private loading: boolean = false;

  private schedulesStore: SchedulesStore = useSchedulesStore();
  /* LIFECYCLE EVENTS */
  /* METHODS */
  setSelectedSchedule(selectedSchedule: string): void {
    this.schedulesStore.updateSelectedSchedule(selectedSchedule);
  }

  onLoadingChange(loading: boolean): void {
    this.loading = loading;
  }

  updateRailgrid(railGrid: Array<Array<DeviceTemplate>>): void {
    this.$emit('rail-grid-change', railGrid);
  }

  @Watch('schedulesStore.selectedSchedule')
  onSelectedScheduleChange(): void {
    this.selectedSchedule = this.schedulesStore.selectedSchedule;
  }
  /* GETTERS */
}
