
import { Component, Prop, Vue } from 'vue-property-decorator';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import ModalDialog from '@client/components/ModalDialog/ModalDialog.vue';

@Component({
  components: { ModalDialog },
})
export default class DeleteButton extends Vue {
  /* DECLARATIONS */
  @Prop()
  private gondolaIndex!: number;
  @Prop()
  private gondolaTemplateId?: string;

  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();

  private showDeleteDialog: boolean = false;
  /* LIFECYCLE EVENTS */
  /* METHODS */
  deleteGondola(gondolaTemplateId?: string): void {
    if (!gondolaTemplateId) {
      return;
    }
    this.showDeleteDialog = true;
  }

  confirmHandler(): void {
    if (!this.gondolaTemplateId) {
      return;
    }
    this.gondolaTemplatesStore.delete(this.gondolaTemplateId);
    this.showDeleteDialog = false;
  }

  cancelHandler(): void {
    this.showDeleteDialog = false;
  }
  /* GETTERS */
}
