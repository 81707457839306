
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DeviceSensorSummary } from '@common/apim/definitions';
import { BaseLayer, DevicePublishing, GondolaPublishing, ScheduleLayer } from '@client/models';
import DevicePublishingBackgroundContentModel from '@client/models/DeviceModels/DevicePublishingBackgroundContentModel';
import DevicePublishingScheduledContentModel from '@client/models/DeviceModels/DevicePublishingScheduledContentModel';
import DevicePublishingForegroundContentModel from '@client/models/DeviceModels/DevicePublishingForegroundContentModel';
import { ContentItem } from '@client/models/ContentModels/types';
import { secondsToHHMMSS } from './utils';
import Wrapper from '@client/components/Layouts/Wrapper.vue';

@Component({
  components: { Wrapper },
  methods: { secondsToHHMMSS },
})
export default class EngagementPerContent extends Vue {
  /* DECLARATIONS */
  @Prop()
  private sensorDataSummary!: DeviceSensorSummary | null;
  @Prop({ required: true })
  private publishing!: GondolaPublishing;
  @Prop({ required: true })
  private contentItems!: Array<ContentItem>;

  /**
   * Map of layerId to content item
   */
  private publishingContentMap: Map<string, BaseLayer | ScheduleLayer> = new Map<string, BaseLayer | ScheduleLayer>();
  private contentItemsMap: Map<string, ContentItem> = new Map<string, ContentItem>();
  /* LIFECYCLE EVENTS */
  created(): void {
    this.contentItems.forEach((contentItem: ContentItem) => {
      this.contentItemsMap.set(contentItem.name, contentItem);
    });
    this.publishing.railGrid.flat(1).forEach((rail: DevicePublishing) => {
      rail.backgroundContent.forEach((content: DevicePublishingBackgroundContentModel) => {
        if (content.baseLayer?.layerId) {
          this.publishingContentMap.set(content.baseLayer.layerId, content.baseLayer);
        }
        content.scheduledContent?.forEach((scheduledContent: DevicePublishingScheduledContentModel) => {
          if (scheduledContent.layer.layerId) {
            this.publishingContentMap.set(scheduledContent.layer.layerId, scheduledContent.layer);
          }
        });
      });
      rail.foregroundContent.forEach((content: DevicePublishingForegroundContentModel) => {
        if (content.baseLayer?.layerId) {
          this.publishingContentMap.set(content.baseLayer.layerId, content.baseLayer);
        }
        content.scheduledContent?.forEach((scheduledContent: DevicePublishingScheduledContentModel) => {
          if (scheduledContent.layer.layerId) {
            this.publishingContentMap.set(scheduledContent.layer.layerId, scheduledContent.layer);
          }
        });
      });
    });
  }
  /* METHODS */
  getContentType(layerId: string) {
    const content: BaseLayer | ScheduleLayer | undefined = this.publishingContentMap.get(layerId);
    if (!content) {
      return '';
    }
    return content.type;
  }

  getLayerName(layerId: string) {
    const content: BaseLayer | ScheduleLayer | undefined = this.publishingContentMap.get(layerId);
    if (!content) {
      return layerId;
    }
    const contentItem: ContentItem | undefined = this.contentItemsMap.get(content.name);
    if (!contentItem) {
      return content.name;
    }
    if (contentItem.isVideo() || contentItem.isImage()) {
      return contentItem.originalName;
    }
    return content.name;
  }
  /* GETTERS */
}
