
import { Component, Vue, Watch } from 'vue-property-decorator';
import { ContentItemInputAcceptMimeType } from '@client/enums';
import ContentItemsUploadHistory from '@client/components/ContentItems/ContentItemsUploadHistory.vue';
import i18n from '@client/plugins/i18n/i18n';
import { MessagesStore, useMessagesStore } from '@client/stores/messages';
import { ContentItemsStore, useContentItemsStore } from '@client/stores/contentItems';
import AzureBlobService from '@client/plugins/azureblobservice';
import { ContentType } from '@common/enums';
import { TransferProgressEvent } from '@azure/core-http';
import { AppGlobalStore, useAppGlobalStore } from '@client/stores/app-global';
import { QueueItem, UploadState } from '@client/stores/contentItems/store';
import { acceptedExtensions } from '@client/components/ContentItems/utils';
import { Route } from 'vue-router';
import { NextType } from '@client/definitions/hooks';
import { Optional } from '@common/types';

@Component({
  components: { ContentItemsUploadHistory },
})
export default class ContentItemsUpload extends Vue {
  /* DECLARATIONS */
  private messagesStore: MessagesStore = useMessagesStore();
  private contentItemsStore: ContentItemsStore = useContentItemsStore();
  private appGlobalStore: AppGlobalStore = useAppGlobalStore();

  private isHistoryDialogOpen: boolean = false;
  private isBadgeVisible: boolean = false;
  private files: Array<File> = [];
  private selectedFolder: string = '';
  private inputRerenderKey: number = 0;
  /* LIFECYCLE EVENTS */
  created(): void {
    this.$root.$on('openFileUploadPopup', () => {
      document.getElementById('content-item-upload-input')?.click();
    });
    this.$root.$on('openFolderUploadPopup', () => {
      document.getElementById('content-item-folder-upload-input')?.click();
    });
    this.$root.$on('addFilesToQueue', () => {
      this.triggerNextUpload();
    });
    this.$root.$on('selectedFolderChanged', (newSelectedFolder: string) => {
      this.selectedFolder = newSelectedFolder;
    });
    window.addEventListener('beforeunload', this.beforeWindowUnload);
  }

  beforeDestroy(): void {
    window.removeEventListener('beforeunload', this.beforeWindowUnload);
  }

  beforeRouteLeave(to: Route, from: Route, next: NextType): void {
    // If items are uploading and the user did not confirm leave,
    // prevent losing unsaved changes by canceling navigation
    if (this.confirmStayInPage()) {
      next(false);
    } else {
      // Navigate to next view
      next();
    }
  }
  /* METHODS */
  closeUploadHistory(): void {
    this.isHistoryDialogOpen = false;
  }

  beforeWindowUnload(e: Event): void {
    if (this.confirmStayInPage()) {
      // Cancel the event
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = false;
    }
  }

  confirmLeave(): boolean {
    return window.confirm();
  }

  confirmStayInPage(): boolean {
    return this.isStillUploading && !this.confirmLeave();
  }
  /**
   * Triggers the upload of the next available file in the queue
   */
  triggerNextUpload(): void {
    // If a file is already being uploaded, skip this
    if (this.contentItemsStore.getCurrentlyUploadingItem()) {
      return;
    }
    const file: Optional<QueueItem> = this.contentItemsStore.getNextItemInQueue();
    if (file) {
      this.onUploadFile(file);
    }
  }

  /**
   * Processes the selected file(s) or folder(s) from the file dialog.
   * It will filter out files we don't accept.
   * @param files list of files to process
   * @param isFolderUpload indicator whether we are using folder or multiple file upload
   */
  onFileOrFolderSelected(files: Array<File>, isFolderUpload: boolean): void {
    if (!files) {
      return;
    }
    this.inputRerenderKey += 1;
    const acceptedFiles: Array<File> = files.filter((file: File) =>
      acceptedExtensions.includes(file.name.toLocaleLowerCase().split('.').pop() || '')
    );
    if (acceptedFiles.length === 0) {
      // Clear the selected files
      this.files = [];
      // No files can be uploaded show a warning message
      this.messagesStore.showMessage(i18n.t(this.$i18nTranslationKeys.contentManagement.noMatchingFiles.$path));
      return;
    } else if (acceptedFiles.length === 1 && !isFolderUpload) {
      // If a single file is selected, trigger the upload immediately
      this.contentItemsStore.addFileToQueue({ file: acceptedFiles[0], targetFolder: this.selectedFolder });
      this.triggerNextUpload();
      return;
    } else if (acceptedFiles.length !== files.length) {
      // Some files have been filtered, show a warning message
      this.messagesStore.showMessage(
        i18n.t(this.$i18nTranslationKeys.contentManagement.filteredFiles.$path, {
          originalNumberOfFiles: files.length,
          allowedNumberOfFiles: acceptedFiles.length,
        })
      );
    }
    this.contentItemsStore.setPendingFiles(
      acceptedFiles.map((file: File) => ({ file, targetFolder: this.selectedFolder })),
      isFolderUpload
    );
    // Clear the selected files after processing it to the store
    this.files = [];
  }

  /**
   * Uploads an Image and creates an associated ContentItem
   */
  async onUploadFile(queueItem: QueueItem): Promise<void> {
    queueItem.uploadState = UploadState.uploading;
    try {
      const fileType: string = queueItem.fileData.file.type.split('/')[0]; // should be "video" or "image"
      if (fileType !== ContentType.Image && fileType !== ContentType.Video) {
        queueItem.uploadState = UploadState.error;
        this.triggerNextUpload();
        return;
      }
      await AzureBlobService.createContentItem(
        queueItem.fileData.file,
        fileType,
        this.appGlobalStore.customer,
        (transferProgressEvent: TransferProgressEvent) => {
          queueItem.loadedBytes = transferProgressEvent.loadedBytes;
          if (queueItem.loadedBytes === queueItem.fileData.file.size) {
            queueItem.uploadState = UploadState.uploaded;
          }
        },
        queueItem.fileData.targetFolder
      );
    } catch (e: unknown) {
      const error: Error = e as Error;
      if (error.name === 'AbortError') {
        queueItem.uploadState = UploadState.cancelled;
        console.warn('Upload aborted');
      } else {
        queueItem.uploadState = UploadState.error;
        console.error('Error occurred during upload', e);
      }
    }
    this.triggerNextUpload();
  }

  @Watch('contentItemsStore.upload.uploadQueue.length')
  onFileAddedToQueue(): void {
    if (this.isHistoryDialogOpen) {
      return;
    }
    this.isBadgeVisible = true;
  }

  @Watch('isHistoryDialogOpen')
  onHistoryDialogToggle(): void {
    if (this.isHistoryDialogOpen) {
      this.isBadgeVisible = false;
    }
  }

  /* GETTERS */
  get uploadInputAcceptMimeType(): string {
    return ContentItemInputAcceptMimeType.ALL;
  }

  get isUploadHistoryButtonVisible(): boolean {
    return this.contentItemsStore.upload.uploadQueue.length > 0;
  }

  get uploadHistoryButtonIcon(): string {
    return this.isStillUploading ? 'mdi-progress-upload' : 'mdi-upload';
  }

  get isStillUploading(): boolean {
    return this.contentItemsStore.getAreItemsStillUploading();
  }
}
