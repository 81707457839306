
import { Component, Prop, Vue } from 'vue-property-decorator';
import AffectedEntitiesModal from '@client/components/Settings/Tags/AffectedEntitiesModal.vue';
import Tag from '@client/models/SettingsModels/Tag.model';
import { AffectedEntitiesViewOptions } from '@client/models/SettingsModels/AffectedEntitiesViewOptions';

@Component({
  computed: {
    AffectedEntitiesViewOptions() {
      return AffectedEntitiesViewOptions;
    },
  },
  components: { AffectedEntitiesModal },
})
export default class DeleteButton extends Vue {
  @Prop()
  private tag!: Tag;

  private showDialog: boolean = false;

  closeDialog(): void {
    this.showDialog = false;
  }
}
