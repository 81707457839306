
import { Component, Prop, Vue } from 'vue-property-decorator';
import { copyQRCodeToClipboard, isClipboardAPICompatibleWithBrowser } from '@client/components/QRCode/utils';

@Component({
  methods: { isClipboardAPICompatibleWithBrowser, copyQRCodeToClipboard },
})
export default class QRCodeCopyButton extends Vue {
  /* DECLARATIONS */
  @Prop({ default: false, type: Boolean })
  private onlyIcon!: boolean;

  /* LIFECYCLE EVENTS */

  /* METHODS */

  /* GETTERS */
}
