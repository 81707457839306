
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FirmwareReleasedEventLog } from '@client/models/EventLogModels/ReleaseManagementEventLog/FirmwareReleasedEvent.modal';
import FirmwareReleasedAffectedDevicesModal from '@client/components/NotificationPanel/Notifications/elements/FirmwareReleasedAffectedDevicesModal.vue';
import ReleaseNoteModal from '@client/components/NotificationPanel/Notifications/elements/ReleaseNoteModal.vue';
import { TranslateResult } from 'vue-i18n';
import { getStoreDetailPath } from '@client/router/utils';
import { Optional } from '@common/types';
import { dateToMomentDate, dateToMomentTime } from '@client/utils/DateTimeUtils';
import { Store } from '@client/models';
import Notification from '@client/components/NotificationPanel/Notifications/Notification.vue';

@Component({
  components: {
    Notification,
    ReleaseNoteModal,
    FirmwareReleasedAffectedDevicesModal,
  },
})
export default class FirmwareReleasedNotification extends Vue {
  /* DECLARATIONS */
  @Prop()
  private toggleDrawerVisibility!: () => void;
  @Prop()
  private eventLog!: FirmwareReleasedEventLog;
  @Prop()
  private store!: Optional<Store>;

  /* METHODS */
  /* GETTERS */
  get notificationTitleIdentifier(): Optional<string> {
    return this.store?.name || this.eventLog.azureStoreId;
  }

  get linkToStore(): string {
    if (!this.store) {
      return '';
    }
    return getStoreDetailPath(this.eventLog.storeId);
  }

  get notificationTitle(): TranslateResult {
    return ` - ${this.$t(this.$i18nTranslationKeys.notifications.firmwareReleased.title.$path, {
      store: this.store?.name || this.eventLog.azureStoreId,
    })}`;
  }

  get executionDate(): string {
    return dateToMomentDate(new Date(this.eventLog.executionDatetime), this.$i18n.locale);
  }

  get executionTime(): string {
    return dateToMomentTime(this.eventLog.executionDatetime, this.$i18n.locale, true);
  }

  get storeAzureId(): string {
    return this.eventLog.azureStoreId || '';
  }
}
