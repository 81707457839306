
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Store } from '@client/models';
import { ReleaseManagementStore, useReleaseManagementStore } from '@client/stores/releaseManagement/store';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import { Optional } from '@common/types';
import ReleaseManagement from '@client/models/SettingsModels/ReleaseManagement.model';
import { ActiveHoursStore, useActiveHoursStore } from '@client/stores/activeHours';
import EmailSubscribeButton from '@client/components/Settings/ReleaseManagement/Actions/EmailSubscribeButton.vue';
import AssignReleaseManagementToStore from '@client/components/Settings/ReleaseManagement/AssignReleaseManagementToStore.vue';

@Component({
  components: { AssignReleaseManagementToStore, EmailSubscribeButton },
})
export default class ReleaseManagementSettings extends Vue {
  /* DECLARATIONS */
  @Prop()
  private store!: Store;
  private releaseManagementStore: ReleaseManagementStore = useReleaseManagementStore();
  private storesStore: StoresStore = useStoresStore();
  private activeHoursStore: ActiveHoursStore = useActiveHoursStore();

  /* LIFECYCLE EVENTS */

  async created(): Promise<void> {
    await this.releaseManagementStore.fetch();
  }

  /* METHODS */

  /* GETTERS */
  get isLoading(): boolean {
    return (
      this.storesStore.loadingIndicator.update ||
      this.storesStore.loadingIndicator.delete ||
      this.activeHoursStore.loadingIndicator.update
    );
  }

  get releaseManagement(): Optional<ReleaseManagement> {
    if (!this.store.releaseManagementReference?.id) {
      return undefined;
    }
    return this.releaseManagementStore.getReleaseConfigurationById(this.store.releaseManagementReference.id);
  }

  get isMissingAzureId(): boolean {
    return !this.store.idAzure;
  }
}
