
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EventLog, Store } from '@client/models';
import PublishingNotification from './Notifications/PublishingNotification.vue';
import DeviceStatusNotification from './Notifications/DeviceStatusNotification.vue';
import StoreActiveHoursNotification from './Notifications/StoreActiveHoursNotification.vue';
import SectionActiveHoursNotification from './Notifications/SectionActiveHoursNotification.vue';
import NoNotifications from './Notifications/NoNotifications.vue';
import NotificationFilters from './NotificationFilters.vue';
import AzureBlobService from '@client/plugins/azureblobservice';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import { EventLogsStore, useEventLogsStore } from '@client/stores/eventLogs';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import InfiniteScroll from '@client/components/InfiniteScroll/InfiniteScroll.vue';
import ActiveHoursConfigNotification from '@client/components/NotificationPanel/Notifications/ActiveHoursConfigNotification.vue';
import FirmwareReleasedNotification from '@client/components/NotificationPanel/Notifications/FirmwareReleasedNotification.vue';
import FirmwareChangedNotification from '@client/components/NotificationPanel/Notifications/FirmwareChangedNotification.vue';
import StoreSynchronizationNotification from '@client/components/NotificationPanel/Notifications/StoreSynchronizationNotification.vue';
import '@client/components/NotificationPanel/Notifications/notifications.scss';
import { Optional } from '@common/types';

@Component({
  components: {
    StoreSynchronizationNotification,
    FirmwareChangedNotification,
    FirmwareReleasedNotification,
    ActiveHoursConfigNotification,
    InfiniteScroll,
    NotificationFilters,
    DeviceStatusNotification,
    PublishingNotification,
    NoNotifications,
    StoreActiveHoursNotification,
    SectionActiveHoursNotification,
  },
})
export default class NotificationPanel extends Vue {
  @Prop()
  private drawerIsVisible!: boolean;
  @Prop()
  private toggleDrawerVisibility!: () => void;

  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  private eventLogsStore: EventLogsStore = useEventLogsStore();
  private storesStore: StoresStore = useStoresStore();

  private readToken: string = '';

  async created(): Promise<void> {
    await this.gondolaTemplatesStore.fetch();
    await this.storesStore.fetch();
    this.readToken = await AzureBlobService.getUrlReadToken();
  }

  onDrawerVisibilityChanged(isDrawerVisible: boolean): void {
    if (isDrawerVisible !== this.drawerIsVisible) {
      this.toggleDrawerVisibility();
    }
  }

  onIntersectionEvent(isIntersecting: boolean): void {
    if (
      isIntersecting &&
      (this.eventLogsStore.eventLogs.length < this.eventLogsStore.totalCount ||
        this.eventLogsStore.currentPage === 0) &&
      !this.eventLogsStore.isSearching
    ) {
      this.eventLogsStore.fetch();
    }
  }

  getStore(event: EventLog): Optional<Store> {
    return this.storesStore.getStoreById(event.storeId);
  }

  get eventLogs(): Array<EventLog> {
    return this.eventLogsStore.eventLogs;
  }

  get isSearching(): boolean {
    return this.eventLogsStore.isSearching;
  }
}
