
import { Component, Vue } from 'vue-property-decorator';
import ReleaseManagementModel from '@client/models/SettingsModels/ReleaseManagement.model';
import { ReleaseManagementStore, useReleaseManagementStore } from '@client/stores/releaseManagement/store';
import ReleaseManagementTable from '@client/components/Settings/ReleaseManagement/ReleaseManagementTable.vue';
import AddButton from '@client/components/Settings/ReleaseManagement/Actions/AddButton.vue';
import { Optional } from '@common/types';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import Wrapper from '@client/components/Layouts/Wrapper.vue';

@Component({
  components: { AddButton, ReleaseManagementTable, Wrapper },
})
export default class ReleaseManagementTab extends Vue {
  private releaseManagementStore: ReleaseManagementStore = useReleaseManagementStore();
  private storesStore: StoresStore = useStoresStore();

  async created(): Promise<void> {
    await this.releaseManagementStore.fetch();
    await this.storesStore.fetch();
  }

  get isLoading(): boolean {
    return this.releaseManagementStore.isFetching || this.releaseManagementStore.isActionPending;
  }

  get releaseConfigurations(): Array<ReleaseManagementModel> {
    return this.releaseManagementStore.getReleaseConfigurations();
  }

  get storesWithDefaultConfigCount(): number {
    const defaultReleaseManagement: Optional<ReleaseManagementModel> =
      this.releaseManagementStore.getDefaultReleaseManagement();
    if (defaultReleaseManagement) {
      return defaultReleaseManagement.appliedStores.length;
    }
    return this.storesStore.stores.length;
  }
}
