
import { Component, Prop, Vue } from 'vue-property-decorator';
import { PublishingSensorDataTableItem } from '@client/models/SensorModels/PublishingSensorDataTableItem';
import { DetectedObjectEntry, DeviceSensorObjects, DeviceSensorSummary } from '@common/apim/definitions';
import { DevicesStore, useDevicesStore } from '@client/stores/devices';
import { Optional } from '@common/types';
import AverageObjectsDetected from '@client/components/SensorAnalytics/charts/AverageObjectsDetected.vue';
import Overview from '@client/components/SensorAnalytics/charts/Overview.vue';
import TimePerArea from '@client/components/SensorAnalytics/charts/TimePerArea.vue';
import ObjectsPerArea from '@client/components/SensorAnalytics/charts/ObjectsPerArea.vue';
import EngagementPerContent from '@client/components/SensorAnalytics/charts/EngagementPerContent.vue';
import { Portal } from 'portal-vue';
import { ContentItem } from '@client/models/ContentModels/types';
import spacetime from 'spacetime';
import { Store } from '@client/models';
import axios, { AxiosError } from 'axios';
import Moment from 'moment';

@Component({
  components: { EngagementPerContent, ObjectsPerArea, TimePerArea, Overview, AverageObjectsDetected, Portal },
  computed: {
    Moment() {
      return Moment;
    },
  },
})
export default class SensorDetail extends Vue {
  /* DECLARATIONS */
  @Prop({ required: true })
  private selectedPublishing!: PublishingSensorDataTableItem;
  @Prop({ required: true })
  private contentItems!: Array<ContentItem>;

  private devicesStore: DevicesStore = useDevicesStore();

  private isDeviceNotFound: boolean = false;

  private sensorDataObjects: DeviceSensorObjects | null = null;
  private sensorDataSummary: DeviceSensorSummary | null = null;
  private isDataLoaded: boolean = false;
  private decisionTimeThreshold: number = 0;
  private dwellTimeThreshold: number = 0;
  private exposureTimeThreshold: number = 0;

  /* LIFECYCLE EVENTS */
  async created(): Promise<void> {
    const { publishing }: PublishingSensorDataTableItem = this.selectedPublishing;
    const sensorDeviceLongId: string | undefined = publishing.sensorDevices?.[0];

    if (!sensorDeviceLongId) {
      console.error('No sensor device long ID found');
      return;
    }
    const startDate: string = this.selectedPublishing.publishing.activeAt?.toISOString() ?? '';
    const endDate: string =
      this.selectedPublishing.publishing.publishingEndTime?.toISOString() ?? new Date().toISOString();
    try {
      const deviceSensorObjects: Optional<DeviceSensorObjects> = await this.devicesStore.getDeviceSensorObjects(
        sensorDeviceLongId,
        startDate,
        endDate
      );
      const deviceSensorSummary: Optional<DeviceSensorSummary> = await this.devicesStore.getDeviceSensorSummary(
        sensorDeviceLongId,
        startDate,
        endDate
      );
      if (!deviceSensorObjects || !deviceSensorSummary) {
        console.error('No sensor data found');
        return;
      }
      const store: Store = this.selectedPublishing.store;
      deviceSensorObjects.detectedObjects = deviceSensorObjects.detectedObjects.map(
        (detectedObject: DetectedObjectEntry) => ({
          ...detectedObject,
          detectedObject: {
            ...detectedObject.detectedObject,
            firstSeen: spacetime(detectedObject.detectedObject.firstSeen).goto(store.timezone).format('iso-utc'),
            lastSeen: spacetime(detectedObject.detectedObject.lastSeen).goto(store.timezone).format('iso-utc'),
          },
        })
      );
      this.sensorDataObjects = deviceSensorObjects;
      this.sensorDataSummary = deviceSensorSummary;
      this.isDataLoaded = true;
    } catch (e) {
      const error: Error | AxiosError = e as Error | AxiosError;
      if (!axios.isAxiosError(error)) {
        if (error.message.includes('Not Found')) {
          this.isDeviceNotFound = true;
        }
        console.error(error.message);
        return;
      }
      if (error.response?.status === 404) {
        this.isDeviceNotFound = true;
      }
      console.error(error.response?.status, error.response?.statusText);
    }
  }

  /* METHODS */
  private onReturnButton(): void {
    this.$emit('return');
  }

  exportDataToJSON(): void {
    if (!this.sensorDataObjects) {
      console.error('No sensor data found');
      return;
    }
    const sensorData: string = JSON.stringify(
      { sensorDataSummary: this.sensorDataSummary, sensorDataObjects: this.sensorDataObjects },
      null,
      2
    );
    const dataUri: string = `data:application/json;charset=utf-8,${encodeURIComponent(sensorData)}`;
    const exportFileDefaultName: string = `sensor-data-${new Date().toISOString()}.json`;
    const linkElement: HTMLAnchorElement = document.createElement('a');
    linkElement.setAttribute('href', dataUri);
    linkElement.setAttribute('download', exportFileDefaultName);
    linkElement.click();
  }

  onDecisionTimeThresholdChange(threshold: number = 0): void {
    this.decisionTimeThreshold = threshold;
  }

  onDwellTimeThresholdChange(threshold: number = 0): void {
    this.dwellTimeThreshold = threshold;
  }

  onExposureTimeThresholdChange(threshold: number = 0): void {
    this.exposureTimeThreshold = threshold;
  }
  /* GETTERS */
  get exportButtonLoading(): boolean {
    return !this.sensorDataObjects || !this.sensorDataSummary;
  }

  get parsedSensorDataObjects(): DeviceSensorObjects | null {
    if (!this.sensorDataObjects) {
      return null;
    }
    return {
      ...this.sensorDataObjects,
      detectedObjects: this.sensorDataObjects.detectedObjects.filter(
        (detectedObject: DetectedObjectEntry) =>
          detectedObject.detectedObject.decisionTime >= this.decisionTimeThreshold &&
          detectedObject.detectedObject.dwellTime >= this.dwellTimeThreshold &&
          detectedObject.detectedObject.exposureTime >= this.exposureTimeThreshold
      ),
    };
  }

  get mostRecentDetectedObjectTimestamp(): string {
    if (!this.sensorDataSummary || this.sensorDataSummary.playedLayers.length === 0) {
      return '';
    }

    let latestTimestamp: string = this.sensorDataSummary.playedLayers[0].end;

    for (const layer of this.sensorDataSummary.playedLayers) {
      if (new Date(layer.end) > new Date(latestTimestamp)) {
        latestTimestamp = layer.end;
      }
    }
    return Moment(latestTimestamp).format('llll');
  }
}
