
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getDeviceDetailPath } from '@client/router/utils';
import { MessagesStore, useMessagesStore } from '@client/stores/messages';

@Component({})
export default class DeviceOptionsMenu extends Vue {
  @Prop()
  private longId!: string;
  @Prop()
  private shortId!: string;

  private messagesStore: MessagesStore = useMessagesStore();

  copyLongId(): void {
    navigator.clipboard.writeText(this.longId);
    this.messagesStore.showMessage(
      this.$t(this.$i18nTranslationKeys.device.copyLongIdNotification.$path, { shortId: this.shortId })
    );
  }

  navigateToDevice(): void {
    if (!this.longId) {
      return;
    }
    if (!this.shortId) {
      return;
    }
    this.$router.push(getDeviceDetailPath(this.longId));
  }
}
