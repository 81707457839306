<template>
  <div>
    <v-divider class="mb-2" />
    <v-container class="d-flex">
      <v-skeleton-loader class="mr-auto" type="button" min-width="300px" />
      <v-skeleton-loader class="mr-1 my-auto" type="chip" min-width="115px" />
      <v-skeleton-loader class="mr-1 my-auto" type="chip" min-width="115px" />
      <v-skeleton-loader class="mr-1" type="button" max-width="36px" />
      <v-skeleton-loader class="mr-4" type="button" max-width="36px" />
    </v-container>
    <v-divider class="mt-2" />
    <v-container class="d-flex justify-space-between">
      <v-skeleton-loader type="chip" />
      <v-skeleton-loader type="chip" />
    </v-container>
    <v-container>
      <v-skeleton-loader type="list-item@4" />
    </v-container>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class StoreDetailGondolasLoader extends Vue {}
</script>
