
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getBrightness } from '@client/utils/ColorUtils';
import { Optional } from '@common/types';

@Component({})
export default class TagPreview extends Vue {
  @Prop()
  private name!: string;
  @Prop()
  private color!: string;
  @Prop()
  private id: Optional<string>;
  @Prop({ default: false })
  private isClosable!: boolean;
  @Prop()
  private loading!: boolean;

  private textColorClass(hex: string) {
    const brightness: number = getBrightness(hex);
    //128 is the threshold value for whether dark or light text is required
    return brightness > 128 ? 'dark-text' : 'light-text';
  }

  clearTag() {
    if (!this.id) {
      console.error('Can not clear tag without id');
      return;
    }
    this.$emit('onClearTag', this.id);
  }

  get chipClass() {
    return `${this.textColorClass(this.color)} font-weight-bold tag-width`;
  }
}
