export type Optional<T> = T | undefined | null;

interface IQueue<T> {
  peek(): Optional<T>;

  enqueue(item: T): void;

  dequeue(): Optional<T>;

  size(): number;
}

/**
 * It can be used for assigning an entity reference to another that should be validated with a hash.
 */
export interface EntityUpdateReference {
  id: string;
  hash: string;
}

export class Queue<T> implements IQueue<T> {
  private queueItems: T[] = [];

  peek(): Optional<T> {
    return this.queueItems.at(0);
  }

  enqueue(item: T): void {
    this.queueItems.push(item);
  }

  dequeue(): Optional<T> {
    return this.queueItems.shift();
  }

  size(): number {
    return this.queueItems.length;
  }
}
