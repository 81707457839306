import { PublishingBackgroundContentModelJSON, PublishingForegroundContentModelJSON } from '@common/schedule/types';
import { PublishState } from '@common/enums';

export interface DeviceGroupJSON {
  color?: string;
  isUnlinked?: boolean;
}

export interface DevicePublishInformationJSON {
  timestamp: Date;
  message: string;
  state: PublishState;
}

export interface GondolaPublishInformationJSON {
  timestamp: Date;
  message: string;
  state: PublishState;
  railsSucceeded: number;
  railsPending: number;
  railsAccepted: number;
  railsFailed: number;
}

export interface DevicePublishingJSON {
  shortId: string;
  longId: string;
  hardwareModel: string;
  publishInformation: DevicePublishInformationJSON;
  backgroundContent: Array<PublishingBackgroundContentModelJSON>;
  foregroundContent: Array<PublishingForegroundContentModelJSON>;
  railGroup: DeviceGroupJSON;
  previewUrl?: string;
}

export interface GondolaPublishingJSON {
  _id: string;
  activeAt: Date;
  railGrid: Array<Array<DevicePublishingJSON>>;
  publishInformation: GondolaPublishInformationJSON;
  customerId: string;
  userId: string;
  storeId: string;
  storeIdAzure: string;
  apimKey: string;
  apiUrl: string;
  gondolaId: string;
  storeHash: string;
  gondolaTemplateId?: string;
  gondolaTemplateHash?: string;
  aisle: string;
  positionInAisle: string;
  scheduleIds: Array<string>;
  scheduleHashes: Array<string>;
  previousAttempts?: Array<string>;
  nextRunAt?: string;
  sensorDevices?: Array<string>;
  publishingEndTime?: string;
  sensorDataArchived?: boolean;
}

export interface SectionsRequestJSON {
  sections: Array<string>;
}

/**
 * Minimum data that we need for publishing on section level
 */
export type SectionPublishingJSON = {
  sectionId: string;
  storeId: string;
  storeHash: string;
};

/**
 * Minimum data that we need for publishing
 */
export type BulkPublishingJSON = {
  templateId: string;
  templateHash: string;
  sections: Array<SectionPublishingJSON>;
};

/**
 * Object for easy publishing route
 */
export type EasyPublishingJSON = {
  templateId: string;
  longId: string;
};

/**
 * Enum expressing possible errors during publishing that occur on section
 */
export enum PublishingSectionValidationError {
  MISSING_STORE = 'MISSING_STORE',
  MISSING_SECTION = 'MISSING_SECTION',
  MISSING_DEVICE_ID = 'MISSING_DEVICE_ID',
  DIMENSION_MISMATCH = 'DIMENSION_MISMATCH',
  INVALID_HASH = 'INVALID_HASH',
  MISSING_TIMEZONE = 'MISSING_TIMEZONE',
}

/**
 * Enum expressing possible errors during publishing that occur on template
 */
export enum PublishingValidationError {
  MISSING_TEMPLATE = 'MISSING_TEMPLATE',
  INVALID_HASH = 'INVALID_HASH',
  BACKGROUND_MISSING = 'BACKGROUND_MISSING',
  BACKGROUND_BASE_LAYER_MISSING = 'BACKGROUND_BASE_LAYER_MISSING',
  CONTAINS_EMPTY_LABELS = 'CONTAINS_EMPTY_LABELS',
  SECTION_ERROR = 'SECTION_ERROR',
}

/**
 * Possible result of validation of whole publishing
 */
export type PublishValidationState = 'READY_TO_PUBLISH' | PublishingValidationError;

/**
 * Possible result of validation one section before publishing
 */
export type PublishToSectionValidationState = 'READY_TO_PUBLISH' | PublishingSectionValidationError;

/**
 * Object that contains result of publishing validation
 */
export type ValidatedPublishingJSON = {
  templateId: string;
  validationState: PublishValidationState;
  validatedSections?: Array<ValidatedSectionJSON>;
};

/**
 * Object that contains result of publishing validation for one section
 */
export type ValidatedSectionJSON = {
  sectionId: string;
  storeId: string;
  validationState: PublishToSectionValidationState;
};

export type PublishingSensorSearchResponse = {
  publishings: GondolaPublishingJSON[];
  totalPages: number;
  currentPage: number;
};
