import { defineStore, Store, StoreDefinition } from 'pinia';
import {
  Aisle,
  Device,
  Gondola as GondolaModel,
  Gondola,
  GondolaPublishing,
  Store as StoreModel,
} from '@client/models';
import { EntityUpdateReference, Optional } from '@common/types';
import Vue from 'vue';
import axios, { AxiosResponse } from 'axios';
import { NewStoreRequestJSON, StoreResponseJSON } from '@common/stores/types';
import Config from '@client/utils/config';
import { DevicesStore, useDevicesStore } from '@client/stores/devices';
import { StoreGondolaWrapper } from '@client/stores/stores';
import { ReleaseManagementStore, useReleaseManagementStore } from '@client/stores/releaseManagement/store';
import ReleaseManagement from '@client/models/SettingsModels/ReleaseManagement.model';
import { ReleaseManagementAssignmentResponseJSON } from '@common/config/release-management/types';
import { AddOrUpdateSectionsResultJSON } from '@common/stores/error';
import { ActiveHoursSchedule } from '@client/models/ActiveHoursModels';
import TagReference from '@client/models/SettingsModels/TagReference';
import { TagsStore, useTagsStore } from '@client/stores/tags/store';
import Tag from '@client/models/SettingsModels/Tag.model';
import { DeviceJSON } from '@common/device/types';

export interface StoresGetters {
  getStoreById: (state: StoresState) => (id: string) => Optional<StoreModel>;
  getStores: (state: StoresState) => () => Array<StoreModel>;
  getCurrentStore: (state: StoresState) => (id: string) => Optional<StoreModel>;
  getGondolaById: (state: StoresState) => (storeId: string, gondolaId: string) => Optional<Gondola>;
  getGondolaByDeviceId: (state: StoresState) => (deviceId: string) => Optional<StoreGondolaWrapper>;
  getAisleOfStore: (state: StoresState) => (storeId: string, aisleName: string) => Optional<Aisle>;
  getAisleNamesOfStore: (state: StoresState) => (storeId: string) => Array<string>;
  getStoresWithValidAzureId: (state: StoresState) => () => Array<StoreModel>;
  getGondolasWithSameLayout: (state: StoresState) => (layoutHash: string) => Array<Gondola>;
  getStoreWithGondolaId: (state: StoresState) => (gondolaId: string) => Optional<StoreModel>;
  aisleNameExistsInStore: (state: StoresState) => (storeId: string, aisleName: string) => boolean;
  sectionNameExistsInAisle: (
    state: StoresState
  ) => (storeId: string, aisleName: string, sectionName: string) => boolean;
}

export interface StoresActions {
  add(newStore: StoreModel, releaseManagement: Optional<ReleaseManagement>, tags: Array<Tag>): Promise<string>;
  updateStores(storesToUpdate: StoreModel[]): void;
  setUpdatingStoreLoadingIndicator(loadingState: boolean): void;
  setDeletingStoreLoadingIndicator(loadingState: boolean): void;
  resetLoadingIndicators(): void;
  clear(): void;
  setFetched(fetched: boolean): void;
  setIsFetching(isFetching: boolean): void;
  updateLastPublishingForGondola(gondola: GondolaModel, gondolaPublishingModel: GondolaPublishing): void;
  populateDevice(store: StoreModel, device: Device): void;
  updateStoreHash(storeId: string, hash: string): void;
  fetch(): Promise<void>;
  fetchStoreDetails(storeId: string, skipSync?: boolean): Promise<Optional<StoreModel>>;
  updatePublishing(gondolaPublishing: GondolaPublishing): Promise<void>;
  updateStoreSettings(
    storeId: string,
    storeName: string,
    storeTimezone: string,
    storeIdAzure: string,
    newTags: Array<TagReference>
  ): Promise<void>;
  deleteStore(storeId: string): Promise<void>;
  editGondola(gondola: GondolaModel, storeId: string, force: boolean): Promise<void>;
  deleteGondola(gondolaId: string, storeId: string): Promise<void>;
  populateDevices(storeId: string): Promise<void>;
  populateStoreGondolas(store: StoreModel): void;
  syncDevices(storeId: string): Promise<void>;
  updateReleaseManagement(releaseManagementId: string, releaseManagementHash: string, storeId: string): Promise<void>;
  updateEmailSubscriptionState(useEmailSubscription: boolean, storeId: string): Promise<void>;
  setStoreSyncingState(storeId: string, isStoreSyncing: boolean): void;
  addSectionsToAisle(
    storeId: string,
    aisleName: string,
    sections: Array<GondolaModel>,
    isForced?: boolean
  ): Promise<void>;
  updateGondolaMap(store: StoreModel): void;
  deleteGondolaMap(store: StoreModel): void;
  addAisle(storeId: string, aisle: Aisle): Promise<void>;
  renameAisle(storeId: string, oldAisleName: string, newAisleName: string): Promise<void>;
  deleteAisle(storeId: string, aisle: string): Promise<void>;
  clearCurrentStore(): void;
  updateStoreActiveHours(storeId: string, activeHours: ActiveHoursSchedule): void;
  updateGondolaActiveHours(storeId: string, gondolaId: string, activeHours: ActiveHoursSchedule): void;
  validateStoreAzureId(storeAzureId: string): Promise<Array<Device>>;
  cloneAisle(storeId: string, storeHash: string, aisleId: string, newName: string): Promise<void>;
  cloneSection(
    storeId: string,
    storeHash: string,
    sourceSectionId: string,
    sectionName: string,
    sectionAisle: string
  ): Promise<void>;
}

export interface StoresState {
  stores: Array<StoreModel>;
  currentStore: Optional<StoreModel>;
  gondolaStoreMap: Map<string, string>; //mapping gondola id to store id
  loadingIndicator: {
    delete: boolean;
    update: boolean;
    clone: boolean;
  };
  fetched: boolean;
  /*
  Fetching status used to prevent concurrent calls
  If this is set to true and a fetch is called, it will be ignored
   */
  isFetching: boolean;
}

export type StoresStoreDefinition = StoreDefinition<'stores', StoresState, StoresGetters, StoresActions>;

export type StoresStore = Store<'stores', StoresState, StoresGetters, StoresActions>;

export const useStoresStore: StoresStoreDefinition = defineStore('stores', {
  state: (): StoresState => ({
    stores: new Array<StoreModel>(),
    currentStore: null,
    gondolaStoreMap: new Map(),
    loadingIndicator: {
      delete: false,
      update: false,
      clone: false,
    },
    fetched: false,
    isFetching: false,
  }),
  getters: {
    getCurrentStore:
      (state: StoresState) =>
      (storeId: string): Optional<StoreModel> => {
        if (!state.currentStore) {
          return;
        }
        if (storeId !== state.currentStore._id) {
          console.warn(`Current store does not exist ${storeId} , currentStore : ${state.currentStore?._id}`);
          return;
        }
        return state.currentStore;
      },
    getStores: (state: StoresState) => (): Array<StoreModel> => {
      return state.stores;
    },
    aisleNameExistsInStore: (state: StoresState) => (storeId: string, aisleName: string) => {
      const store: Optional<StoreModel> = state.stores.find((store: StoreModel) => store._id === storeId);
      if (!store) {
        return false;
      }
      return store.aisles.some(
        (aisle: Aisle) => aisle.name.trim().toLocaleLowerCase() === aisleName.trim().toLocaleLowerCase()
      );
    },
    getStoreById: (state: StoresState) => (storeId: string) => {
      if (!storeId) {
        return null;
      }
      return state.stores.find((store: StoreModel) => store._id === storeId);
    },
    getGondolaById:
      (state: StoresState) =>
      (storeId: string, gondolaId: string): Optional<Gondola> => {
        return state.stores
          .find((store: StoreModel) => store._id === storeId)
          ?.gondolas.find((gondola: GondolaModel) => gondola._id === gondolaId);
      },
    getGondolaByDeviceId:
      (state: StoresState) =>
      (deviceId: string): Optional<StoreGondolaWrapper> => {
        // no filter, return nothing..
        if (!deviceId) return null;
        let searchResult: Optional<StoreGondolaWrapper> = null;
        state.stores.forEach((store: StoreModel) => {
          store.gondolas.forEach((gondola: GondolaModel) => {
            const found: boolean = gondola.railGrid.some((row: Device[]) =>
              row.some((col: Device) => col.longId === deviceId || col.shortId === deviceId)
            );
            if (found) {
              searchResult = { storeModel: store, gondolaModel: gondola };
              return;
            }
          });
        });
        return searchResult;
      },
    getAisleOfStore:
      (state: StoresState) =>
      (storeId: string, aisleName: string): Optional<Aisle> => {
        return state.stores
          .find((store: StoreModel) => store._id === storeId)
          ?.aisles.find((aisle: Aisle) => aisle.name === aisleName);
      },
    getAisleNamesOfStore:
      (state: StoresState) =>
      (storeId: string): Array<string> => {
        const store: Optional<StoreModel> = state.stores.find((store: StoreModel) => store._id === storeId);
        return !store ? [] : store.aisles.map((aisle: Aisle) => aisle.name);
      },
    getStoresWithValidAzureId: (state: StoresState) => (): Array<StoreModel> => {
      return state.stores.filter((localStore: StoreModel) => localStore.idAzure);
    },
    getGondolasWithSameLayout:
      (state: StoresState) =>
      (layoutHash: string): Array<Gondola> => {
        return state.stores
          .flatMap((store: StoreModel) => store.gondolas)
          .filter((gondola: Gondola) => gondola.layoutHash === layoutHash);
      },
    getStoreWithGondolaId:
      (state: StoresState) =>
      (gondolaId: string): Optional<StoreModel> => {
        const storeId: Optional<string> = state.gondolaStoreMap.get(gondolaId);
        if (storeId) {
          return state.stores.find((store: StoreModel) => store._id === storeId);
        }
      },
    sectionNameExistsInAisle:
      (state: StoresState) =>
      (storeId: string, aisleName: string, sectionName: string): boolean => {
        const store: Optional<StoreModel> = state.stores.find((store: StoreModel) => store._id === storeId);
        if (!store) {
          return false;
        }
        const aisle: Optional<Aisle> = store.aisles.find(
          (aisle: Aisle) => aisle.name.trim().toLocaleLowerCase() === aisleName.trim().toLocaleLowerCase()
        );
        if (!aisle) return false;
        return aisle.gondolas.some(
          (section: GondolaModel) =>
            section.positionInAisle.trim().toLocaleLowerCase() === sectionName.trim().toLocaleLowerCase()
        );
      },
  },
  actions: {
    async add(
      newStore: StoreModel,
      releaseManagement: Optional<ReleaseManagement>,
      tags: Array<Tag> = []
    ): Promise<string> {
      this.loadingIndicator.update = true;
      const newStoreRequest: NewStoreRequestJSON = {
        customerId: newStore.customerId,
        name: newStore.name,
        timezone: newStore.timezone,
        aisles: [],
        activeHoursSchedule: newStore.activeHours,
        idAzure: newStore.idAzure,
      };
      if (releaseManagement) {
        newStoreRequest.releaseManagement = {
          id: releaseManagement._id,
          hash: releaseManagement.hash,
        };
      }
      if (tags) {
        newStoreRequest.tagIds = tags.map((tag: Tag) => {
          return { id: tag._id, hash: tag.hash };
        });
      }
      const response: AxiosResponse<StoreResponseJSON> = await axios.post(
        `${Config.getApiUrl()}/stores/`,
        newStoreRequest
      );

      this.loadingIndicator.update = false;
      if (response.status === 200) {
        const store: StoreModel = StoreModel.fromJSON(response.data);
        this.stores.push(store);
        this.updateGondolaMap(store);
        return store._id;
      } else {
        console.warn(`Unhandled response status for adding a store ${response.status}`);
      }
      return '';
    },
    updateStores(storesToUpdate: StoreModel[]): void {
      for (const storeToUpdate of storesToUpdate) {
        const index: number = this.stores.findIndex((store: StoreModel) => store._id === storeToUpdate._id);
        this.stores.splice(index, 1, storeToUpdate);
      }
    },
    setUpdatingStoreLoadingIndicator(loadingState: boolean): void {
      this.loadingIndicator.update = loadingState;
    },
    setDeletingStoreLoadingIndicator(loadingState: boolean): void {
      this.loadingIndicator.delete = loadingState;
    },
    resetLoadingIndicators(): void {
      this.loadingIndicator.update = false;
      this.loadingIndicator.delete = false;
    },
    clear(): void {
      Vue.set(this, 'stores', new Array<StoreModel>());
    },
    setFetched(fetched: boolean): void {
      this.fetched = fetched;
    },
    setIsFetching(isFetching: boolean): void {
      this.isFetching = isFetching;
    },
    updateLastPublishingForGondola(gondola: GondolaModel, gondolaPublishingModel: GondolaPublishing): void {
      Vue.set(gondola, 'lastPublishing', gondolaPublishingModel);
    },
    populateDevice(store: StoreModel, device: Device): void {
      for (const gondola of store.gondolas) {
        gondola.railGrid = gondola.railGrid.map((row: Array<Device>) =>
          row.map((gondolaDevice: Device) =>
            gondolaDevice.shortId && device.shortId === gondolaDevice.shortId ? device : gondolaDevice
          )
        );
      }
    },
    updateStoreHash(storeId: string, hash: string): void {
      const store: Optional<StoreModel> = this.getCurrentStore(storeId);
      if (!store) {
        return;
      }
      store.hash = hash;
      this.updateStores([store]);
    }, //both action and mutation
    async fetch(): Promise<void> {
      if (!this.isFetching) {
        this.setIsFetching(true);
        const response: AxiosResponse<Array<StoreResponseJSON>> = await axios.get(`${Config.getApiUrl()}/stores`);
        if (response.status === 200) {
          const stores: Array<StoreModel> = response.data.map((value: StoreResponseJSON) => {
            const store: StoreModel = StoreModel.fromJSON(value);
            this.updateGondolaMap(store);
            return store;
          });
          Vue.set(this, 'stores', stores);
          this.setFetched(true);
          this.setIsFetching(false);
        }
      }
    },
    async fetchStoreDetails(storeId: string, skipSync: boolean = false): Promise<Optional<StoreModel>> {
      if (this.currentStore) {
        this.currentStore = null;
      }
      const response: AxiosResponse<StoreResponseJSON> = await axios.get(
        `${Config.getApiUrl()}/stores/${storeId}?skipSync=${skipSync}`
      );
      if (response.status !== 200) {
        console.debug(`Error syncing store with storeId ${storeId}`);
        return;
      }
      const store: StoreModel = StoreModel.fromJSON(response.data);
      this.currentStore = store;
      this.updateGondolaMap(store);
      return store;
    },
    async updatePublishing(gondolaPublishing: GondolaPublishing): Promise<void> {
      const gondola: Optional<Gondola> = this.getGondolaById(gondolaPublishing.storeId, gondolaPublishing.gondolaId);
      if (!gondola) {
        return;
      }
      if (
        gondola.lastPublishing?._id === gondolaPublishing._id ||
        (gondolaPublishing.activeAt ?? new Date(0)) > (gondola.lastPublishing?.activeAt ?? new Date(0))
      ) {
        this.updateLastPublishingForGondola(gondola, gondolaPublishing);
      }
    },
    async updateStoreSettings(
      storeId: string,
      storeName: string,
      storeTimezone: string,
      storeIdAzure: string,
      tagsReference: Array<TagReference>
    ): Promise<void> {
      const devicesStore: DevicesStore = useDevicesStore();
      const tagsStore: TagsStore = useTagsStore();
      const tags: EntityUpdateReference[] = [];
      tagsReference.forEach((tagReference: TagReference) => {
        const tag: Optional<Tag> = tagsStore.getTagById(tagReference.id);
        if (!tag) {
          return;
        }
        tags.push({ id: tag._id, hash: tag.hash });
      });
      this.setUpdatingStoreLoadingIndicator(true);
      const response: AxiosResponse<StoreResponseJSON> = await axios.patch(
        `${Config.getApiUrl()}/stores/${storeId}`,
        {
          name: storeName,
          timezone: storeTimezone,
          idAzure: storeIdAzure,
          tagsReference: tags,
        },
        {
          params: {
            hash: this.currentStore?.hash,
          },
        }
      );
      if (response.status === 200 || response.status === 204) {
        const store: Optional<StoreModel> = this.getCurrentStore(storeId);
        if (!store) {
          return;
        }
        devicesStore.clearStoreDevices(store._id);
        store.idAzure = storeIdAzure;
        store.name = storeName;
        store.timezone = storeTimezone;
        store.hash = response.data.hash;
        store.tags = tagsReference;
        if (store.releaseManagementReference?.id !== response.data.releaseManagementReferenceJSON?.id) {
          store.releaseManagementReference = response.data.releaseManagementReferenceJSON;
        }
        this.updateStores([store]);
        await this.populateDevices(storeId);
        await tagsStore.fetch();
        this.setUpdatingStoreLoadingIndicator(false);
      } else {
        console.warn(`Unhandled response status for updateStoreSettings ${response.status}`);
      }
    },
    async deleteStore(storeId: string): Promise<void> {
      this.setDeletingStoreLoadingIndicator(true);
      const response: AxiosResponse<void> = await axios.delete(`${Config.getApiUrl()}/stores/${storeId}`, {
        params: {
          hash: this.currentStore?.hash,
        },
      });
      if (response.status === 200 || response.status === 204) {
        const index: number = this.stores.findIndex((store: StoreModel) => store._id === storeId);
        this.deleteGondolaMap(this.stores[index]);
        this.stores.splice(index, 1);
        this.loadingIndicator.delete = false;
        this.currentStore = null;
      } else {
        console.warn(`Unhandled response status for deleteStore ${response.status}`);
      }
    },
    async editGondola(gondola: GondolaModel, storeId: string, force: boolean): Promise<void> {
      this.loadingIndicator.update = true;
      try {
        const response: AxiosResponse<AddOrUpdateSectionsResultJSON> = await axios.post(
          `${Config.getApiUrl()}/stores/${storeId}/gondola/${gondola._id}`,
          gondola,
          {
            params: {
              hash: this.getCurrentStore(storeId)?.hash,
              force: force,
            },
          }
        );
        if (response.status === 200) {
          const store: Optional<StoreModel> = this.getCurrentStore(storeId);
          if (!store) {
            return;
          }
          store.hash = response.data.store.hash;
          const aisle: Optional<Aisle> = store.aisles.find((aisle: Aisle) => aisle.name == gondola.aisle);
          if (aisle) {
            const index: number = aisle.gondolas.findIndex((gondola: GondolaModel) => gondola._id === gondola._id);
            aisle.gondolas[index] = gondola;
          } else {
            store.aisles.push(new Aisle(gondola.aisle, [gondola]));
          }
          this.updateStores([store]);
          // update stores that were force updated
          if (response.data.forceUpdatedStores) {
            const storesToUpdate: StoreModel[] = response.data.forceUpdatedStores.map(
              (storesToUpdateJSON: StoreResponseJSON) => StoreModel.fromJSON(storesToUpdateJSON)
            );
            this.updateStores(storesToUpdate);
          }
          // repopulate store devices, to be sure data is up-to-date
          await this.populateDevices(storeId);
          await this.fetchStoreDetails(storeId);
        } else {
          console.error(response.data);
        }
      } finally {
        this.loadingIndicator.update = false;
      }
    },
    async deleteGondola(gondolaId: string, storeId: string): Promise<void> {
      this.loadingIndicator.delete = true;
      const store: Optional<StoreModel> = this.getCurrentStore(storeId);
      if (!store) {
        return;
      }
      const response: AxiosResponse<{ hash: string }> = await axios.delete(
        `${Config.getApiUrl()}/stores/${storeId}/gondola/${gondolaId}`,
        {
          params: {
            hash: store.hash,
            azureStoreId: store.idAzure,
          },
        }
      );
      if (response.status === 200) {
        const store: Optional<StoreModel> = this.getCurrentStore(storeId);
        if (store) {
          store.hash = response.data.hash;
          for (const aisle of store.aisles) {
            if (aisle.gondolas.some((value: Gondola) => value._id == gondolaId)) {
              aisle.gondolas = aisle.gondolas.filter((gondola: Gondola) => gondola._id !== gondolaId);
              this.updateStores([store]);
            }
          }
        }
      } else {
        console.error(response.data);
      }
      this.loadingIndicator.delete = false;
    },
    async populateDevices(storeId: string): Promise<void> {
      const devicesStore: DevicesStore = useDevicesStore();
      const store: Optional<StoreModel> = this.getCurrentStore(storeId);
      if (!store) {
        return;
      }
      if (store?.idAzure) {
        await devicesStore.fetch(storeId);
        this.populateStoreGondolas(store);
      }
      this.updateStores([store]);
    },
    async syncDevices(storeId: string): Promise<void> {
      const store: Optional<StoreModel> = this.getCurrentStore(storeId);
      if (store?.idAzure) {
        this.isFetching = true;
        const response: AxiosResponse<void> = await axios.post(`${Config.getApiUrl()}/stores/${storeId}/sync-devices`, {
          params: {
            storeId,
            sync: true,
          },
        });
        if (response.status >= 400) {
          console.debug(`Received error response when trying to retrieve devices for storeId ${storeId}`);
          this.isFetching = false;
          return;
        }
        this.isFetching = false;
        this.populateStoreGondolas(store);
      }
    },
    async updateReleaseManagement(
      releaseManagementId: string,
      releaseManagementHash: string,
      storeId: string
    ): Promise<void> {
      this.setUpdatingStoreLoadingIndicator(true);
      const response: AxiosResponse<ReleaseManagementAssignmentResponseJSON> = await axios.post(
        `${Config.getApiUrl()}/stores/${storeId}/release-management`,
        {
          releaseManagementId: releaseManagementId,
          releaseManagementHash: releaseManagementHash,
          storeHash: this.getCurrentStore(storeId)?.hash,
        }
      );
      if (response.status === 200) {
        const store: Optional<StoreModel> = this.getCurrentStore(storeId);
        if (!store) {
          return;
        }
        const releaseManagementStore: ReleaseManagementStore = useReleaseManagementStore();

        await releaseManagementStore.updateReleaseConfigurationHash(
          releaseManagementId,
          response.data.releaseManagementHash
        );

        if (response.data.previousReleaseManagement?.id && response.data.previousReleaseManagement?.hash) {
          await releaseManagementStore.updateReleaseConfigurationHash(
            response.data.previousReleaseManagement.id,
            response.data.previousReleaseManagement.hash
          );
        }
        const releaseManagement: Optional<ReleaseManagement> =
          releaseManagementStore.getReleaseConfigurationById(releaseManagementId);
        if (store && releaseManagement) {
          store.hash = response.data.storeHash;
          store.releaseManagementReference = {
            id: releaseManagement._id,
            name: releaseManagement.name,
          };
        }
        this.updateStores([store]);
        this.setUpdatingStoreLoadingIndicator(false);
      } else {
        console.debug(`Received error response when trying to update release management config for storeId ${storeId}`);
        return;
      }
    },
    async updateEmailSubscriptionState(useEmailSubscription: boolean, storeId: string): Promise<void> {
      this.setUpdatingStoreLoadingIndicator(true);
      const response: AxiosResponse<StoreResponseJSON> = await axios.patch(
        `${Config.getApiUrl()}/stores/${storeId}`,
        {
          useEmailSubscription: useEmailSubscription,
        },
        {
          params: {
            hash: this.getCurrentStore(storeId)?.hash,
          },
        }
      );

      if (response.status === 200 || response.status === 204) {
        const store: Optional<StoreModel> = this.getCurrentStore(storeId);
        if (!store) {
          return;
        }
        store.useEmailSubscription = useEmailSubscription;
        store.hash = response.data.hash;
        this.updateStores([store]);
        this.setUpdatingStoreLoadingIndicator(false);
      } else {
        console.warn(`Unhandled response status for updateEmailSubscriptionState ${response.status}`);
      }
    },
    populateStoreGondolas(store: StoreModel): void {
      const devicesStore: DevicesStore = useDevicesStore();
      const devices: Array<Device> = devicesStore.getDevicesByStoreId(store._id);
      store.gondolas.forEach((gondola: GondolaModel) => {
        gondola.railGrid = gondola.railGrid.map((row: Array<Device>) =>
          row.map((gondolaRail: Device) => {
            return (
              (gondolaRail.shortId && devices.find((device: Device) => device.shortId === gondolaRail.shortId)) ||
              gondolaRail
            );
          })
        );
      });
    },
    setStoreSyncingState(storeId: string, isStoreSyncing: boolean): void {
      const store: Optional<StoreModel> = this.getCurrentStore(storeId);
      if (!store) {
        return;
      }
      store.setStoreSyncing(isStoreSyncing);
      this.updateStores([store]);
    },
    async addSectionsToAisle(
      storeId: string,
      aisleName: string,
      sections: Array<GondolaModel>,
      isForced: boolean = false
    ): Promise<void> {
      this.loadingIndicator.update = true;
      const store: Optional<StoreModel> = this.getCurrentStore(storeId);
      if (!store) {
        return;
      }
      try {
        const response: AxiosResponse<AddOrUpdateSectionsResultJSON> = await axios.post(
          `${Config.getApiUrl()}/stores/${storeId}/sections`,
          {
            sections: sections,
          },
          {
            params: {
              hash: store.hash,
              force: isForced,
            },
          }
        );
        this.loadingIndicator.update = false;
        if (response.status === 200) {
          store.hash = response.data.store.hash;
          store.gondolas.push(...sections);
          this.updateStores([store]);
          const aisle: Optional<Aisle> = store.aisles.find((aisle: Aisle) => aisle.name === aisleName);
          if (aisle) {
            aisle.gondolas.push(...sections);
          } else {
            store.aisles.push(new Aisle(aisleName, sections));
          }
        } else {
          console.error(response.data);
        }
      } catch (e: unknown) {
        this.loadingIndicator.update = false;
      }
    },
    async addAisle(storeId: string, aisle: Aisle): Promise<void> {
      this.loadingIndicator.update = true;
      const currentStore: Optional<StoreModel> = this.getCurrentStore(storeId);
      if (!currentStore) {
        return;
      }
      return await axios
        .post(`${Config.getApiUrl()}/stores/${storeId}/aisle`, Aisle.toJSON(aisle), {
          params: {
            hash: currentStore.hash,
          },
        })
        .then((response: AxiosResponse<StoreResponseJSON>) => {
          if (response.status === 200) {
            currentStore.hash = response.data.hash;
            currentStore.aisles = response.data.aisles.map(Aisle.fromJSON);
            this.updateStores([currentStore]);
          } else {
            console.error(response.data);
          }
        })
        .finally(() => {
          this.loadingIndicator.update = false;
        });
    },
    async renameAisle(storeId: string, oldAisleName: string, newAisleName: string): Promise<void> {
      this.loadingIndicator.update = true;
      const currentStore: Optional<StoreModel> = this.getCurrentStore(storeId);
      if (!currentStore) {
        return;
      }
      return await axios
        .patch(`${Config.getApiUrl()}/stores/${storeId}/aisle/${oldAisleName}`, Aisle.toJSON(new Aisle(newAisleName)), {
          params: {
            hash: currentStore.hash,
          },
        })
        .then((response: AxiosResponse<StoreResponseJSON>) => {
          if (response.status === 200) {
            currentStore.hash = response.data.hash;
            currentStore.aisles = response.data.aisles.map(Aisle.fromJSON);
            this.updateStores([currentStore]);
          } else {
            console.error(response.data);
          }
        })
        .finally(() => {
          this.loadingIndicator.update = false;
        });
    },
    async deleteAisle(storeId: string, aisle: string): Promise<void> {
      this.loadingIndicator.delete = true;
      const currentStore: Optional<StoreModel> = this.getCurrentStore(storeId);
      if (!currentStore) {
        return;
      }
      return await axios
        .delete(`${Config.getApiUrl()}/stores/${storeId}/aisle/${aisle}`, {
          params: {
            hash: currentStore.hash,
          },
        })
        .then((response: AxiosResponse<StoreResponseJSON>) => {
          if (response.status === 200) {
            currentStore.hash = response.data.hash;
            currentStore.aisles = response.data.aisles.map(Aisle.fromJSON);
            this.updateStores([currentStore]);
          } else {
            console.error(response.data);
          }
        })
        .finally(() => {
          this.loadingIndicator.delete = false;
        });
    },
    updateStoreActiveHours(storeId: string, activeHours: ActiveHoursSchedule): void {
      const store: Optional<StoreModel> = this.getStoreById(storeId);
      if (store) {
        store.activeHours = activeHours;
      }
    },
    updateGondolaActiveHours(storeId: string, gondolaId: string, activeHours: ActiveHoursSchedule): void {
      const store: Optional<StoreModel> = this.getStoreById(storeId);
      if (!store) {
        return;
      }
      const gondola: Optional<GondolaModel> = store.gondolas.find((gondola: GondolaModel) => gondola._id === gondolaId);
      if (!gondola) {
        return;
      }
      gondola.activeHours = activeHours;
      const aisleGondola: Optional<GondolaModel> = store.aisles
        .flatMap((aisle: Aisle) => aisle.gondolas)
        .find((gondola: GondolaModel) => gondola._id === gondolaId);
      if (!aisleGondola) {
        return;
      }
      aisleGondola.activeHours = activeHours;
    },
    updateGondolaMap(store: StoreModel): void {
      store.gondolas.forEach((gondola: Gondola) => {
        this.gondolaStoreMap.set(gondola._id, store._id);
      });
    },
    deleteGondolaMap(store: StoreModel): void {
      store.gondolas.forEach((gondola: Gondola) => {
        this.gondolaStoreMap.delete(gondola._id);
      });
    },
    clearCurrentStore(): void {
      this.currentStore = null;
    },
    async validateStoreAzureId(storeAzureId: string): Promise<Array<Device>> {
      this.loadingIndicator.update = true;
      try {
        const response: AxiosResponse<Array<DeviceJSON>> = await axios.get(
          `${Config.getApiUrl()}/stores/validate/${storeAzureId}`
        );
        this.loadingIndicator.update = false;
        if (response.status === 200 || response.status === 204) {
          return response.data.map(Device.fromJSON);
        }
        return [];
      } catch (e: unknown) {
        this.loadingIndicator.update = false;
        throw e;
      }
    },
    async cloneAisle(storeId: string, storeHash: string, aisleId: string, newAisleName: string): Promise<void> {
      const payload: { name: string } = {
        name: newAisleName,
      };
      const store: Optional<StoreModel> = this.getStoreById(storeId);
      if (!store) {
        return;
      }
      this.loadingIndicator.clone = true;
      return await axios
        .post(`${Config.getApiUrl()}/stores/${storeId}/aisle/${aisleId}/clone`, payload, {
          params: { hash: storeHash },
        })
        .then((response: AxiosResponse<StoreResponseJSON>) => {
          if (response.status === 200) {
            store.hash = response.data.hash;
            store.aisles = response.data.aisles.map(Aisle.fromJSON);
          } else {
            console.error(response.data);
          }
        })
        .finally(() => {
          this.loadingIndicator.clone = false;
        });
    },
    async cloneSection(
      storeId: string,
      storeHash: string,
      sourceSectionId: string,
      newSectionName: string,
      newSectionAisle: string
    ): Promise<void> {
      const store: Optional<StoreModel> = this.getStoreById(storeId);
      if (!store) {
        return;
      }
      this.loadingIndicator.clone = true;
      return await axios
        .post(
          `${Config.getApiUrl()}/stores/${storeId}/section/${sourceSectionId}/clone`,
          { newSectionAisle, newSectionName },
          {
            params: { hash: storeHash },
          }
        )
        .then((response: AxiosResponse<StoreResponseJSON>) => {
          if (response.status === 200) {
            store.hash = response.data.hash;
            store.aisles = response.data.aisles.map(Aisle.fromJSON);
          } else {
            console.error(response.data);
          }
        })
        .finally(() => {
          this.loadingIndicator.clone = false;
        });
    },
  },
});
