
import { Component, Prop, Vue } from 'vue-property-decorator';
import { BackgroundContent, ForegroundContent, HardwareModel } from '@client/models';
import BackgroundPreview from '@client/components/Preview/BackgroundPreview.vue';
import ForegroundPreview from '@client/components/Preview/ForegroundPreview.vue';
import SmartPreview from '@client/components/SmartPreview/SmartPreview.vue';
import DevicePublishingBackgroundContentModel from '@client/models/DeviceModels/DevicePublishingBackgroundContentModel';
import DevicePublishingForegroundContentModel from '@client/models/DeviceModels/DevicePublishingForegroundContentModel';

@Component({
  components: { SmartPreview, ForegroundPreview, BackgroundPreview },
})
export default class Preview extends Vue {
  @Prop()
  private backgroundContentItem?: DevicePublishingBackgroundContentModel | BackgroundContent;
  @Prop()
  private foregroundContentItems!: Array<DevicePublishingForegroundContentModel | ForegroundContent>;
  @Prop()
  private hardwareModel!: HardwareModel;
  @Prop()
  private selectedSchedule!: string;

  private isBackgroundLoaded: boolean = false;

  created(): void {
    // If there is no background content flag the background as loaded
    this.isBackgroundLoaded = !this.backgroundContentItem?.baseLayer;
  }

  private onPreviewBackgroundLoaded(): void {
    this.isBackgroundLoaded = true;
  }

  private onPreviewBackgroundUnloaded(): void {
    this.isBackgroundLoaded = false;
  }

  /**
   * Returns the height of the current device
   */
  get railHeight() {
    return this.hardwareModel.height * this.hardwareModel.railScaleFactor;
  }
}
