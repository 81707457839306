
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Device from '@client/components/Device/Device.vue';
import ModalDialog from '../ModalDialog/ModalDialog.vue';
import {
  BaseLayer,
  DeviceTemplate,
  GondolaTemplate,
  HardwareModel,
  ScheduledContent,
  ScheduleLayer,
} from '@client/models';
import { default as TemplateDevice } from '@client/components/Device/DeviceTemplate.vue';
import DeviceGroup from '@client/models/DeviceModels/DeviceGroup.model';
import { SchedulesStore, useSchedulesStore } from '@client/stores/schedules';
import { Optional } from '@common/types';
import ScheduleModel from '@client/models/ScheduleModels/Schedule.model';
import { DeviceCanvas } from '@client/components';
import DeviceLayerList from '@client/components/Device/CurrentSelectedContentDetails/DeviceLayerList.vue';
import ContentPreview from '../Device/CurrentSelectedContentDetails/ContentPreview.vue';
import {
  DeviceContentSelection,
  GondolaTemplatesStore,
  useGondolaTemplatesStore,
} from '@client/stores/gondolaTemplates';
import QuickApplyBackground from '@client/components/GondolaTemplate/QuickApplyBackground.vue';
import { DraggableDevice } from '@client/definitions/DraggableDevice';
import ScheduleDetails from '@client/components/GondolaTemplate/ScheduleDetails.vue';
import EditContentModal from '@client/components/Device/CurrentSelectedContentDetails/EditContentModal.vue';

@Component({
  components: {
    EditContentModal,
    ScheduleDetails,
    QuickApplyBackground,
    DeviceLayerList,
    DeviceCanvas,
    TemplateDevice,
    Device,
    ModalDialog,
    ContentPreview,
  },
})
export default class GondolaTemplateContentEdit extends Vue {
  @Prop()
  private gondolaTemplate!: GondolaTemplate;
  @Prop({ default: false })
  private showCurrentlyDisplayedSchedule!: boolean;

  private schedulesStore: SchedulesStore = useSchedulesStore();
  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();

  private rerenderKey: number = 0;
  private showDeleteDeviceDialog: boolean = false;
  private showContentItemDialog: boolean = false;

  private isCanvasInViewport: boolean = false;

  private sizes: HardwareModel[] = HardwareModel.getAll();

  private draggingDevice: DraggableDevice | DeviceTemplate | null = null;
  private draggingDeviceIndexes: { row: number; col: number } | null = null;
  private devices: Array<DraggableDevice> = [
    { name: 'VP1080P', class: 'toolbar-vp1080p' },
    { name: 'VR06001', class: 'toolbar-vr06001' },
    { name: 'VR09001', class: 'toolbar-vr09001' },
    { name: 'VR12001', class: 'toolbar-vr12001' },
  ];
  private hoverState: { row: number; col: number; id: string } | null = null;

  private selectedDevice: DeviceTemplate | null = null;
  private selectedDeviceIndexes: { row: number; col: number } | null = null;

  private isEditContentMode: boolean = false;

  private railGrid: Array<Array<DeviceTemplate>> = [];

  private cancelHandler = () => {
    // Will be overridden later
  };
  private confirmHandler = () => {
    // Will be overridden later
  };

  beforeDestroy() {
    document.body.style.setProperty('--device-scale', '1');
  }

  created() {
    this.railGrid = this.gondolaTemplate.railGrid;
    document.body.style.setProperty('--device-scale', '0.5');
    document.addEventListener('keyup', (event: KeyboardEvent) => {
      if (!this.isEditContentMode) {
        switch (event.code) {
          case 'ArrowUp':
            if (this.selectedDeviceIndexes && this.selectedDeviceIndexes.row !== 0 && this.selectedDevice) {
              this.selectedDeviceIndexes.row -= 1;
              this.selectedDevice = this.railGrid[this.selectedDeviceIndexes.row][this.selectedDeviceIndexes.col];
            }
            break;
          case 'ArrowDown':
            if (
              this.selectedDeviceIndexes &&
              this.selectedDeviceIndexes.row !== this.railGrid.length - 1 &&
              this.selectedDevice
            ) {
              this.selectedDeviceIndexes.row += 1;
              this.selectedDevice = this.railGrid[this.selectedDeviceIndexes.row][this.selectedDeviceIndexes.col];
            }
            break;
          case 'ArrowLeft':
            if (this.selectedDeviceIndexes && this.selectedDeviceIndexes.col !== 0 && this.selectedDevice) {
              this.selectedDeviceIndexes.col -= 1;
              this.selectedDevice = this.railGrid[this.selectedDeviceIndexes.row][this.selectedDeviceIndexes.col];
            }
            break;
          case 'ArrowRight':
            if (
              this.selectedDeviceIndexes &&
              this.selectedDeviceIndexes.col !== this.railGrid[this.selectedDeviceIndexes.row].length - 1 &&
              this.selectedDevice
            ) {
              this.selectedDeviceIndexes.col += 1;
              this.selectedDevice = this.railGrid[this.selectedDeviceIndexes.row][this.selectedDeviceIndexes.col];
            }
            break;
        }
      }
      if (event.code === 'Escape') {
        if (this.isEditContentMode) {
          this.isEditContentMode = false;
        } else {
          this.unselectDevice();
        }
      }
    });
  }

  @Watch('gondolaTemplate', { immediate: true, deep: true })
  onGondolaTemplateRailGridChange(): void {
    this.railGrid = this.gondolaTemplate.railGrid;
    if (this.selectedDeviceIndexes) {
      this.selectedDevice = this.railGrid[this.selectedDeviceIndexes.row][this.selectedDeviceIndexes.col];
    }
  }

  private handleOnIntersect(
    _entries: Array<IntersectionObserverEntry>,
    _observer: IntersectionObserver,
    isIntersecting: boolean
  ) {
    this.isCanvasInViewport = isIntersecting;
  }

  async deleteDevice(): Promise<void> {
    if (this.isDeleteDeviceDisabled || !this.selectedDeviceIndexes) {
      return;
    }
    const { row, col }: { row: number; col: number } = this.selectedDeviceIndexes;
    this.railGrid[row].splice(col, 1);

    if (this.railGrid[row].length === 0) {
      this.railGrid.splice(row, 1);
    }
    this.$emit('rail-grid-change', this.railGrid);
    this.unselectDevice();
  }

  setSelectedDevice(device: DeviceTemplate, rowIndex: number, colIndex: number): void {
    this.selectedDevice = device;
    this.selectedDeviceIndexes = { row: rowIndex, col: colIndex };
  }

  unselectDevice() {
    this.selectedDevice = null;
    this.selectedDeviceIndexes = null;
    this.isEditContentMode = false;
  }

  isDeviceSelected(rowIndex: number, colIndex: number): boolean {
    if (!this.selectedDeviceIndexes) {
      return false;
    }
    return this.selectedDeviceIndexes.row === rowIndex && this.selectedDeviceIndexes.col === colIndex;
  }

  onDragStart(device: DraggableDevice, event: DragEvent) {
    this.draggingDevice = device;

    const dragImage: HTMLElement = document.querySelector('.drag-image') as HTMLElement;
    const dragImageText: HTMLElement = dragImage.querySelector('.drag-image__text') as HTMLElement;
    dragImageText.innerText = device.name;

    event.dataTransfer?.setDragImage(dragImage, 0, 0);
  }

  onDragStartDevice(device: DeviceTemplate, rowIndex: number, colIndex: number, event: DragEvent) {
    this.draggingDevice = device;
    this.draggingDeviceIndexes = { row: rowIndex, col: colIndex };

    const dragImage: HTMLElement = document.querySelector('.drag-image') as HTMLElement;
    const dragImageText: HTMLElement = dragImage.querySelector('.drag-image__text') as HTMLElement;
    dragImageText.innerText = device.hardwareModel.identifier;

    event.dataTransfer?.setDragImage(dragImage, 0, 0);
  }

  onDragEnd() {
    this.draggingDevice = null;
    this.draggingDeviceIndexes = null;
    this.hoverState = null;
  }

  onDragEnter(rowIndex: number, colIndex: number, id: string) {
    this.hoverState = { row: rowIndex, col: colIndex, id: id };
  }

  onDragLeave(id: string) {
    if (this.hoverState?.id === id) {
      this.hoverState = null;
    }
  }

  isHovering(rowIndex: number, colIndex: number, id: string): boolean {
    if (!this.hoverState) {
      return false;
    }
    return this.hoverState.row === rowIndex && this.hoverState.col === colIndex && this.hoverState.id === id;
  }

  handleDropToRight(rowIndex: number, colIndex: number) {
    let deviceTemplateToDrop: DeviceTemplate = new DeviceTemplate();
    if (!this.draggingDevice) {
      return;
    }
    if (this.draggingDevice instanceof DeviceTemplate) {
      deviceTemplateToDrop = this.draggingDevice;
      this.removeDeviceFromOldPosition();
    } else {
      deviceTemplateToDrop.hardwareModel = HardwareModel.getByIdentifier(this.draggingDevice.name.toUpperCase());
    }

    if (!this.railGrid[rowIndex]) {
      this.railGrid[rowIndex] = [];
    }

    if (colIndex >= this.railGrid[rowIndex].length) {
      this.railGrid[rowIndex].push(deviceTemplateToDrop);
      this.setSelectedDevice(deviceTemplateToDrop, rowIndex, this.railGrid[rowIndex].length - 1);
    } else {
      this.railGrid[rowIndex].splice(colIndex, 0, deviceTemplateToDrop);
      this.setSelectedDevice(deviceTemplateToDrop, rowIndex, colIndex);
    }

    this.$emit('rail-grid-change', this.railGrid);
    this.draggingDevice = null;
    this.hoverState = null;
    this.rerenderKey++;
  }

  handleDropToLeft(rowIndex: number, colIndex: number) {
    let deviceTemplateToDrop: DeviceTemplate = new DeviceTemplate();
    if (!this.draggingDevice) {
      return;
    }
    if (this.draggingDevice instanceof DeviceTemplate) {
      deviceTemplateToDrop = this.draggingDevice;
      this.removeDeviceFromOldPosition();
    } else {
      deviceTemplateToDrop.hardwareModel = HardwareModel.getByIdentifier(this.draggingDevice.name.toUpperCase());
    }

    if (colIndex < 0) {
      if (!this.railGrid[rowIndex]) {
        this.railGrid[rowIndex] = [];
      }
      this.railGrid[rowIndex].unshift(deviceTemplateToDrop);
      this.setSelectedDevice(deviceTemplateToDrop, rowIndex, 0);
    } else if (this.railGrid[rowIndex][colIndex]) {
      this.railGrid[rowIndex].splice(colIndex + 1, 0, deviceTemplateToDrop);
      this.setSelectedDevice(deviceTemplateToDrop, rowIndex, colIndex + 1);
    } else {
      this.railGrid[rowIndex].splice(colIndex, 0, deviceTemplateToDrop);
      this.setSelectedDevice(deviceTemplateToDrop, rowIndex, colIndex);
    }

    this.$emit('rail-grid-change', this.railGrid);
    this.draggingDevice = null;
    this.hoverState = null;
    this.rerenderKey++;
  }

  handleDropToBottom(rowIndex: number) {
    let deviceTemplateToDrop: DeviceTemplate = new DeviceTemplate();
    if (!this.draggingDevice) {
      return;
    }
    if (this.draggingDevice instanceof DeviceTemplate) {
      deviceTemplateToDrop = this.draggingDevice;
      this.removeDeviceFromOldPosition();
    } else {
      deviceTemplateToDrop.hardwareModel = HardwareModel.getByIdentifier(this.draggingDevice.name.toUpperCase());
    }

    if (rowIndex >= this.railGrid.length) {
      this.railGrid.push([deviceTemplateToDrop]);
      this.setSelectedDevice(deviceTemplateToDrop, this.railGrid.length - 1, 0);
    } else {
      this.railGrid.splice(rowIndex, 0, [deviceTemplateToDrop]);
      this.setSelectedDevice(deviceTemplateToDrop, rowIndex, 0);
    }

    this.$emit('rail-grid-change', this.railGrid);
    this.draggingDevice = null;
    this.hoverState = null;
    this.rerenderKey++;
  }

  handleDropToTop(rowIndex: number) {
    let deviceTemplateToDrop: DeviceTemplate = new DeviceTemplate();
    if (!this.draggingDevice) {
      return;
    }
    if (this.draggingDevice instanceof DeviceTemplate) {
      deviceTemplateToDrop = this.draggingDevice;
      this.removeDeviceFromOldPosition();
    } else {
      deviceTemplateToDrop.hardwareModel = HardwareModel.getByIdentifier(this.draggingDevice.name.toUpperCase());
    }

    if (rowIndex < 0) {
      this.railGrid.unshift([deviceTemplateToDrop]);
      this.setSelectedDevice(deviceTemplateToDrop, 0, 0);
    } else if (this.railGrid[rowIndex].length > 0) {
      this.railGrid.splice(rowIndex + 1, 0, [deviceTemplateToDrop]);
      this.setSelectedDevice(deviceTemplateToDrop, rowIndex + 1, 0);
    } else {
      this.railGrid.splice(rowIndex === 0 ? 1 : rowIndex, 0, [deviceTemplateToDrop]);
      this.setSelectedDevice(deviceTemplateToDrop, rowIndex === 0 ? 1 : rowIndex, 0);
    }

    this.$emit('rail-grid-change', this.railGrid);
    this.draggingDevice = null;
    this.hoverState = null;
    this.rerenderKey++;
  }

  removeDeviceFromOldPosition() {
    if (!this.draggingDeviceIndexes) {
      return;
    }
    const { row, col }: { row: number; col: number } = this.draggingDeviceIndexes;
    this.railGrid[row].splice(col, 1);
    if (this.railGrid[row].length === 0) {
      this.railGrid.splice(row, 1);
    }
  }

  changeSize(element: string): void {
    if (!this.selectedDevice || !this.selectedDeviceIndexes) {
      return;
    }
    const { row, col }: { row: number; col: number } = this.selectedDeviceIndexes;
    const hardwareModel: HardwareModel = HardwareModel.getByIdentifier(element);
    this.selectedDevice.hardwareModel = hardwareModel;
    this.railGrid[row][col].hardwareModel = hardwareModel;
    this.$emit('rail-grid-change', this.railGrid);
    this.rerenderKey++;
  }

  changeDeviceGroup(railGroup: DeviceGroup): void {
    if (!this.selectedDevice || !this.selectedDeviceIndexes) {
      return;
    }
    const { row, col }: { row: number; col: number } = this.selectedDeviceIndexes;
    this.selectedDevice.railGroup = railGroup;
    this.railGrid[row][col].railGroup = railGroup;
    this.$emit('rail-grid-change', this.railGrid);
    this.rerenderKey++;
  }

  emitRailGridChange(): void {
    this.$emit('rail-grid-change', this.railGrid);
  }

  closeContentItemDialog(): void {
    this.showContentItemDialog = false;
  }

  openContentItemDialog(): void {
    this.showContentItemDialog = true;
  }

  setSelectedDeviceAndEdit(device: DeviceTemplate, rowIndex: number, colIndex: number): void {
    this.setSelectedDevice(device, rowIndex, colIndex);
    this.isEditContentMode = true;
  }

  get getDeviceGroups(): DeviceGroup[] {
    return DeviceGroup.DeviceGroups;
  }

  get isDeleteDeviceDisabled(): boolean {
    return (
      !this.selectedDevice ||
      (this.selectedDeviceIndexes?.col === 0 &&
        this.selectedDeviceIndexes?.row === 0 &&
        this.railGrid.length === 1 &&
        this.railGrid[0].length === 1)
    );
  }

  get selectedSchedule(): Optional<ScheduleModel> {
    const selectedScheduleId: string = this.schedulesStore.getSelectedSchedule();
    if (!selectedScheduleId) {
      return null;
    }
    return this.schedulesStore.getById(selectedScheduleId);
  }

  get deviceContentSelection(): DeviceContentSelection {
    return this.gondolaTemplatesStore.deviceContentSelection;
  }

  get selectedLayer(): ScheduleLayer | BaseLayer | undefined {
    if (!this.selectedDevice) {
      return undefined;
    }
    if (this.deviceContentSelection.isForeground) {
      if (this.selectedSchedule) {
        const scheduleContent: ScheduledContent | undefined = this.selectedDevice.foregroundContent[
          this.deviceContentSelection.index
        ]?.scheduledContent?.find(
          (schedule: ScheduledContent) => schedule.scheduleId === this.schedulesStore.getSelectedSchedule()
        );
        if (scheduleContent) {
          return scheduleContent?.layer;
        }
      }
      return this.selectedDevice.foregroundContent[this.deviceContentSelection.index]?.baseLayer;
    } else {
      if (this.selectedSchedule) {
        const scheduleContent: ScheduledContent | undefined =
          this.selectedDevice.backgroundContent?.[0]?.scheduledContent?.find(
            (schedule: ScheduledContent) => schedule.scheduleId === this.schedulesStore.getSelectedSchedule()
          );
        if (scheduleContent) {
          return scheduleContent?.layer;
        }
      }
      return this.selectedDevice.backgroundContent?.[0]?.baseLayer;
    }
  }
}
