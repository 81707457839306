
import { Component, Prop, Vue } from 'vue-property-decorator';
import AffectedStoresModal from '@client/components/Settings/ReleaseManagement/AffectedStoresModal.vue';
import ReleaseManagement from '@client/models/SettingsModels/ReleaseManagement.model';

@Component({
  components: { AffectedStoresModal },
})
export default class DeleteButton extends Vue {
  @Prop()
  private isDisabled!: boolean;
  @Prop()
  private releaseManagement!: ReleaseManagement;

  private showDialog: boolean = false;

  closeDialog(): void {
    this.showDialog = false;
  }
}
