import { defineStore, Store, StoreDefinition } from 'pinia';
import { TranslateResult } from 'vue-i18n';
import { v4 } from 'uuid';

const MESSAGE_QUEUE_TIMEOUT: number = 5000;
const MAX_DISPLAYED_MESSAGES: number = 3;

export interface MessagesActions {
  showMessage(message: string | TranslateResult): void;
  applyRemoveMessageFromQueue(): void;
  removeMessageFromQueue(): void;
  removeMessageById(id: string): void;
}

export interface DisplayedMessage {
  message: string | TranslateResult;
  id: string;
}

export interface MessagesState {
  displayedMessages: Array<DisplayedMessage>;
  timeout?: ReturnType<typeof setTimeout>;
}

export type MessagesStoreDefinition = StoreDefinition<
  'messages',
  MessagesState,
  Record<string, never>,
  MessagesActions
>;

export type MessagesStore = Store<'messages', MessagesState, Record<string, never>, MessagesActions>;

export const useMessagesStore: MessagesStoreDefinition = defineStore('messages', {
  state: (): MessagesState => ({
    displayedMessages: new Array<DisplayedMessage>(),
    timeout: undefined,
  }),
  actions: {
    applyRemoveMessageFromQueue() {
      this.displayedMessages.pop();
    },
    showMessage(message: string) {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = undefined;
      }
      this.timeout = setTimeout(() => {
        this.removeMessageFromQueue();
      }, MESSAGE_QUEUE_TIMEOUT);
      this.displayedMessages.unshift({ message, id: v4() });
      if (this.displayedMessages.length > MAX_DISPLAYED_MESSAGES) {
        this.displayedMessages.pop();
      }
    },
    removeMessageFromQueue() {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = undefined;
      }
      if (this.displayedMessages.length > 1) {
        this.timeout = setTimeout(() => {
          this.removeMessageFromQueue();
        }, MESSAGE_QUEUE_TIMEOUT);
      }
      this.applyRemoveMessageFromQueue();
    },
    removeMessageById(id: string) {
      const index: number = this.displayedMessages.findIndex((message: DisplayedMessage) => message.id === id);
      if (index !== -1) {
        this.displayedMessages.splice(index, 1);
      }
    },
  },
});
