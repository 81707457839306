import VueRouter from 'vue-router';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { templateRoutes } from '@client/router/TemplateRoutes';
import { storeRoutes } from '@client/router/StoreRoutes';
import { RouteConfig } from 'vue-router/types/router';
import { ErrorResponse, ErrorType } from '@common/error/types';
import { ErrorStore, useErrorStore } from '@client/stores/error';
import { errorRoutes } from '@client/router/ErrorRoutes';
import { deviceRoutes } from '@client/router/DeviceRoutes';
import { settingsRoutes } from '@client/router/SettingsRoutes';
import { sensorRoutes } from '@client/router/SensorRoutes';

const routes: RouteConfig[] = [
  ...templateRoutes,
  ...storeRoutes,
  ...deviceRoutes,
  ...settingsRoutes,
  ...sensorRoutes,
  ...errorRoutes,
];

const router: VueRouter = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// Interceptor to handle any error
axios.interceptors.response.use(
  function (response: AxiosResponse) {
    return response;
  },
  async function (error: AxiosError<ErrorResponse>) {
    const errorStore: ErrorStore = useErrorStore();
    const errorType: ErrorType = error?.response?.data?.errorType || ErrorType.UNKNOWN_ERROR;
    if (error.message === 'jwt not active') {
      await router.replace(router.currentRoute.fullPath);
    }
    // call specific handlers. If there is no specific handler, notify default handler
    if (errorType.name === ErrorType.INVALID_AUTHENTICATION.name) {
      errorStore.setErrorType(errorType.name);
      if (router.currentRoute.name === 'error') {
        return Promise.reject(error);
      }
      await router.replace('/error');
      return Promise.reject(error);
    }
    errorStore.notify({ errorType: errorType, error: error });
    return Promise.reject(error);
  }
);

export default router;
