
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ReleaseManagementStore, useReleaseManagementStore } from '@client/stores/releaseManagement/store';
import ReleaseManagement from '@client/models/SettingsModels/ReleaseManagement.model';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import { Optional } from '@common/types';
import ReleaseManagementTable from '@client/components/Settings/ReleaseManagement/ReleaseManagementTable.vue';
import ModalHeader from '@client/components/Layouts/ModalHeader.vue';

@Component({
  components: { ModalHeader, ReleaseManagementTable },
})
export default class AssignReleaseManagementToStore extends Vue {
  @Prop()
  private storeId!: string;
  @Prop()
  private currentReleaseManagementId: Optional<string>;
  @Prop()
  private isLoading!: boolean;

  private releaseManagementStore: ReleaseManagementStore = useReleaseManagementStore();
  private storesStore: StoresStore = useStoresStore();

  private showDialog: boolean = false;

  closeDialog(): void {
    this.showDialog = false;
  }

  async changeReleaseManagement(releaseManagement: ReleaseManagement) {
    this.closeDialog();
    await this.storesStore.updateReleaseManagement(releaseManagement._id, releaseManagement.hash, this.storeId);
  }

  get releaseConfigurations(): Array<ReleaseManagement> {
    return this.releaseManagementStore.getReleaseConfigurations();
  }

  get isConfigEditable(): boolean {
    return this.releaseConfigurations.length > 1;
  }
}
