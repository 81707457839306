
import { Component, Vue } from 'vue-property-decorator';
import { BreadcrumbsStore, useBreadcrumbsStore } from '@client/stores/breadcrumbs';
import AboutTab from '@client/components/Settings/AboutTab.vue';
import TagsTab from '@client/components/Settings/Tabs/TagsTab.vue';
import ReleaseManagementTab from '@client/components/Settings/Tabs/ReleaseManagementTab.vue';

const RELEASE_MANAGEMENT_TAB: string = 'releaseManagement';
const TAGS_TAB: string = 'tags';
const ABOUT_TAB: string = 'about';

@Component({
  components: { AboutTab, ReleaseManagementTab, TagsTab },
})
export default class SettingsView extends Vue {
  /** DECLARATIONS **/
  private breadcrumbsStore: BreadcrumbsStore = useBreadcrumbsStore();
  private activeTab: string = RELEASE_MANAGEMENT_TAB;
  /** LIFE CYCLE EVENTS **/
  async created(): Promise<void> {
    this.breadcrumbsStore.replace({
      path: `${this.$route.fullPath}`,
      title: { key: this.$i18nTranslationKeys.settings.breadcrumb.$path },
    });
    this.breadcrumbsStore.setCurrentPage({ key: this.$i18nTranslationKeys.settings.breadcrumb.$path }, 'mdi-cog', [
      {
        title: { key: this.$i18nTranslationKeys.settings.releaseManagement.title.$path },
        key: 'releaseManagement',
        icon: 'mdi-access-point',
        clickEvent: () => this.setTab(RELEASE_MANAGEMENT_TAB),
        dataCy: 'release-management-tab-cy',
      },
      {
        title: { key: this.$i18nTranslationKeys.settings.tags.title.$path },
        key: 'tags',
        icon: 'mdi-tag',
        clickEvent: () => this.setTab(TAGS_TAB),
        dataCy: 'tags-tab-cy',
      },
      {
        title: { key: this.$i18nTranslationKeys.settings.about.title.$path },
        key: 'about',
        icon: 'mdi-information-outline',
        clickEvent: () => this.setTab(ABOUT_TAB),
        dataCy: 'about-tab-cy',
      },
    ]);
  }
  /** METHODS **/
  setTab(tab: string): void {
    this.activeTab = tab;
  }
  /** GETTERS **/
}
