
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import DatePicker from '@client/components/Filters/DatePicker/DatePicker.vue';

@Component({
  components: { DatePicker },
})
export default class HeaderDateFilter extends Vue {
  @Prop()
  private headerText!: string;
  @Prop()
  private defaultValue!: [string?, string?];

  private dateRangeValue: [string?, string?] = [];

  /**
   * This property indicates that clearing of a filter value will be done through chip displayed above the table
   * If not set, clearing of value will be done inside the v-menu where value is entered.
   */
  @Prop({ default: false })
  private useChips!: boolean;

  @Watch('dateRangeValue')
  emitValueChanged(): void {
    this.$emit('change', this.dateRangeValue);
  }

  @Watch('defaultValue')
  defaultValueChanged(): void {
    this.dateRangeValue = this.defaultValue;
  }

  prefillChange(newValue: string): void {
    this.$emit('prefillChange', newValue);
  }

  get filterButtonColor(): string {
    return !this.useChips && this.dateRangeValue[0] && this.dateRangeValue[1] ? 'primary' : '';
  }
}
