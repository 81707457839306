
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ContentItemsStore, useContentItemsStore } from '@client/stores/contentItems';
import { QueueItem, UploadState } from '@client/stores/contentItems/store';
import { bytesToMegaBytes } from '@client/components/ContentItems/utils';
import AzureBlobService from '@client/plugins/azureblobservice';
import { TranslateResult } from 'vue-i18n';

/**
 * Renders the list of uploaded, or currently uploading, content items.
 */
@Component({})
export default class ContentItemsUploadHistory extends Vue {
  /* DECLARATIONS */
  @Prop()
  private closeUploadHistory!: () => void;

  private contentItemsStore: ContentItemsStore = useContentItemsStore();
  /* LIFECYCLE EVENTS */
  /* METHODS */

  /**
   * Cancels all ongoing uploads, the current file and the files in the queue
   */
  cancelOngoingUploads(): void {
    this.contentItemsStore.clearUploadQueue();
    AzureBlobService.cancelCurrentUpload();
  }

  /**
   * Cancels just the current uploading file
   */
  cancelCurrentUpload(): void {
    AzureBlobService.cancelCurrentUpload();
  }

  /**
   * Removes a file from the upload queue
   * @param index index of the item in the queue
   */
  removeUploadFileFromQueue(index: number): void {
    this.contentItemsStore.removeItemFromQueue(index);
  }

  getFileIcon(file: File): string {
    return file.type.includes('image') ? 'mdi-image' : 'mdi-video';
  }

  getFileSizeInMegaBytes(file: File): string {
    return `${bytesToMegaBytes(file.size).toFixed(2)}MB`;
  }

  getFileProgressPercentage(uploadQueueItem: QueueItem): number {
    if (uploadQueueItem.loadedBytes === 0) {
      return 0;
    }
    return Math.floor((uploadQueueItem.loadedBytes / uploadQueueItem.fileData.file.size) * 100);
  }

  getIsFileUploaded(uploadQueueItem: QueueItem): boolean {
    return uploadQueueItem.uploadState === UploadState.uploaded;
  }

  getIsFileInQueue(uploadQueueItem: QueueItem): boolean {
    return uploadQueueItem.uploadState == UploadState.queued;
  }
  getHasFileUploadError(uploadQueueItem: QueueItem): boolean {
    return uploadQueueItem.uploadState === UploadState.error;
  }
  getIsFileUploadCancelled(uploadQueueItem: QueueItem): boolean {
    return uploadQueueItem.uploadState === UploadState.cancelled;
  }

  getIsFileUploading(uploadQueueItem: QueueItem): boolean {
    return uploadQueueItem.uploadState === UploadState.uploading;
  }

  getFileTooltipValue(uploadQueueItem: QueueItem): TranslateResult {
    switch (uploadQueueItem.uploadState) {
      case UploadState.cancelled:
        return this.$t(this.$i18nTranslationKeys.contentManagement.uploadCancelled.$path);
      case UploadState.uploaded:
        return this.$t(this.$i18nTranslationKeys.contentManagement.uploadedSuccessfully.$path);
      case UploadState.error:
        return this.$t(this.$i18nTranslationKeys.contentManagement.error.$path);
      case UploadState.uploading:
      case UploadState.queued:
      default:
        return this.$t(this.$i18nTranslationKeys.contentManagement.cancelUpload.$path);
    }
  }
  /* GETTERS */
  /**
   * Returns the upload queue, sorted by items being uploaded first
   */
  get uploadQueue(): Array<QueueItem> {
    return this.contentItemsStore.getSortedUploadQueue();
  }

  get uploadQueueProgressPercentage(): number {
    return this.contentItemsStore.getQueueProgressPercentage();
  }

  get isCancelUploadEnabled(): boolean {
    return this.contentItemsStore.getAreItemsStillUploading();
  }
}
