
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  DeviceContentSelection,
  GondolaTemplatesStore,
  useGondolaTemplatesStore,
} from '@client/stores/gondolaTemplates';
import {
  BackgroundContent,
  BaseLayer,
  DeviceTemplate,
  ForegroundContent,
  ScheduledContent,
  ScheduleLayer,
} from '@client/models';
import { SchedulesStore, useSchedulesStore } from '@client/stores/schedules';
import LabelItem from '@client/components/Device/CurrentSelectedContentDetails/LabelItem.vue';
import { Optional } from '@common/types';
import ImageModel from '@client/models/ContentModels/Image.model';
import ModalDialog from '../../ModalDialog/ModalDialog.vue';

/**
 * Contains the list of object available in the canvas/template
 * Contains a button to select the background, and each of the labels
 * Also contains a button to add a label (only if the base layer is selected and number of labels < 12)
 */
@Component({ components: { ModalDialog, LabelItem } })
export default class DeviceLayerList extends Vue {
  @Prop()
  private deviceTemplate!: DeviceTemplate;
  @Prop()
  private rowIndex!: number;
  @Prop()
  private colIndex!: number;
  @Prop()
  private openContentItemDialog!: () => void;

  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  private schedulesStore: SchedulesStore = useSchedulesStore();

  private showDeleteAllScheduledBackgroundDialog: boolean = false;

  async onAddForeground(): Promise<void> {
    const updatedDeviceForeground: Array<ForegroundContent> = this.deviceTemplate.foregroundContent.map(
      (foregroundItem: ForegroundContent) => foregroundItem.clone()
    );
    updatedDeviceForeground.push(ForegroundContent.fromContentItem(ImageModel.getDefaultPlaceHolderContentItem()));
    this.deviceTemplate.foregroundContent = updatedDeviceForeground;
    this.gondolaTemplatesStore.updateDeviceContentSelection({
      isForeground: true,
      index: updatedDeviceForeground.length - 1,
    });
    this.$emit('rail-grid-change');
  }

  updateDeviceContentSelection(isForeground: boolean, index: number): void {
    this.gondolaTemplatesStore.updateDeviceContentSelection({ isForeground, index });
  }

  getLabelClass(index: number): Optional<string> {
    if (index - 1 !== this.deviceContentSelection.index) {
      return '';
    }
    return 'active';
  }
  closeDeleteAllScheduledBackgroundModal(): void {
    this.showDeleteAllScheduledBackgroundDialog = false;
  }

  /**
   * Method used to delete ALL the background content of a device.
   * Will remove the base and the scheduled content
   */
  async onDeleteAllBackgroundContent(): Promise<void> {
    this.deviceTemplate.backgroundContent = [new BackgroundContent(undefined, undefined)];
    this.showDeleteAllScheduledBackgroundDialog = false;
    this.$emit('rail-grid-change');
  }

  onDeleteBackground(): void {
    if (this.selectedSchedule) {
      this.deviceTemplate.backgroundContent[0].scheduledContent = [];
    } else {
      this.showDeleteAllScheduledBackgroundDialog = true;
    }
    this.$emit('rail-grid-change');
  }

  get isLoading(): boolean {
    return this.gondolaTemplatesStore.loadingIndicator.update;
  }

  get deviceContentSelection(): DeviceContentSelection {
    return this.gondolaTemplatesStore.deviceContentSelection;
  }

  get selectedSchedule(): string | undefined {
    return this.schedulesStore.selectedSchedule;
  }

  get isLabelAddButtonEnabled(): boolean {
    return this.deviceTemplate.foregroundContent.length < 12 && !this.selectedSchedule;
  }

  get backgroundClass(): Optional<string> {
    if (this.deviceContentSelection.isForeground) {
      return '';
    }
    return 'active';
  }

  get selectedLayer(): ScheduleLayer | BaseLayer | undefined {
    if (this.deviceContentSelection.isForeground) {
      if (this.selectedSchedule) {
        const scheduleContent: ScheduledContent | undefined = this.deviceTemplate.foregroundContent[
          this.deviceContentSelection.index
        ]?.scheduledContent?.find(
          (schedule: ScheduledContent) => schedule.scheduleId === this.schedulesStore.getSelectedSchedule()
        );
        if (scheduleContent) {
          return scheduleContent?.layer;
        }
      }
      return this.deviceTemplate.foregroundContent[this.deviceContentSelection.index]?.baseLayer;
    } else {
      if (this.selectedSchedule) {
        const scheduleContent: ScheduledContent | undefined =
          this.deviceTemplate.backgroundContent?.[0]?.scheduledContent?.find(
            (schedule: ScheduledContent) => schedule.scheduleId === this.schedulesStore.getSelectedSchedule()
          );
        if (scheduleContent) {
          return scheduleContent?.layer;
        }
      }
      return this.deviceTemplate.backgroundContent?.[0]?.baseLayer;
    }
  }

  get isEditBackgroundButtonDisabled(): boolean {
    if (!this.selectedSchedule) {
      return false;
    }

    return !this.deviceTemplate.backgroundContent?.[0]?.baseLayer;
  }
}
