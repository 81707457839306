
import { Component, Prop, Vue } from 'vue-property-decorator';
import ModalHeader from '@client/components/Layouts/ModalHeader.vue';
import SelectTag from '@client/components/Settings/Tags/Actions/SelectTag.vue';
import TagReference from '@client/models/SettingsModels/TagReference';
import { ContentItemsStore, useContentItemsStore } from '@client/stores/contentItems';
import { ContentItem } from '@client/models/ContentModels/types';
import { TranslateResult } from 'vue-i18n';
import i18n from '@client/plugins/i18n/i18n';
import { TranslationKeys } from '@client/plugins/i18n/locales';

@Component({
  components: { SelectTag, ModalHeader },
})
export default class EditTagsModal extends Vue {
  @Prop()
  private contentItem!: ContentItem;
  @Prop()
  private showDialog!: boolean;
  @Prop()
  private closeDialog!: () => void;

  private selectedTags: Array<TagReference> = [];

  private contentItemsStore: ContentItemsStore = useContentItemsStore();

  updateSelectedTags(selectedTags: Array<TagReference>): void {
    this.selectedTags = selectedTags;
  }

  updateTagFilter(): void {
    this.contentItemsStore.updateContentItemTags(this.contentItem, this.selectedTags);
    this.closeDialog();
  }

  get title(): TranslateResult {
    return i18n.t(TranslationKeys.settings.tags.editTag.$path);
  }
}
