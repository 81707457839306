
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { OnlineStatus } from '@common/enums';
import { TranslateResult } from 'vue-i18n';

@Component({})
export default class HeaderOnlineStatusFilter extends Vue {
  @Prop()
  private headerText!: string;
  @Prop()
  private defaultValue!: OnlineStatus | null;

  private filterValue: OnlineStatus | null = this.defaultValue;

  private filterTypesOptions = (): Array<{ text: TranslateResult; value: OnlineStatus }> => [
    {
      text: this.$t(this.$i18nTranslationKeys.notifications.eventType.online.$path),
      value: OnlineStatus.ONLINE,
    },
    {
      text: this.$t(this.$i18nTranslationKeys.notifications.eventType.offline.$path),
      value: OnlineStatus.OFFLINE,
    },
  ];

  @Watch('filterValue')
  emitValueChanged(): void {
    this.$emit('change', this.filterValue);
  }

  @Watch('defaultValue')
  defaultValueChanged(): void {
    this.filterValue = this.defaultValue;
  }

  clearFilter(): void {
    this.filterValue = null;
  }

  get filterButtonColor(): string {
    return this.filterValue ? 'primary' : '';
  }

  get filterTypes(): Array<{ text: TranslateResult; value: OnlineStatus }> {
    return this.filterTypesOptions();
  }
}
