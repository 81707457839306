
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DeviceTemplate } from '@client/models';
import ScheduledContentModel from '@client/models/ScheduleModels/ScheduledContent.model';
import { LayerVisibility } from '@common/enums';
import ScheduleLayerModel from '@client/models/ScheduleModels/ScheduleLayer.model';
import BaseLayerModel from '@client/models/ScheduleModels/BaseLayer.model';
import { EMPTY_LABEL_PLACEHOLDER } from '@client/models/ContentModels/types';

@Component({})
export default class LabelItem extends Vue {
  @Prop()
  private index!: number;
  @Prop()
  private deviceTemplate!: DeviceTemplate;
  @Prop()
  private selectedSchedule!: string;
  @Prop()
  private openContentItemsDialog!: () => void;

  onDeleteForeground(): void {
    this.deviceTemplate.foregroundContent.splice(this.index, 1);
  }

  onDeleteScheduledForeground(): void {
    if (!this.selectedSchedule || !this.deviceTemplate.foregroundContent[this.index].scheduledContent) {
      return;
    }
    this.deviceTemplate.foregroundContent[this.index].scheduledContent = this.deviceTemplate.foregroundContent[
      this.index
    ].scheduledContent?.filter((schedule: ScheduledContentModel) => schedule.scheduleId !== this.selectedSchedule);
  }

  toggleLabelVisibility(): void {
    if (!this.selectedSchedule) {
      return;
    }
    if (!this.deviceTemplate.foregroundContent[this.index].scheduledContent) {
      this.$set(this.deviceTemplate.foregroundContent[this.index], 'scheduledContent', []);
    }
    const scheduledContent: ScheduledContentModel | undefined = this.deviceTemplate.foregroundContent[
      this.index
    ].scheduledContent?.find(
      (scheduledContent: ScheduledContentModel) => scheduledContent.scheduleId === this.selectedSchedule
    );
    if (!scheduledContent) {
      const baseLayer: BaseLayerModel = this.deviceTemplate.foregroundContent[this.index].baseLayer;

      this.deviceTemplate.foregroundContent[this.index].scheduledContent?.push(
        new ScheduledContentModel(this.selectedSchedule, ScheduleLayerModel.fromBaseLayerModel(baseLayer))
      );
    } else {
      scheduledContent.layer.visibility =
        scheduledContent.layer.visibility === LayerVisibility.VISIBLE
          ? LayerVisibility.HIDDEN
          : LayerVisibility.VISIBLE;
    }
  }

  get hasScheduledContent(): boolean {
    const scheduledContentLength: number | undefined =
      this.deviceTemplate.foregroundContent[this.index].scheduledContent?.length;
    return scheduledContentLength ? scheduledContentLength > 0 : false;
  }

  get isLabelVisible(): boolean {
    const scheduledContent: ScheduledContentModel | undefined = this.deviceTemplate.foregroundContent[
      this.index
    ].scheduledContent?.find(
      (scheduledContent: ScheduledContentModel) => scheduledContent.scheduleId === this.selectedSchedule
    );
    if (!scheduledContent) {
      return true;
    }
    return scheduledContent.layer.visibility === LayerVisibility.VISIBLE;
  }

  get isEditLabelButtonDisabled(): boolean {
    if (!this.selectedSchedule) {
      return false;
    }
    return this.deviceTemplate.foregroundContent?.[this.index]?.baseLayer?.name === EMPTY_LABEL_PLACEHOLDER;
  }
}
