
import { Component, Prop, Vue } from 'vue-property-decorator';
import ModalHeader from '@client/components/Layouts/ModalHeader.vue';
import ReleaseManagement from '@client/models/SettingsModels/ReleaseManagement.model';
import { TranslateResult } from 'vue-i18n';
import i18n from '@client/plugins/i18n/i18n';
import { TranslationKeys } from '@client/plugins/i18n/locales';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import { Optional } from '@common/types';
import { Store } from '@client/models';

type ButtonType = 'Unsubscribe' | 'Subscribe';
@Component({
  components: { ModalHeader },
})
export default class AssignButton extends Vue {
  @Prop()
  private releaseManagement!: ReleaseManagement;
  @Prop()
  private isLoading!: boolean;
  @Prop()
  private isDisabled!: boolean;
  private showDialog: boolean = false;

  private storesStore: StoresStore = useStoresStore();

  closeDialog(): void {
    this.showDialog = false;
  }

  assignReleaseManagement(): void {
    const store: Optional<Store> = this.storesStore.getStoreById(this.storeId);
    if (store?.useEmailSubscription) {
      this.showDialog = true;
    } else {
      this.changeReleaseManagement();
    }
  }
  changeReleaseManagement() {
    this.$emit('release-management-changed', this.releaseManagement);
    this.closeDialog();
  }

  async changeSubscription(buttonType: ButtonType): Promise<void> {
    if (buttonType === 'Unsubscribe') {
      await this.storesStore.updateEmailSubscriptionState(false, this.storeId);
    }
    this.changeReleaseManagement();
  }

  icon(buttonType: ButtonType): string {
    return buttonType === 'Subscribe' ? 'mdi-bell' : 'mdi-bell-off';
  }

  buttonText(buttonType: ButtonType): TranslateResult {
    return buttonType === 'Subscribe'
      ? i18n.t(
          TranslationKeys.settings.releaseManagement.store.currentReleaseManagementModal.subscribeButton.subscribe.title
            .$path
        )
      : i18n.t(
          TranslationKeys.settings.releaseManagement.store.currentReleaseManagementModal.subscribeButton.unsubscribe
            .title.$path
        );
  }

  tooltipMessage(buttonType: ButtonType): TranslateResult {
    return buttonType === 'Subscribe'
      ? i18n.t(
          TranslationKeys.settings.releaseManagement.store.currentReleaseManagementModal.subscribeButton.subscribe
            .tooltip.$path
        )
      : i18n.t(
          TranslationKeys.settings.releaseManagement.store.currentReleaseManagementModal.subscribeButton.unsubscribe
            .tooltip.$path
        );
  }

  get storeId(): string {
    return this.$route.params.storeid;
  }

  get title(): TranslateResult {
    return i18n.t(
      TranslationKeys.settings.releaseManagement.store.changeReleaseManagementModal.continueUsingEmailTitle.$path
    );
  }

  get infoMessage(): TranslateResult {
    return i18n.t(
      TranslationKeys.settings.releaseManagement.store.changeReleaseManagementModal.continueUsingEmailMessage.$path
    );
  }
}
