
import { Component, Prop, Vue } from 'vue-property-decorator';
import QRCodeVue from 'qrcode.vue';

@Component({
  components: {
    QRCodeVue,
  },
})
export default class QRCode extends Vue {
  @Prop()
  private qrCodeData!: string | undefined;

  get isDataAvailable(): boolean {
    return !!this.qrCodeData;
  }
}
