import i18n from '@client/plugins/i18n/i18n';
import { RouteConfig } from 'vue-router/types/router';
import { TranslationKeys } from '@client/plugins/i18n/locales';

export const templateRoutes: Array<RouteConfig> = [
  {
    path: '/templates',
    name: 'templates',
    component: () => import('@client/views/GondolaPages/GondolaTemplateListView.vue'),
    meta: {
      title: i18n.t(TranslationKeys.gondolaTemplateListView.title.$path),
    },
  },
  {
    path: '/template/:id',
    name: 'gondolatemplate',
    props: { editable: true },
    component: () => import('@client/views/GondolaPages/GondolaTemplateDetailView.vue'),
    meta: {
      title: i18n.t(TranslationKeys.gondolaTemplateDetailView.title.$path),
    },
  },
  {
    path: '/assets/:folderId?',
    name: 'assets',
    component: () => import('@client/views/ContentManagementPages/ContentManagementView.vue'),
    meta: {
      title: i18n.t(TranslationKeys.contentManagement.$path),
    },
  },
];
