
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { BackgroundContent, BaseLayer, HardwareModel, Schedule } from '@client/models';
import ScheduleLayerModel from '@client/models/ScheduleModels/ScheduleLayer.model';
import { ContentType } from '@common/enums';
import { Optional } from '@common/types';
import { OPTIMIZED_PREVIEW_POSTFIX } from '@client/models/DeviceModels/DeviceCanvasItem.model';
import { SchedulesStore, useSchedulesStore } from '@client/stores/schedules';
import DevicePublishingBackgroundContentModel from '@client/models/DeviceModels/DevicePublishingBackgroundContentModel';
import ScheduledContentModel from '@client/models/ScheduleModels/ScheduledContent.model';
import DevicePublishingScheduledContentModel from '@client/models/DeviceModels/DevicePublishingScheduledContentModel';

@Component
export default class BackgroundPreview extends Vue {
  @Prop()
  private backgroundContentItem!: DevicePublishingBackgroundContentModel | BackgroundContent;
  @Prop()
  private hardwareModel!: HardwareModel;
  @Prop()
  private selectedSchedule!: string;

  private schedulesStore: SchedulesStore = useSchedulesStore();
  private schedules: Array<Schedule> = this.schedulesStore.getSchedulesForTemplate(this.$route.params.id);
  private backgroundContent: Optional<BaseLayer | ScheduleLayerModel> = null;
  private backgroundContentBorder: string = '';
  private previewURL: string = '';

  async created(): Promise<void> {
    await this.setBackgroundContentToRender();
  }

  @Watch('schedulesStore.schedules.length')
  @Watch('schedulesStore.selectedSchedule')
  @Watch('selectedSchedule')
  async onSchedulesChanged(): Promise<void> {
    this.schedules = this.schedulesStore.getSchedulesForTemplate(this.$route.params.id);
    await this.setBackgroundContentToRender();
  }

  /**
   * Returns the preview url to be shown
   * @private
   */
  private async getPreviewUrl(): Promise<string> {
    return this.backgroundContent?.getPreviewBlobUrl() || '';
  }

  @Watch('backgroundContentItem')
  private async setBackgroundContentToRender(): Promise<void> {
    const scheduledContent: ScheduledContentModel | DevicePublishingScheduledContentModel | undefined =
      this.selectedScheduledContent;
    if (!this.selectedSchedule || !scheduledContent) {
      this.backgroundContent = this.backgroundContentItem?.baseLayer;
      this.setBackgroundScheduleContentBorder();
      this.previewURL = await this.getPreviewUrl();
      return;
    }
    if (scheduledContent) {
      this.backgroundContent = scheduledContent.layer;
      this.setBackgroundScheduleContentBorder();
      this.previewURL = await this.getPreviewUrl();
    }
  }

  private setBackgroundScheduleContentBorder(): void {
    const scheduledContent: ScheduledContentModel | DevicePublishingScheduledContentModel | undefined =
      this.selectedScheduledContent;
    const color: string | undefined = this.schedules.find(
      (schedule: Schedule) => schedule._id === this.selectedSchedule
    )?.color;
    this.backgroundContentBorder = `${color && scheduledContent ? '1px' : '0'} solid ${
      color && scheduledContent ? color : 'transparent'
    }`;
  }

  private onDataLoaded(): void {
    this.$emit('onLoaded');
  }

  get selectedScheduledContent(): ScheduledContentModel | DevicePublishingScheduledContentModel | undefined {
    if (!this.backgroundContentItem) {
      return undefined;
    }
    if (this.backgroundContentItem instanceof BackgroundContent) {
      return (this.backgroundContentItem as BackgroundContent).scheduledContent?.find(
        (background: ScheduledContentModel) => background.scheduleId === this.selectedSchedule
      );
    } else {
      return (this.backgroundContentItem as DevicePublishingBackgroundContentModel).scheduledContent?.find(
        (background: DevicePublishingScheduledContentModel) => background.schedule?._id === this.selectedSchedule
      );
    }
  }

  /**
   * Returns the width style rule of the background item
   * Width: (Background original width in pixels * scaling factor of the hardware model) * Scaling of the preview zoom (if used defaults to 1)
   */
  get width(): string | null {
    if (!this.backgroundContent) {
      return null;
    }
    return `calc(${this.backgroundContent.getWidth() * this.hardwareModel.railScaleFactor}px * var(--device-scale))`;
  }

  /**
   * Returns the height style rule of the background item
   * Height: (Background original height in pixels * scaling factor of the hardware model) * Scaling of the preview zoom (if used defaults to 1)
   */
  get height(): string | null {
    if (!this.backgroundContent) {
      return null;
    }
    return `calc(${this.backgroundContent.getHeight() * this.hardwareModel.railScaleFactor}px * var(--device-scale))`;
  }

  /**
   * Returns a boolean to indicate if the preview is an image or not
   * If the preview URL ends with a '-webp', even if the content is originally a video, an image format has been generated for it
   * @private
   */
  get isImage(): boolean | undefined {
    if (this.backgroundContent?.type === ContentType.Playlist) {
      return this.backgroundContent.playlist[0]?.previewUrl.endsWith(OPTIMIZED_PREVIEW_POSTFIX);
    }
    return (
      this.backgroundContent?.type === ContentType.Image ||
      this.backgroundContent?.previewUrl?.endsWith(OPTIMIZED_PREVIEW_POSTFIX)
    );
  }
}
