
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class HeaderNumberFilter extends Vue {
  @Prop()
  private headerText!: string;
  @Prop()
  private defaultValue!: number | null;
  @Prop()
  private maxValue!: number;
  /**
   * This property indicates that clearing of a filter value will be done through chip displayed above the table
   * If not set, clearing of value will be done inside the v-menu where value is entered.
   */
  @Prop({ default: false })
  private useChips!: boolean;

  private filterValue: number | null = this.defaultValue;

  clearFilter(): void {
    this.filterValue = null;
  }
  validateInput(): void {
    if (Number(this.filterValue) > this.maxValue) {
      this.filterValue = this.maxValue;
    }
    this.emitValueChanged();
  }
  emitValueChanged(): void {
    this.$emit('change', this.filterValue);
  }

  @Watch('defaultValue')
  defaultValueChanged(): void {
    this.filterValue = this.defaultValue;
  }

  get filterButtonColor(): string {
    return !this.useChips && this.filterValue ? 'primary' : '';
  }
}
