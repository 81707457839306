
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Gondola } from '@client/models';
import CreateOrCloneGondolaTemplateModal from '@client/components/GondolaTemplate/CreateOrCloneGondolaTemplateModal.vue';

@Component({
  components: { CreateOrCloneGondolaTemplateModal },
})
export default class CreateTemplateFromSectionButton extends Vue {
  /* DECLARATIONS */
  @Prop()
  private gondola!: Gondola;
  @Prop()
  private isLoading!: boolean;

  private showDialog: boolean = false;
  /* LIFECYCLE EVENTS */
  /* METHODS */
  openDialog(): void {
    this.showDialog = true;
  }

  closeDialog(): void {
    this.showDialog = false;
  }
  /* GETTERS */
  get storeId(): string {
    return this.$route.params.storeid;
  }
}
