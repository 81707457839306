import { BaseLayer } from '@client/models/ScheduleModels';
import BaseLayerModel from '@client/models/ScheduleModels/BaseLayer.model';
import { PublishingBackgroundContentModelJSON, PublishingScheduledContentModelJSON } from '@common/schedule/types';
import DevicePublishingScheduledContentModel from '@client/models/DeviceModels/DevicePublishingScheduledContentModel';
import { Optional } from '@common/types';

export default class DevicePublishingBackgroundContentModel {
  baseLayer?: BaseLayer;
  scheduledContent?: Array<DevicePublishingScheduledContentModel>;

  constructor(baseLayer?: BaseLayer, scheduledContent?: Optional<Array<DevicePublishingScheduledContentModel>>) {
    this.baseLayer = baseLayer;
    this.scheduledContent = scheduledContent || [];
  }

  public static fromJSON(
    publishingBackgroundContentModelJSON: PublishingBackgroundContentModelJSON
  ): DevicePublishingBackgroundContentModel {
    const baseLayerModel: BaseLayerModel | undefined = publishingBackgroundContentModelJSON.baseLayer
      ? BaseLayer.fromJSON(publishingBackgroundContentModelJSON.baseLayer)
      : undefined;
    const scheduledContentModel: DevicePublishingScheduledContentModel[] | undefined =
      publishingBackgroundContentModelJSON.scheduledContent?.map(
        (scheduledContentItem: PublishingScheduledContentModelJSON) =>
          DevicePublishingScheduledContentModel.fromJSON(scheduledContentItem)
      );

    return new DevicePublishingBackgroundContentModel(baseLayerModel, scheduledContentModel);
  }

  public toJSON(): PublishingBackgroundContentModelJSON {
    return {
      baseLayer: this.baseLayer?.toJSON(),
      scheduledContent: this.scheduledContent?.map(
        (publishingScheduledContentModel: DevicePublishingScheduledContentModel) =>
          publishingScheduledContentModel.toJSON()
      ),
    };
  }

  public clone(): DevicePublishingBackgroundContentModel {
    return new DevicePublishingBackgroundContentModel(
      this.baseLayer?.clone(),
      this.scheduledContent?.map((publishingScheduledContentModel: DevicePublishingScheduledContentModel) =>
        publishingScheduledContentModel.clone()
      )
    );
  }
}
