
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { isInputOnlyAscii } from '@common/utils/validation';
import i18n from '@client/plugins/i18n/i18n';
import { validateTextFieldLength } from '@client/utils/validateTextFieldLength';
import { AppGlobalStore, useAppGlobalStore } from '@client/stores/app-global';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import { Device, Store } from '@client/models';
import ModalHeader from '@client/components/Layouts/ModalHeader.vue';
import WizardDevices from '@client/components/Store/StoreCreationWizard/WizardDevices.vue';
import WarningMessage from '@client/components/Layouts/WarningMessage.vue';

@Component({
  components: { WarningMessage, WizardDevices, ModalHeader },
})
export default class WizardAzureId extends Vue {
  /* DECLARATIONS */
  @Prop({ required: false })
  private validationResult!: TranslateResult;
  @Prop({ type: Boolean })
  private valid!: boolean;
  @Prop({ type: Array })
  private azureIdRelatedDevices!: Array<Device>;
  @Prop()
  private store!: Store;

  private appGlobalStore: AppGlobalStore = useAppGlobalStore();
  private storesStore: StoresStore = useStoresStore();

  private storeIdAzure: string = '';
  /* LIFECYCLE EVENTS */
  mounted(): void {
    (this.$refs.form as Vue & { validate: () => boolean }).validate();
  }
  /* METHODS */
  validateStoreAzureId(value: string): boolean | TranslateResult {
    if (!isInputOnlyAscii(value)) {
      return i18n.t(this.$i18nTranslationKeys.error.nonASCIIInput.$path);
    }
    const maxInternalLength: number = 128;
    const customerIdLength: number = this.appGlobalStore.customer.length;
    // azure id internally on APIM is saved as "{customerId}-{AzureId}" and limited to 128 char
    const maxAzureIdLength: number = maxInternalLength - customerIdLength - 1;
    return validateTextFieldLength(value, maxAzureIdLength, true);
  }

  @Watch('store.idAzure')
  async onStoreAzureIdChange(): Promise<void> {
    this.storeIdAzure = this.store.idAzure;
  }

  @Watch('storeIdAzure')
  async onStoreIdAzureChange(): Promise<void> {
    this.$emit('onChange', this.storeIdAzure);
  }

  /* GETTERS */
  get isLoading(): boolean {
    return this.storesStore.isFetching || this.storesStore.loadingIndicator.update;
  }

  get validationResultTitle(): TranslateResult {
    return this.valid
      ? this.$t(this.$i18nTranslationKeys.storeCreation.validation.validAzureId.$path)
      : this.$t(this.$i18nTranslationKeys.storeCreation.validation.invalidAzureId.$path);
  }

  get warningMessageIcon(): string {
    return this.valid ? 'mdi-check-circle' : 'mdi-alert-circle';
  }

  get warningMessageColor(): string {
    return this.valid ? 'success' : 'error';
  }
}
