
import { Component, Prop, Vue } from 'vue-property-decorator';
import TagReference from '@client/models/SettingsModels/TagReference';
import Tag from '@client/components/Settings/Tags/Tag.vue';

@Component({
  components: { Tag },
})
export default class TagsList extends Vue {
  @Prop()
  private tags!: Array<TagReference>;
}
