
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DeviceTemplate } from '@client/models';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';

/**
 * This is used as a placeholder when the gondola template is still loading.
 */
@Component({})
export default class GondolaTemplateLoader extends Vue {
  @Prop() private gondolaTemplateId!: string;

  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();

  private getRailClass(device: DeviceTemplate): string {
    return `device-${device.hardwareModel.identifier}`;
  }

  get railGrid(): Array<Array<DeviceTemplate>> | null {
    return this.gondolaTemplatesStore.getById(this.gondolaTemplateId)?.railGrid || null;
  }
}
