import { BaseContentItemJSON, ImageJSON, PopulatedPlaylistJSON, VideoJSON } from '@common/content-item/types';
import { ContentType } from '@common/enums';

const isVideoJSON = (contentItem: BaseContentItemJSON): contentItem is VideoJSON =>
  contentItem.type === ContentType.Video;
const isImageJSON = (contentItem: BaseContentItemJSON): contentItem is ImageJSON =>
  contentItem.type === ContentType.Image;
const isPlaylistJSON = (contentItem: BaseContentItemJSON): contentItem is PopulatedPlaylistJSON =>
  contentItem.type === ContentType.Playlist;

export { isImageJSON, isPlaylistJSON, isVideoJSON };
