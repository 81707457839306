
import { Component, Vue } from 'vue-property-decorator';
import { ContentItemsStore, useContentItemsStore } from '@client/stores/contentItems';
import { bytesToMegaBytes } from '@client/components/ContentItems/utils';
import { QueueFile } from '@client/stores/contentItems/store';
import { TranslateResult } from 'vue-i18n';
import { Optional } from '@common/types';
import { ContentItem } from '@client/models/ContentModels/types';
import { MessagesStore, useMessagesStore } from '@client/stores/messages';

/**
 * Renders the confirmation modal, in case the user wants to upload multiple files.
 */
@Component({})
export default class ContentItemsUploadConfirmation extends Vue {
  /* DECLARATIONS */
  private contentItemsStore: ContentItemsStore = useContentItemsStore();

  private keepFolderStructure: boolean = false;
  /* LIFECYCLE EVENTS */
  /* METHODS */
  closeUploadPopup(): void {
    this.contentItemsStore.clearPendingFiles();
  }

  async onConfirmUpload(): Promise<void> {
    if (this.keepFolderStructure) {
      const numberOfCreatedFolders: number = await this.contentItemsStore.createFolderHierarchy();
      this.keepFolderStructure = false;
      const messageStore: MessagesStore = useMessagesStore();
      messageStore.showMessage(
        this.$t(this.$i18nTranslationKeys.contentManagement.foldersCreated.$path, {
          numberOfFolders: numberOfCreatedFolders,
        })
      );
    }
    this.contentItemsStore.queuePendingFiles();
    this.$root.$emit('addFilesToQueue');
  }

  setKeepFolderStructure(): void {
    this.keepFolderStructure = !this.keepFolderStructure;
  }

  /* GETTERS */
  get pendingFiles(): Array<QueueFile> {
    return this.contentItemsStore.upload.pendingFiles;
  }

  get sizeOfFilesToUploadInMegaBytes(): string {
    const totalSizeInBytes: number = this.contentItemsStore.upload.pendingFiles.reduce(
      (total: number, queueFile: QueueFile) => total + queueFile.file.size,
      0
    );
    return `${bytesToMegaBytes(totalSizeInBytes).toFixed(2)}MB`;
  }

  get filesListToRender(): string {
    let list: string = '';
    this.pendingFiles
      .filter((file: QueueFile, index: number) => index <= 3)
      .forEach((queueFile: QueueFile) => {
        list = list.concat(
          `<li>${queueFile.file.name} <strong>(${bytesToMegaBytes(queueFile.file.size).toFixed(2)}MB)</strong></li>`
        );
      });
    if (this.pendingFiles.length > 4) {
      list = list.concat(
        `<li>${this.$t(this.$i18nTranslationKeys.contentManagement.andOtherFiles.$path, {
          numberOfFiles: this.pendingFiles.length - 4,
        })}</li>`
      );
    }
    return list;
  }

  get selectedFolderName(): TranslateResult {
    const selectedFolder: Optional<ContentItem> = this.contentItemsStore.getContentItemById(
      this.pendingFiles[0].targetFolder
    );
    if (!selectedFolder) {
      return this.$t(this.$i18nTranslationKeys.contentManagement.root.$path);
    }
    return selectedFolder.name;
  }

  get isFolderUploadSelected(): boolean {
    return this.contentItemsStore.upload.isFolderUpload;
  }

  get isLoading(): boolean {
    return this.contentItemsStore.isActionPending;
  }
}
