import { DeviceDetectedObject } from '@common/apim/definitions';
import { DailyAverages, HourlyAverages, OverallAverages } from '@client/components/SensorAnalytics/charts/types';

export const secondsToHHMMSS = (seconds: number): string => {
  const hours: number = Math.floor(seconds / 3600);
  const minutes: number = Math.floor((seconds % 3600) / 60);
  const remainingSeconds: number = Math.floor(seconds % 60);

  const hoursStr: string = hours.toString().padStart(2, '0');
  const minutesStr: string = minutes.toString().padStart(2, '0');
  const secondsStr: string = remainingSeconds.toString().padStart(2, '0');

  return `${hoursStr}:${minutesStr}:${secondsStr}`;
};

export const getHourOfDay = (timestamp: string): string => {
  const date: Date = new Date(timestamp);
  // Get the hour of the day (00 - 23)
  return date.toISOString().slice(11, 13);
};

const getDay = (timestamp: string): string => {
  return timestamp.slice(0, 10); // Extract the "YYYY-MM-DD" part
};

const getDayOfWeek = (timestamp: string): string => {
  const date: Date = new Date(timestamp);
  const days: string[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  return days[date.getDay()];
};

export const calculateHourlyAverages = (objects: DeviceDetectedObject[]): HourlyAverages[] => {
  const groupedByHour: { [hour: string]: DeviceDetectedObject[] } = {};
  Array.from({ length: 24 }, (_: unknown, i: number) => i).forEach((hour: number) => {
    groupedByHour[hour] = [];
  });

  // Group objects by the hour of the day (ignoring the date)
  objects.forEach((obj: DeviceDetectedObject) => {
    if (!obj.firstSeen) {
      return;
    }
    const hour: string = getHourOfDay(obj.firstSeen);
    if (!groupedByHour[hour]) {
      groupedByHour[hour] = [];
    }
    groupedByHour[hour].push(obj);
  });

  // Calculate averages for each hour of the day (00 to 23)
  return Object.keys(groupedByHour).map((hour: string) => {
    const hourObjects: Array<DeviceDetectedObject> = groupedByHour[hour];

    const totalDecisionTime: number = hourObjects.reduce(
      (sum: number, obj: DeviceDetectedObject) => sum + obj.decisionTime,
      0
    );
    const totalDwellTime: number = hourObjects.reduce(
      (sum: number, obj: DeviceDetectedObject) => sum + obj.dwellTime,
      0
    );
    const totalExposureTime: number = hourObjects.reduce(
      (sum: number, obj: DeviceDetectedObject) => sum + obj.exposureTime,
      0
    );

    const totalDecisionObjects: number = hourObjects.reduce(
      (sum: number, obj: DeviceDetectedObject) => sum + (obj.decisionTime > 0 ? 1 : 0),
      0
    );

    const totalDwellObjects: number = hourObjects.reduce(
      (sum: number, obj: DeviceDetectedObject) => sum + (obj.dwellTime > 0 ? 1 : 0),
      0
    );

    const totalExposureObjects: number = hourObjects.reduce(
      (sum: number, obj: DeviceDetectedObject) => sum + (obj.exposureTime > 0 ? 1 : 0),
      0
    );

    const count: number = hourObjects.length;

    return {
      hour,
      averageDecisionTime: totalDecisionTime / count,
      averageDwellTime: totalDwellTime / count,
      averageExposureTime: totalExposureTime / count,
      validObjectsDecision: totalDecisionObjects,
      validObjectsDwell: totalDwellObjects,
      validObjectsExposure: totalExposureObjects,
    };
  });
};

export const calculateOverallAverages = (objects: DeviceDetectedObject[]): OverallAverages => {
  let validDecisionCount: number = 0;
  let validDwellCount: number = 0;
  let validExposureCount: number = 0;

  objects.forEach((obj: DeviceDetectedObject) => {
    // Sum the times and count the valid objects for each metric
    if (obj.decisionTime > 0) {
      validDecisionCount++;
    }
    if (obj.dwellTime > 0) {
      validDwellCount++;
    }
    if (obj.exposureTime > 0) {
      validExposureCount++;
    }
  });

  const totalObjects: number = objects.length;

  return {
    validObjectsExposure: validExposureCount,
    validObjectsDecision: validDecisionCount,
    validObjectsDwell: validDwellCount,
    averageObjectsDecision: validDecisionCount / totalObjects,
    averageObjectsDwell: validDwellCount / totalObjects,
    averageObjectsExposure: validExposureCount / totalObjects,
  };
};

export const calculateDailyAverages = (
  objects: DeviceDetectedObject[],
  byDate: boolean = true,
  ignoreEmptyDays: boolean = false
): DailyAverages[] => {
  if (objects.length === 0) {
    return [];
  }
  const groupedByDay: { [day: string]: DeviceDetectedObject[] } = {};

  if (!byDate) {
    // Fill days of the week
    const daysOfWeek: string[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    daysOfWeek.forEach((day: string) => {
      if (!groupedByDay[day]) {
        groupedByDay[day] = [];
      }
    });
  }

  // Group objects by the day (YYYY-MM-DD)
  objects.forEach((obj: DeviceDetectedObject) => {
    let day: string;
    if (byDate) {
      day = getDay(obj.firstSeen);
    } else {
      day = getDayOfWeek(obj.firstSeen);
    }
    if (!groupedByDay[day]) {
      groupedByDay[day] = [];
    }
    groupedByDay[day].push(obj);
  });

  // If byDate is true, fill missing days
  if (byDate && !ignoreEmptyDays) {
    // Get the range of dates (earliest to latest)
    const allDays: Array<string> = Object.keys(groupedByDay).sort(
      (a: string, b: string) => new Date(a).getTime() - new Date(b).getTime()
    );

    const startDate: string = allDays[0];
    const endDate: string = allDays[allDays.length - 1];

    // Generate all dates between start and end
    const dateRange: string[] = [];

    const currentDate: Date = new Date(startDate);
    while (currentDate.toISOString().slice(0, 10) <= endDate) {
      dateRange.push(currentDate.toISOString().slice(0, 10));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    // Ensure all days are present in groupedByDay
    dateRange.forEach((day: string) => {
      if (!groupedByDay[day]) {
        groupedByDay[day] = [];
      }
    });
  }

  // Calculate averages for each day
  return Object.keys(groupedByDay)
    .map((day: string) => {
      const dayObjects: Array<DeviceDetectedObject> = groupedByDay[day];

      let totalDecisionTime: number = 0;
      let totalDwellTime: number = 0;
      let totalExposureTime: number = 0;

      let validDecisionCount: number = 0;
      let validDwellCount: number = 0;
      let validExposureCount: number = 0;

      dayObjects.forEach((obj: DeviceDetectedObject) => {
        if (obj.decisionTime > 0) {
          totalDecisionTime += obj.decisionTime;
          validDecisionCount++;
        }
        if (obj.dwellTime > 0) {
          totalDwellTime += obj.dwellTime;
          validDwellCount++;
        }
        if (obj.exposureTime > 0) {
          totalExposureTime += obj.exposureTime;
          validExposureCount++;
        }
      });

      return {
        day,
        averageDecisionTime: validDecisionCount > 0 ? totalDecisionTime / validDecisionCount : 0,
        averageDwellTime: validDwellCount > 0 ? totalDwellTime / validDwellCount : 0,
        averageExposureTime: validExposureCount > 0 ? totalExposureTime / validExposureCount : 0,
        validObjectsDecision: validDecisionCount,
        validObjectsDwell: validDwellCount,
        validObjectsExposure: validExposureCount,
      };
    })
    .sort((a: DailyAverages, b: DailyAverages) => {
      if (byDate) {
        return new Date(a.day).getTime() - new Date(b.day).getTime();
      }
      return 0;
    });
};

export const mapDatasetFromAverage = (type: string, average: DailyAverages | HourlyAverages): number => {
  switch (type) {
    case 'awareness':
      return average.validObjectsExposure;
    case 'dwell':
      return average.validObjectsDwell;
    case 'decision':
      return average.validObjectsDecision;
    default:
      return 0;
  }
};

export const mapLabelFromAverage = (type: string, average: DailyAverages | HourlyAverages): string => {
  switch (type) {
    case 'awareness':
      return `${secondsToHHMMSS(average.averageExposureTime)} (${average.validObjectsExposure})`;
    case 'dwell':
      return `${secondsToHHMMSS(average.averageDwellTime)} (${average.validObjectsDwell})`;
    case 'decision':
      return `${secondsToHHMMSS(average.averageDecisionTime)} (${average.validObjectsDecision})`;
    default:
      return '';
  }
};
